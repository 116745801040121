import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'osTestParameter'
})
export class OsTestParameterPipe implements PipeTransform {

  transform(value: any): any {
    if (value == 'P') {
      return 'People';
    } else if (value == 'I') {
      return 'Informative';
    } else if (value == 'A') {
      return 'Administrative';
    } else if (value == 'N') {
      return 'Creative';
    }
    return null;
  }

}
