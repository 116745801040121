import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { MessageService } from 'primeng/api';
declare var $: any;
@Component({
  selector: 'app-plan-action-modals',
  templateUrl: './plan-action-modals.component.html',
  styleUrls: ['./plan-action-modals.component.scss'],
  providers: [MessageService]
})
export class PlanActionModalsComponent implements OnInit {

  @Input() public planDataToSend;
  @Input() public modalRefToSend;
  @Input() public flagToTriggerChangeEvent;

  showDisableTeamMemberModal = false;
  actionType;

  // Downgrade Specific
  counselorList;
  disableMembersList = [];
  message;
  displayMessage;
  isDowngradeAllowed;

  constructor(private dashboardService: DashboardService,private messageService: MessageService) { }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(this.planDataToSend);
    // console.log(this.modalRefToSend);

    /* if (this.planDataToSend.action === 'Upgrade') {
      this.showDisableTeamMemberModal = false;
      this.actionType = 'Upgrade';
      $('#' + this.modalRefToSend.id).modal('show');
    }
    else */ if (this.planDataToSend.action === 'Cancel Renewal') {
      this.showDisableTeamMemberModal = false;
      this.actionType = 'Cancel';
      $('#' + this.modalRefToSend.id).modal('show');
    }
    else if (this.planDataToSend.action === 'Downgrade') {
      // this.showDisableTeamMemberModal = true;
      this.checkUserLimit();
      // this.executePlanAction('Downgrade');

      // $('#' + this.modalRefToSend.id).modal('show');
    }
  }
  
  onCancel() {
    this.dashboardService.managePlanBtnDisabled(false);
  }

  executePlanAction(actionType) {
    // Upgrade OR Downgrade Action
    if (actionType === 'Downgrade') {
      const upgradePostData = {
        plan_type: this.planDataToSend.plan_type,
        recurring_type: this.planDataToSend.recurring_type
      }
      this.dashboardService.updateSubscription(upgradePostData).subscribe(response => {
        if (response.statusCode == 200 && response.status == true) {
          this.dashboardService.setPlanChangeRequestFlag(response.data.plan_change_or_cancellation_request);
          this.dashboardService.managePlanBtnDisabled(false);
        }
      });
    }
    // Cancel Action
    else if (actionType === 'Cancel') {
      this.dashboardService.cancelSubscription().subscribe(response => {
        if (response.statusCode == 200 && response.status == true) {
          this.dashboardService.setPlanChangeRequestFlag(response.data.plan_change_or_cancellation_request);
          this.dashboardService.managePlanBtnDisabled(false);
          this.messageService.add({ severity: 'success', summary: 'Success', detail:response.message });
        }
      });
    }
  }

  // Downgrade specific
  checkUserLimit() {
    const beforeDowngradeData = {
      plan_type: this.planDataToSend.plan_type,
      recurring_type: this.planDataToSend.recurring_type
    }
    this.dashboardService.beforeDowngrade(beforeDowngradeData).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        if (response.message === 'User limit exceed') {
          this.displayMessage = response.displayMessage;
          this.counselorList = response.counselorList.counselors;
          this.isDowngradeAllowed = false;
          this.showDisableTeamMemberModal = true;
        }
        else {
          this.actionType = 'Downgrade';
          this.showDisableTeamMemberModal = false;
        }
        $('#' + this.modalRefToSend.id).modal('show');
        // this.dashboardService.setPlanChangeRequestFlag(response.data.plan_change_or_cancellation_request);
      }
    });
  }

  disableMember(counselorId, actionStatus) {
    let actionValue;
    if (actionStatus == 1) {
      actionValue = 2;
    }
    else {
      actionValue = 1;
    }

    const disableMemberPostData = {
      plan_type: this.planDataToSend.plan_type,
      recurring_type: this.planDataToSend.recurring_type,
      action: actionValue,
      counselor_id: counselorId
    }

    this.dashboardService.disableCounselors(disableMemberPostData).subscribe(response => {
      if (response.statusCode == 200 && response.status == false) {
        this.counselorList = response.data.allCounselors;
        this.displayMessage = response.message;
        this.isDowngradeAllowed = false;
      }
      else if (response.statusCode == 200 && response.status == true) {
        if (response.message === 'Proceed') {
          this.displayMessage = '';
          this.actionType = 'Downgrade';
          this.counselorList = response.data.allCounselors;
          this.isDowngradeAllowed = true;
        }
      }
    });
  }
}
