import { Injectable, Inject } from '@angular/core';
import { ReplaySubject, Observable, forkJoin, Subject, empty } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadFilesService {

  // a JSON object with key:value with key as url and value as subject
  // Ex. {//js.hs-scripts.com/6378190.js: ReplaySubject}
  razorpaySubject: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private document: Document) { }


  loadRazorpayScript(): Observable<any> {
    const url = 'https://checkout.razorpay.com/v1/checkout.js';

    if (this.razorpaySubject[url]) {
      return this.razorpaySubject[url].asObservable();
    }

    this.razorpaySubject[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;

    script.onload = () => {
      this.razorpaySubject[url].next();
      this.razorpaySubject[url].complete();
    };

    this.document.body.appendChild(script);
    return this.razorpaySubject[url].asObservable();
  }

}
