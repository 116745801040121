import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { PreloginService } from '../../services/prelogin.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-counsellor-registration',
  templateUrl: './counsellor-registration.component.html',
  styleUrls: ['./counsellor-registration.component.scss']
})
export class CounsellorRegistrationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private validationService: ValidationsService, private preLoginService: PreloginService) { }

  // flags
  isSignUpCompleted = false;
  isLoading = false;

  logoUrl ="https://mindler-products-images.imgix.net/confluence/general-icons/edsoul.png";

  // defaults
  partnerName = null;
  partnerId = null;
  counsellorEmail = null;

  isFormSubmitted = false;
  isUserAlreadyExists = false;

  signUpForm: FormGroup;

  // password toggle
  isFieldTypeText = false;

  ngOnInit() {

    if (localStorage.getItem('counsellorSign_partner_name') != null && localStorage.getItem('counsellorSign_partner_id') != null && (localStorage.getItem('counsellorSign_email') != null)) {
      this.partnerName = localStorage.getItem('counsellorSign_partner_name');
      this.partnerId = localStorage.getItem('counsellorSign_partner_id');
      this.counsellorEmail = localStorage.getItem('counsellorSign_email');
    }

    this.signUpForm = this.formBuilder.group({
      partner_id: [this.partnerId],
      email: [this.counsellorEmail],
      name: ['', [Validators.required, Validators.pattern('[(A-Za-z0-9 )]+')]],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // getters
  get name() { return this.signUpForm.get('name'); }
  get phone() { return this.signUpForm.get('phone'); }
  get email() { return this.signUpForm.get('email'); }
  get password() { return this.signUpForm.get('password'); }


  togglePasswordFieldType() {
    this.isFieldTypeText = !this.isFieldTypeText;
  }

  signUpFormSubmit() {

    this.isFormSubmitted = true;

    if (this.signUpForm.invalid) {
      return;
    }
    this.preLoginService.counselorRegistration(this.signUpForm.value).subscribe(response => {
      // console.log(response);

      if (response.statusCode == 200 && response.status == false) {
        this.isUserAlreadyExists = response.message;
      }
      else {
        this.isFormSubmitted = false;

        const autoLoginPostData = {
          email: this.email.value,
          password: this.password.value
        };

        this.signUpForm.reset();
        localStorage.clear();

        this.preLoginService.login(autoLoginPostData).subscribe(data => {
          this.router.navigate(['/my-dashboard']);
        });
      }
    });
  }

  // Telephone Input
  getNumber(fullNumber) {
    this.signUpForm.patchValue({
      phone: fullNumber
    });
  }

  hasError(event: any): void {
    if (!event && this.phone.value !== '') {
      this.phone.setErrors({ invalidPhoneNumber: true });
    }
  }

}
