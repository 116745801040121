import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import {MultiSelectModule} from 'primeng/multiselect';
import { DashboardService } from '../../services/dashboard.service';
import { SuperadminService } from '../../services/superadmin.service';
declare var $: any;

@Component({
  selector: 'app-marketing-resources',
  templateUrl: './marketing-resources.component.html',
  styleUrls: ['./marketing-resources.component.scss']
})
export class AdminMarketingResourcesComponent implements OnInit {
  resourceId;
  isLoading = false;
  resourceStatus=true;
  uploadResource;
  partners;
  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;
  @Input() fieldvalue = '';
  partnerFolders;
  savedData;
  apiResponse;
  resourceForm: FormGroup;
  folderForm: FormGroup;
  resources
  noOfPages;
  folders = [];
  partnerlist;
  filetype=false;
  isFolderExist=false;
  isResourcefile;
  getPartnersPostData: any = {};
  partnersTableSearch = new Subject<string>();
  myDummyEvent = new Event('dummyEvent', { bubbles: true });


  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private superAdminService: SuperadminService) { 

  	this.partnersTableSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => { 
        this.setFilterAndGetList(this.myDummyEvent);
      });
  }

  ngOnInit() {
      this.getPartnersPostData.offset = '';
      this.getPartnersPostData.limit = 10;
      this.getPartnersPostData.search = '';

      const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
      this.resourceForm = this.formBuilder.group({
        partner: ['', Validators.required],
        folder: ['', Validators.required],
        resource_type: ['', Validators.required],
        resource_name: ['', [Validators.required, Validators.minLength(5)]],
        resource_link: ['', [Validators.required, Validators.pattern(reg)]],
        resource_file: [''],
        id: [''],
      });


      this.folderForm = this.formBuilder.group({
        partnerFolder: ['', Validators.required],
        folder_name: ['', [Validators.required, Validators.minLength(6)]],
      });

      this.getPartnerList();
      this.resourcelist();
      this.folderlist()
  }


  get partner() { return this.resourceForm.get('partner'); }
  get folder() { return this.resourceForm.get('folder'); }
  get resource_type() { return this.resourceForm.get('resource_type'); }
  get resource_name() { return this.resourceForm.get('resource_name'); }
  get resource_link() { return this.resourceForm.get('resource_link'); }
  
  get partnerFolder() { return this.folderForm.get('partnerFolder'); }
  get folder_name() { return this.folderForm.get('folder_name'); }

  resourceType(event: any){

      this.uploadResource = event.target.value;
      if(this.uploadResource=='PDF'){
        this.resourceForm.patchValue({
          resource_link: ''
        });
      }else{
        this.resourceForm.patchValue({
          resource_file: ''
        });
      }

  }


  setFilterAndGetList(event) {
    this.getPartnersPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }

    this.resourcelist();
  }

  uploadResourceFile(event){

      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        
        this.resourceForm.get('resource_file').setValue(file);

        this.resourceForm.patchValue({
          resource_file:file
        });
        if(file.type=='application/pdf'){
          this.filetype=true;
        }
        this.isResourcefile = file;

      }
  }


  partnerField(event){

    this.folders = [];
    if(event!=null){
        this.superAdminService.getFolderBypartner({partner_id:event}).subscribe(response => {
         if (response.statusCode === 200 && response.status === true) {
             this.folders = response.data;
           }
        });
    }
  }

  getPartnerList(){

      this.superAdminService.partnerlist().subscribe(data => {
        const res = data.response;
        this.partners = res;
      });

  }


  folderlist(){

      this.superAdminService.folderlist().subscribe(response => {
        const res = response.data;
        //this.folders = res;
      
      });

  }

  resourcelist(){

      this.superAdminService.resourcelist(this.getPartnersPostData).subscribe(response => {
        const res = response.data;
        this.resources = res;
      });

  }

  addResourceBtn(){
    this.resourceForm.reset();
    this.resourceId='';
    this.resourceStatus=true;
  }

  resourceFormSubmit() {

     let formValues;
     const resource = this.resourceForm.get('resource_file').value;
     const partner =  this.resourceForm.get('partner').value;
     const folder =  this.resourceForm.get('folder').value;
     const type = this.resourceForm.get('resource_type').value;
     const resource_name =  this.resourceForm.get('resource_name').value;


    if(type=='PDF'){
       const formData = new FormData();
       formData.append('image', this.resourceForm.get('resource_file').value);
       formData.append('partner', JSON.stringify(partner));
       formData.append('folder', this.resourceForm.get('folder').value);
       formData.append('resource_type', this.resourceForm.get('resource_type').value);
       formData.append('resource_name', this.resourceForm.get('resource_name').value);

       if(this.resourceId){
            formData.append('id', this.resourceId);
        }

       formValues = formData;
     }else{
         const resource = this.resourceForm.get('resource_file').value;
         const partner =  this.resourceForm.get('partner').value;
         const folder =  this.resourceForm.get('folder').value;
         const type = this.resourceForm.get('resource_type').value;
         const resource_name =  this.resourceForm.get('resource_name').value;

         if(this.resourceId){ 
            this.resourceForm.patchValue({
              id: this.resourceId
            })
          }

         formValues = this.resourceForm.value;
     }


     
     let validateArr = [partner, folder, type, resource_name];
     validateArr = validateArr.filter(item => item);
     
    if(this.resourceForm.invalid && type!='PDF') {
      return;
    }else if(type=='PDF' && (validateArr.length!=4 || !resource)) {
      return;
    }else{
      
      //console.log(formValues); return false;
      this.resourceStatus=false;
      this.superAdminService.addresources(formValues).subscribe(response => {
       if (response.statusCode === 200 && response.status === true) {
           this.resourcelist();
           this.resourceForm.reset();
           $("#resetPasswordModal").modal("hide");
         }
      });
    }
  }

  editResource(data){

    
    this.superAdminService.resourcelistbyid({id:data.id, table:'partner_resources'}).subscribe(response => {
        const res = response.data;
        $("#resetPasswordModal").modal("show");
        this.savedData=res;
        let partnerData =[];
        (res.partner).forEach(element => { 
          partnerData.push({ id: element.id, name: element.name });
        });
        this.resourceId = data.id;
        this.resourceForm.get('partner').setValue(partnerData);
        this.resourceForm.patchValue({
          folder: data.folder_id,
          resource_link: data.resource_link,
          resource_name: data.resource_title,
          resource_type: data.resource_type
        });

        this.uploadResource = data.resource_type;
        if(this.uploadResource=='PDF'){
          this.resourceForm.patchValue({
            resource_link: ''
          });
        }else{
          this.resourceForm.patchValue({
            resource_file: ''
          });
        }

      });
  }


  checkFolderAvailability(event){
    this.isFolderExist = false;
    this.superAdminService.isFolderAlreadyExist({folder_name:event}).subscribe(response => {
     if (response.statusCode === 200 && response.status === true) {
        this.isFolderExist = true;
       }
    });

    return this.isFolderExist;
  }
  
  folderFormSubmit(){

    if(this.folderForm.invalid) {
      return;
    }

    if(this.isFolderExist==false){

          this.superAdminService.addFolder(this.folderForm.value).subscribe(response => {
           if (response.statusCode === 200 && response.status === true) {
               this.folderlist();
               this.folderForm.reset();
               $("#addFolder").modal("hide");
             }
          });
    }

  }


  viewPartner(partnerId){
    
    this.superAdminService.viewResourcePartners({id:partnerId}).subscribe(response => {
     if (response.statusCode === 200 && response.status === true) {
         this.partnerlist = response.data;
       }
    });
  }

}
