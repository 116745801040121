import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { PasswordValidator } from '../../custom_validators/password.validator';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  isChangePasswordFormSubmitted = false;

  feedbackMessage;
  fieldTextType: boolean = false;

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router, private dashboardService: DashboardService) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6),Validators.pattern('.*[0-9].*')]],
      confirmPassword: ['', Validators.required]
    }, { validator: PasswordValidator });
  }


  // getters
  get password() { return this.changePasswordForm.get('password'); }
  get confirmPassword() { return this.changePasswordForm.get('confirmPassword'); }


  changePasswordFormSubmit() {
    this.isChangePasswordFormSubmitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    const changePasswordPostData = {
      password: this.password.value
    };

    this.dashboardService.changePassword(changePasswordPostData).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.changePasswordForm.reset();
        this.isChangePasswordFormSubmitted = false;
        this.feedbackMessage = 'Password Changed!';
        setTimeout(() => {
          this.feedbackMessage = '';
        }, 3000);
      }
      // else {
      //   this.isPasswordReset = false;
      // }
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  selectedBorder() {
    var eyeIcon = document.getElementById('eye-icon');
    eyeIcon.style.borderBottom = "2px solid #007fb6";
  }

  unselectedBorder() {
    var eyeIcon = document.getElementById('eye-icon');
    eyeIcon.style.borderBottom = "2px #e9e9e9 solid";
  }

}
