import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {

  constructor() { }

  // To accept only numeric input
  isNumericInput(event) {
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      return true;
    } else {
      return false;
    }
  }

  // Email address Validator
  validateEmail(control: FormControl) {
    const regex = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-Z0-9]+$/;
    if (control.value) {
      const matches = control.value.match(regex);
      return matches ? null : { invalidEmail: true };
    } else {
      return null;
    }
  }

  // Coupon Quantity Validator - value should be in a range like 1-6999 (passed as param).
  validateCouponQuantity(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value != '' && (isNaN(control.value) || control.value < min || control.value > max)) {
        return { invalidQunatity: true };
      }
      return null;
    };
  }

}
