import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ScholarshipsService {

  httpOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa('mindler' + ':' + 'm1ndl3r')
    })
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    })
  };

  constructor(private http: HttpClient) { }


  getTokenFromBuddyStudy() {
    // Post request 'FormData'
    const authPostData = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('scope', 'read');

    return this.http.post<any>('https://api.buddy4study.com/api/v1.0/uaa/oauth/token', authPostData, this.httpOptionsAuth)
      .pipe(map(response => {
        localStorage.setItem('buddyStudy_token', response.access_token);
        return response;
      })
      );
  }

  addTokenToHeader() {
    if (localStorage.getItem('buddyStudy_token') != null) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('buddyStudy_token'));
    }
  }

  getFiltersData() {
    const url = 'https://api.buddy4study.com/api/v1.0/utilms/rule?filter=[%22class%22,%22gender%22,%22religion%22,%22special%22,%22country%22]';

    this.addTokenToHeader();

    return this.http.get<any>(url, this.httpOptions);
  }

  getScholarshipsList(data) {
    const url = 'https://api.buddy4study.com/api/v1.0/ssms/mindler/scholarship/listing/';

    this.addTokenToHeader();

    return this.http.post<any>(url, data, {...this.httpOptions,observe: 'response'}).pipe(map((d:any)=>{
      if(d.status == 403) {
        d['body'] = {
          retry: 1
        }
      }
      return d.body
    }));
  }

}
