import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { DashboardService } from '../../services/dashboard.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import { PreloginService } from '../../services/prelogin.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-assessment-coupons',
  templateUrl: './assessment-coupons.component.html',
  styleUrls: ['./assessment-coupons.component.scss']
})
export class AssessmentCouponsComponent implements OnInit {

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  isLoading = true;

  loggedInUserDetails;

  optionForm: FormGroup;

  // Stats and Buy More
  couponDetailsAndPriceResponse;
  isBuyMoreClicked = false;
  buyMoreSuccessEventMessage;

  getPurchasedCouponsPostData = {
    filterByType: Number,
    orderBy: 'date desc',
    search: '',
    download: '',
    offset: ''
  };
  purchasedCouponsList;
  purchasedCouponsListForCsvDownload;
  fileName = 'Purchased Coupons List';

  //Keep Track if partner is white labelled 
  whiteLabelledPartner: boolean = false;

  // Pagination
  noOfPages;

  // assign Coupon
  packageTypeSelectDropdown = []; // planSelect1, planSelect2, ...

  serialNumber = 1;

  assessmentTableSearch = new Subject<string>();
  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  constructor(private zone: NgZone, private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService, private _preloginService: PreloginService) {
    // subscribe to Coupons Bought Success flag and refresh the view
    this.dashboardService.getCouponBoughtFlag().subscribe(data => {
      this.zone.run(() => {
        this.getPartnerCouponDetails();
        this.getPurchasedCouponsList();
        this.isBuyMoreClicked = !this.isBuyMoreClicked; // or set it to false
      });
    });

    this._preloginService.getWhiteLabelling().subscribe((data) => {
      if (data === 1) {
        this.whiteLabelledPartner = true;
      } else {
        this.whiteLabelledPartner = false;
      }
    });


    // search
    this.assessmentTableSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.setFilterAndGetList();
      });
  }


  ngOnInit() {
    this.loggedInUserDetails = this.dashboardService.decodeToken();

    this.optionForm = this.formBuilder.group({
      filterByType: ['']
    });

    this.getPartnerCouponDetails();

    this.getPurchasedCouponsList();

    this.optionForm.get('filterByType').valueChanges.subscribe(optedValue => {
      this.getPurchasedCouponsPostData.filterByType = optedValue;
      this.setFilterAndGetList();
    });
  }

  //
  setFilterAndGetList() {
    this.getPurchasedCouponsPostData.offset = '0';
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(this.myDummyEvent);
    }
    this.getPurchasedCouponsList();
  }


  // get stats and Buy More Popup Info
  getPartnerCouponDetails() {
    this.dashboardService.getPartnerCouponDetails().subscribe(response => {
      this.isLoading = false;
      if (response.statusCode == 200 && response.status == true) {
        this.couponDetailsAndPriceResponse = response.data;
      }
    },
      error => {
        this.isLoading = false;
      });
  }

  // Buy More
  buyMoreClickAction() {
    this.isBuyMoreClicked = !this.isBuyMoreClicked;
  }

  // Coupons table
  getPurchasedCouponsList() {
    this.dashboardService.getPurchasedCouponsList(this.getPurchasedCouponsPostData).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        if (response.data != null) {
          if (this.getPurchasedCouponsPostData.download == '1') {
            this.getPurchasedCouponsPostData.download = '';
            this.purchasedCouponsListForCsvDownload = response.data.couponDetails;
            this.dashboardService.exportToCsvService(this.fileName, this.purchasedCouponsListForCsvDownload);
          }
          else {
            // creating select drop-downs (dynamic)
            this.packageTypeSelectDropdown = [];  // resetting the array, otherwise new fields are appended to it.
            (response.data.couponDetails).forEach((coupon, index) => {
              // selects will be generated with names as planSelect1, planSelect2, ...
              // STARTS with '1' i.e. planSelect1
              this.packageTypeSelectDropdown.push({ name: ('planSelect' + (index + 1)), value: (coupon.package_id == 2 && coupon.staged_at == 3) ? 3 : coupon.package_id });
              // INFO - if package_id == 2 and staged_at == 3, then 'college'
            });

            this.purchasedCouponsList = response.data.couponDetails;
            this.noOfPages = response.data.couponCount;
          }
        }
      }
      else if (response.status == false && response.statusCode == 200) {
        this.purchasedCouponsList = null;
      }
    });
  }

  // Download CSV

  downloadCsv() {
    this.getPurchasedCouponsPostData.download = '1';
    this.getPurchasedCouponsList();
  }

  // downloadCsv() {
  //   this.getPurchasedCouponsPostData.download = '1';
  //   const csvData = this.convertToCSV(this.purchasedCouponsListForCsvDownload);
  //   const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  //   saveAs(blob, 'purchased_coupons_list.csv');
  // }

  // convertToCSV(data: any[]) {
  //   const header = 'S.no.,Purchased On,Generated On,Used On,Services,Used By,Coupon Code,Products';
  //   const rows = data.map((item, index) => {
  //     const serialNumber = index + 1;
  //     const services = item.products.map((d)=>{
  //       return d.product_name
  //     })
  //     const rowData = [
  //       serialNumber,
  //       item.purchased_on,
  //       item.generated_on,
  //       item.used_on,
  //       services,
  //       item.name_of_student,
  //       item.coupon,
  //       items.products

  //     ];
  //     return rowData.join(',');
  //   });
  //   return header + '\n' + rows.join('\n');
  // }



  // Assign Purchased coupon
  assignPurchasedCoupon(itemIndex, dropdownSelectedPackageValue, couponCode) {
    const assignPurchasedCouponPostData = {
      coupon: couponCode,
      package: parseInt(dropdownSelectedPackageValue)
    };

    this.dashboardService.assignPurchasedCoupon(assignPurchasedCouponPostData).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.purchasedCouponsList[itemIndex].package_id = dropdownSelectedPackageValue;
      }
    });
  }

  // Paginate (with serialNumber update)
  paginate(event) {
    this.serialNumber = event.first + 1;
    this.getPurchasedCouponsPostData.offset = (event.first);
    this.getPurchasedCouponsList();
  }

}
