import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, Subject, BehaviorSubject, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";

import { NetworkService } from "./network.service";
import { LazyLoadFilesService } from "./lazy-load-files.service";
// General Methods - isUserLoggedIn()

@Injectable({
  providedIn: "root",
})
export class PreloginService extends NetworkService {
  baseUrl;
  adminBaseUrl;
  supportedLoginUrl;

  isLoggedInForNav = false;
  private whiteLabellingSubject = new BehaviorSubject<number>(null);
  private partnerLogoSubject = new BehaviorSubject<string>(null);
  private loggedInFlagForNavSubject = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private http: HttpClient,
    private lazyLoadService: LazyLoadFilesService,
    private router: Router
  ) {
    super();
    // console.log('prelogin service cons- ', this.getBaseUrls());
    this.baseUrl = this.getBaseUrls().baseUrl;
    this.adminBaseUrl = this.getBaseUrls().adminBaseUrl;
    this.supportedLoginUrl = this.getBaseUrls().supportedLoginUrl;
  }

  // Sidebar toggle on mobile START
  setLoggedInFlagForNav(myFlag) {
    this.isLoggedInForNav = myFlag;
    this.loggedInFlagForNavSubject.next(this.isLoggedInForNav);
  }

  getLoggedInFlagForNav(): Observable<any> {
    return this.loggedInFlagForNavSubject.asObservable();
  }
  // Sidebar toggle on mobile END

  // Partner Registration
  registerPartner_Step1(data) {
    return this.http.post<any>(
      this.baseUrl + "register",
      data,
      this.httpOptions
    );
  }

  isDomainAvailable(data) {
    return this.http.post<any>(
      this.baseUrl + "checkDomain",
      data,
      this.httpOptions
    );
  }

  uploadImage(data) {
    return this.http.post<any>(this.baseUrl + "uploadImage", data);
  }

  removeImage(data) {
    return this.http.post<any>(
      this.baseUrl + "removeImage",
      data,
      this.httpOptions
    );
  }

  registerPartner_Step2(data) {
    return this.http.post<any>(
      this.baseUrl + "register",
      data,
      this.httpOptions
    );
  }

  // Counsellor Registration
  decodeInvitation(data) {
    return this.http.post<any>(
      this.baseUrl + "decodeInvitation",
      data,
      this.httpOptions
    );
  }

  counselorRegistration(data) {
    return this.http.post<any>(
      this.baseUrl + "counselorRegister",
      data,
      this.httpOptions
    );
  }

  // Login
  login(data): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + "login", data, this.httpOptions)
      .pipe(
        map((data) => {
          if (data.statusCode == 200 && data.status == true) {
            const decodedToken: any = jwt_decode(data.token);

            // block counsellor if its partner has no active plan else proceed (also see login component)
            if (
              !(
                decodedToken.counselor_id != null &&
                decodedToken.subscription_status == 0 &&
                decodedToken.trial_period == 0
              )
            ) {
              localStorage.setItem("pp_session", data.session);
              localStorage.setItem("pp_token", data.token);
              // this.getMyProfile();
              this.setLoggedInFlagForNav(true);

              this.lazyLoadService.loadRazorpayScript();
            }
          }
          return data;
        })
      );
  }

  getMyProfile() {
    console.log("Came here to profile");
    this.httpOptions.headers = this.httpOptions.headers.set(
      "token",
      localStorage.getItem("pp_token")
    );
    this.httpOptions.headers = this.httpOptions.headers.set(
      "session",
      localStorage.getItem("pp_session")
    );
    console.log(this.httpOptions.headers);
    this.http
      .get<any>(this.baseUrl + "getMyProfile", this.httpOptions)
      .subscribe((res) => {
        let data: any = {};
        console.log(res.data.userInfo[0].white_labelling);
        data.value = res.data.userInfo[0].white_labelling;
        this.setWhiteLabellingData(res.data.userInfo[0].white_labelling);
        this.setPartnerLogo(res.data.userInfo[0].image, res.data.userInfo[0].id);
      });
  }

  setPartnerLogo(image: string, partner_id: number) {
    var imageUrl = environment.logo_baseUrl + partner_id + '/' + image + '?w=350&h=75&fit=crop&crop=faces'
    this.partnerLogoSubject.next(imageUrl);
  }
  getPartnerLogo(): Observable<string> {
    return this.partnerLogoSubject.asObservable();
  }

  setWhiteLabellingData(data) {
    if (data === null) {
      data = 0;
    } else {
      data = 1;
    }
    localStorage.setItem("whiteLabelling", data.toString());
    this.whiteLabellingSubject.next(data);
  }

  resetPassword(data) {
    return this.http.post<any>(this.baseUrl + "resetCounselorPassword", data);
  }

  forgotPassword(data) {
    return this.http.post<any>(this.baseUrl + "forgotPassword", data);
  }

  isResetPasswordValid(data) {
    console.log(data);
    return this.http.post<any>(this.baseUrl + "isResetPasswordValid", data);
  }

  sendVerificationEmail(data) {
    return this.http.post<any>(
      this.baseUrl + "register",
      data,
      this.httpOptions
    );
  }

  verifyEmail(data) {
    return this.http.post<any>(this.baseUrl + "verifyEmail", data);
  }

  getWhiteLabelling(): Observable<number> {
    return this.whiteLabellingSubject.asObservable();
  }

  // isLoggedIn for auth
  isUserLoggedIn() {
    if (localStorage.getItem("pp_session") != null) {
      // re-calling lazy load scripts as they get vanished on page reload.
      this.lazyLoadService.loadRazorpayScript();

      return true;
    } else {
      this.setLoggedInFlagForNav(false);
      return false;
    }
  }

  // get logged in user role for auth
  // 0 - Partner, 1 - Admin, 2 - Counsellor, 3 - Super-admin
  getCurrentRole() {
    if (localStorage.getItem("pp_session") !== null) {
      const decodedToken: any = jwt_decode(localStorage.getItem("pp_token"));

      if (decodedToken.partner_id !== null && decodedToken.role === null) {
        return 0; // Partner
      } else if (decodedToken.counselor_id != null) {
        if (decodedToken.role === 1) {
          return 1; // Admin
        }
        if (decodedToken.role === 2) {
          return 2; // Counsellor
        }
      } else if (decodedToken.admin_id) {
        return 3; //  Super-admin
      }
    }
  }

  logOut(errorCode?: number) {
    localStorage.clear();

    this.setLoggedInFlagForNav(false);

    if (errorCode) {
      this.router.navigate(["/login"], { queryParams: { err: errorCode } });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  // Super-admin Login
  adminLogin(data): Observable<any> {
    return this.http
      .post<any>(this.adminBaseUrl + "login", data, this.httpOptions)
      .pipe(
        map((data) => {
          if (data.statusCode === 200 && data.status === true) {
            localStorage.setItem("pp_session", data.session);
            localStorage.setItem("pp_token", data.token);
            this.setLoggedInFlagForNav(true);

            this.lazyLoadService.loadRazorpayScript();
          }
          return data;
        })
      );
  }

  loginWithToken(data): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + "loginWithMindlerToken", data, this.httpOptions)
      .pipe(
        map((data) => {
          if (data.statusCode === 200) {
            data.session ? localStorage.setItem("pp_session", data.session) : null;
            data.token ? localStorage.setItem("pp_token", data.token) : null;

            this.getMyProfile();
            this.setLoggedInFlagForNav(true);

            this.lazyLoadService.loadRazorpayScript();
          }
          return data;
        })
      )
  }
}
