import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-career-profile',
  templateUrl: './career-profile.component.html',
  styleUrls: ['./career-profile.component.scss']
})
export class CareerProfileComponent implements OnInit {

  isLoading = true;

  studentId;

  apiResponse;

  isDataAvailable;

  dailySchedule;
  dailyScheduleData: any;
  dailyScheduleChartOptions: any;
  dailyScheduleChartLabels;

  singleValuePieOptions: any;

  isScheduleDataAvailable;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

    this.getCareerActivities();
  }


  getCareerActivities() {
    this.isLoading = true;

    this.dashboardService.getCareerActivities({ student_id: this.studentId }).subscribe(response => {
      this.isLoading = false;

      if (response.status === true && response.statusCode === 200) {
        this.apiResponse = response.data;

        if (this.apiResponse.workstyle.length === 0 &&
          this.apiResponse.get_specific_match.length === 0 &&
          this.apiResponse.get_motivators.length === 0 &&
          this.apiResponse.schedule === '' &&
          this.apiResponse.rank.length === 0) {
          this.isDataAvailable = false;
        }
        else {
          this.isDataAvailable = true;
        }

        if (Object.keys(this.apiResponse.schedule).length > 0) {
          this.displayScheduleChart(this.apiResponse.schedule);
          this.isScheduleDataAvailable = true;
        }
        else {
          this.isScheduleDataAvailable = false;
        }
      }
    });
  }


  displayScheduleChart(scheduleData) {
    this.dailyScheduleChartLabels = ['At School', 'Sleeping', 'Self Study', 'Entertainment', 'Tuition', 'Daily Chores'];

    this.dailyScheduleData = {
      labels: this.dailyScheduleChartLabels,
      datasets: [
        {
          data: [
            scheduleData.at_school,
            scheduleData.sleeping,
            scheduleData.self_study,
            scheduleData.entertainment,
            scheduleData.tution,
            scheduleData.daily_chores
          ],
          backgroundColor: [
            '#007FB6',
            '#50BB11',
            '#FFC000',
            '#EA6915',
            '#FB4444',
            '#8964BF'
          ]
        }]
    };

    this.dailyScheduleChartOptions = {
      legend: {
        position: 'bottom'
      }
    };


    this.singleValuePieOptions = {
      tooltips: {
        enabled: false
      }
    };
  }

}
