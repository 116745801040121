import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {

  isLoading = true;

  studentId;

  apiResponse;

  isDataAvailable;

  dailySchedule;
  dailyScheduleData: any;
  dailyScheduleChartOptions: any;
  dailyScheduleChartLabels;

  singleValuePieOptions: any;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) {
    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

    this.getCareerInsights();
  }

  ngOnInit() {
    this.singleValuePieOptions = {
      tooltips: {
        enabled: false
      }
    };
  }

  getCareerInsights() {
    this.isLoading = true;

    this.dashboardService.getCareerInsights({ student_id: this.studentId }).subscribe(response => {
      this.isLoading = false;
      if (response.status === true && response.statusCode === 200) {
        this.apiResponse = response.data;
      }
    });
  }

}
