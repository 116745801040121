import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { FormBuilder } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { Router, ActivatedRoute, NavigationStart, Event } from '@angular/router';
import { CounsellorRegistrationComponent } from 'src/app/prelogin/counsellor-registration/counsellor-registration.component';
import { PlatformLocation } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent implements OnInit {

  isLandingScreen = true;

  activeTabName = 'preferences';

  location = 'india';

  noOfRoadmaps;
  noOfIndiaRoadmaps;
  noOfAbroadRoadmaps;

  isDisabledTab = true;
  isCollegeTabDisabled = true;
  isExamTabDisabled = true;
  showConfirmationModal = false;

  myRoutes = ['/roadmap/preferences', '/roadmap/college-map', '/roadmap/exam-shortlist',
    '/roadmap/abroad/preferences', '/roadmap/abroad/college-map'];

  constructor(private formBuilder: FormBuilder,private platformLocation: PlatformLocation , private router: Router,
    private activatedRoute: ActivatedRoute, private validationService: ValidationsService,
    private dashboardService: DashboardService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.myRoutes.indexOf(event.url) !== -1) {
          const lastIndex = (event.url).lastIndexOf('/');
          this.activeTabName = (event.url).substring(lastIndex + 1);
        }
        // if on preferences/college-map or any tab, and user clicks on 'Roadmap' in side-nav,
        // then without below condition it causes an issue
        if (event.url === '/roadmap') {
          this.isLandingScreen = true;
        }
      }
    });

    this.dashboardService.getRoadmapTabFlag().subscribe(data => {
      if (data) {
        if (data === 'enableCollegeTab') {
          this.isCollegeTabDisabled = false;
        }
        if (data === 'enableExamTab') {
          this.isCollegeTabDisabled = false;
          this.isExamTabDisabled = false;
        }
      }
      else {
        this.isCollegeTabDisabled = true;
        this.isExamTabDisabled = true;
      }
    });
    this.changeRoadmapStatus(0);

    //Calling Function to notify users if they click back on Browser without saving Roadmap
    // platformLocation.onPopState(() => this.confirmRoadmapDeletion());

  }

  ngOnInit() {
    this.activeTabName = 'preferences';

    if (localStorage.getItem('roadmap_id') != null && localStorage.getItem('student_id') == null) {
      this.isLandingScreen = false;

      if (localStorage.getItem('roadmap_type')) {
        this.location = localStorage.getItem('roadmap_type');
        if(this.location=='india'){
          this.noOfRoadmaps= this.noOfIndiaRoadmaps;
        }
        if(this.location=='abroad'){
          this.noOfRoadmaps = this.noOfAbroadRoadmaps;
        }
        

        if (localStorage.getItem('roadmap_type') === 'india') {
          this.router.navigate(['preferences'], { relativeTo: this.activatedRoute });
        }
        else if (localStorage.getItem('roadmap_type') === 'abroad') {
          this.router.navigate(['abroad/preferences'], { relativeTo: this.activatedRoute });
        }
      }
      else {
        this.location = 'india';
        this.router.navigate(['preferences'], { relativeTo: this.activatedRoute });
      }
    }


    this.getSavedRoadmapList();
  }

  goBack() {
    this.isLandingScreen = true;
    localStorage.removeItem('roadmap_id');

    if (localStorage.getItem('fromSavedRoadmap') != null) {
      localStorage.removeItem('fromSavedRoadmap');
      if(localStorage.getItem('roadmap_type')==='india'){
        this.router.navigate(['/saved-roadmaps','india']);
      }
      else{
        this.router.navigate(['/saved-roadmaps','abroad']);
      }
    }
    else {
      this.getSavedRoadmapList();
      this.router.navigate(['roadmap']);
    }
  }

  getSavedRoadmapList() {
    const getRoadmapListPostData = {
      offset: 0
    };

    this.dashboardService.getSavedRoadmapList(getRoadmapListPostData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.noOfIndiaRoadmaps = response.data.count;
      }
      else if (response.status === false && response.statusCode === 200) {
        this.noOfIndiaRoadmaps = 0;
      }
    });

    this.dashboardService.getSavedAbroadRoadmapList(getRoadmapListPostData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.noOfAbroadRoadmaps = response.data.count;
      }
      else if (response.status === false && response.statusCode === 200) {
       this.noOfAbroadRoadmaps = 0;
      }
      if(this.location==='india'){
        this.changeRoadmapStatus(0);
      }
      else{
        this.changeRoadmapStatus(1);
      }
    });
  }

  setCountryPreference() {
    const postData = {
      country: this.location
    };

    const apiToHit = (this.location === 'india') ? 'setCountryPreference' : 'setAbroadCountryPreference';

    this.dashboardService[apiToHit](postData).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        localStorage.setItem('roadmap_id', response.data.roadmap_id);
        localStorage.setItem('roadmap_type', this.location);

        this.isLandingScreen = false;

        if (this.location === 'india') {
          this.router.navigate(['preferences'], { relativeTo: this.activatedRoute });
        }
        else if (this.location === 'abroad') {
          this.router.navigate(['abroad/preferences'], { relativeTo: this.activatedRoute });
        }
      }
    });
  }

  changeRoadmapStatus(ind){
    ind = parseInt(ind);
    if(ind==0){
      this.location='india';
      this.noOfRoadmaps = this.noOfIndiaRoadmaps;
    }
    else{
      this.location='abroad';
      this.noOfRoadmaps = this.noOfAbroadRoadmaps;
    }
  }

  deleteRoadmap() {
		const deletePostData = {
			roadmapid: localStorage.getItem('roadmap_id')
		};

    var roadmapLocation = localStorage.getItem('roadmap_type');

		if(roadmapLocation==='india'){
				this.dashboardService.deleteRoadmap(deletePostData).subscribe(response => {
					if (response.status === true && response.statusCode === 200) {
						   console.log("Delete Successfully");
					}
				});
		}
		else{
			this.dashboardService.deleteAbroadRoadmap(deletePostData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
          console.log("Delete Successfully");
				}
			});
		}
    $('#deleteRoadmapModal').modal('hide');
    this.goBack();
	}

  alertUser(){
    //If a roadmap is already saved or visited from student section 
    if(localStorage.getItem('fromSavedRoadmap')==='yes' || localStorage.getItem('student_id')!==null){
      this.goBack();
      return ;
    }
    $('#deleteRoadmapModal').modal('show');
  }
}
