import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assessmentType'
})
export class AssessmentTypePipe implements PipeTransform {

  transform(package_id: any, staged_at: any): any {
    if (package_id == 1) {
      return 'Stream';
    } else if (package_id == 2 && staged_at == 2) {
      return 'Career';
    } else if (package_id == 2 && staged_at == 3) {
      return 'College';
    }
    return null;
  }

}
