import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject,from, forkJoin, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

declare var $: any;
@Component({
	selector: 'app-saved-roadmaps',
	templateUrl: './saved-roadmaps.component.html',
	styleUrls: ['./saved-roadmaps.component.scss']
})
export class SavedRoadmapsComponent implements OnInit {

	isLoading = true;

	@ViewChild('roadmapDownload', {static: false}) pdfTable: ElementRef; 

	// search;
	roadmapSearch = new Subject<string>();

	getRoadmapListPostData;
	roadmapListApiResponse;

	currentRowRoadmapId;
	roadmapAction;
	roadmapLocation; 
	
	roadmapNameForDownload;

	// saveRoadmap
	roadmapNameForm: FormGroup;
	roadmapNameFormSubmitted;
	nameAlreadyExists;

	noOfPages;
	search;

	isRoadmapDataLoading = false;

	// assign
	selectedStudents = [];
	filteredStudents = [];
	firstNames = [];

	// un-assign
	getAssignedStudentsList;

	downloadRoadmapCareers : any =[];

	// download csv
	roadmapListApiResponseForCsvDownload;

	constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private activatedRoute: ActivatedRoute,
		 private router: Router) {
		this.activatedRoute.params.subscribe(params=>{
			this.roadmapLocation = params["location"];
		})
		
		// Search
		this.roadmapSearch.pipe(
			debounceTime(400),
			distinctUntilChanged())
			.subscribe(value => {
				this.getRoadmapListPostData.search = value;
				this.getRoadmapListPostData.offset = 0;
				this.getSavedRoadmapList();
			});
	}

	ngOnInit() {
		// For Bootstrap tooltip, but bootstrap tooltip does not works inside ngIf, ngFor. Used primeNg tooltip.
		// $('[data-toggle="tooltip"]').tooltip();

		this.getRoadmapListPostData = {
			offset: 0
		};

		this.roadmapNameForm = this.formBuilder.group({
			roadmap_name: ['', [Validators.required,Validators.pattern("^[ A-Za-z0-9_./@-]*$"),Validators.maxLength(50)]]
		});

		this.getSavedRoadmapList();
	}

	// getters
	get roadmapName() { return this.roadmapNameForm.get('roadmap_name'); }


	getSavedRoadmapList() {
		this.isLoading = true;
			if(this.roadmapLocation=== 'india'){  //If we were redirected from India , getting India data
				this.dashboardService.getSavedRoadmapList(this.getRoadmapListPostData).subscribe(response => {
					this.isLoading = false;

					if (response.status === true && response.statusCode === 200) {
						if (this.getRoadmapListPostData.download === 1) {
							this.getRoadmapListPostData.download = 0;
							this.roadmapListApiResponseForCsvDownload = response.data.roadmaps;
							this.dashboardService.exportToCsvService('Saved Roadmaps List', this.roadmapListApiResponseForCsvDownload);
						}
						else {
							this.roadmapListApiResponse = response.data;
							this.noOfPages = response.data.count;
							this.getRoadmapListPostData.download = 0;
						}

					}
					else if (response.status === false && response.statusCode === 200) {
						this.roadmapListApiResponse = null;
						this.noOfPages = 0;
						this.getRoadmapListPostData.download = 0;
					}
				});
			}
			else{           // Else getting Abroad RoadMap data;
				this.dashboardService.getSavedAbroadRoadmapList(this.getRoadmapListPostData).subscribe(response => {
					this.isLoading = false;

					if (response.status === true && response.statusCode === 200) {
						if (this.getRoadmapListPostData.download === 1) {
							this.getRoadmapListPostData.download = 0;
							this.roadmapListApiResponseForCsvDownload = response.data.roadmaps;
							this.dashboardService.exportToCsvService('Saved Roadmaps List', this.roadmapListApiResponseForCsvDownload);
						}
						else {
							this.roadmapListApiResponse = response.data;
							this.noOfPages = response.data.count;
							this.getRoadmapListPostData.download = 0;
						}

					}
					else if (response.status === false && response.statusCode === 200) {
						this.roadmapListApiResponse = null;
						this.noOfPages = 0;
						this.getRoadmapListPostData.download = 0;
					}
				});

			}
	}

	// Edit Roadmap - Redirect to roadmap/preferences
	editRoadmap(roadmapId, roadmapName) {
		if(this.roadmapLocation==='abroad'){
			localStorage.setItem('roadmap_type','abroad');
		}
		else{
			localStorage.setItem('roadmap_type','india');
		}
			localStorage.setItem('roadmap_id', roadmapId);
			localStorage.setItem('fromSavedRoadmap', 'yes');
			localStorage.setItem('savedRoadmapName', roadmapName);
			this.router.navigate(['/roadmap']);
	}


	setCurrentRowRoadmapId(action, roadmapId, roadmapName) {
		this.currentRowRoadmapId = roadmapId;   // IMPORTANT (Used everywhere)

		this.roadmapNameForm.reset();
		this.roadmapNameFormSubmitted = false;

		if (action === 'rename') {
			this.roadmapAction = action;
			if (roadmapName == null) {
				roadmapName = '';
			}
			this.roadmapNameForm.patchValue({
				roadmap_name: roadmapName
			});
			$('#saveRoadmapNameModal').modal('show');
		}
		else if (action === 'delete') {
			$('#deleteRoadmapModal').modal('show');
		}
		else if (action === 'copy') {
			this.roadmapAction = action;
			$('#saveRoadmapNameModal').modal('show');
		}
		else if (action === 'addStudent') {
			this.selectedStudents = [];
			this.filteredStudents = [];
			$('#assignRoadmapToStudentsModal').modal('show');
		}
		else if (action === 'removeStudent') {
			this.getListOfAssignedStudents();
			$('#unAssignRoadmapFromStudentsModal').modal('show');
		}
	}

	// multi-select autocomplete
	filteredStudentsMultiple(event) {
		const query = event.query;
		this.dashboardService.getStudentNamesList({ name: query }).subscribe(response => {
			this.filteredStudents = response.data.studentList;
		});
	}

	// assign
	assignRoadmapToStudents() {
		const students = [];
		this.selectedStudents.forEach(element => {
			students.push(element.uid);
		});

		const mapStudentWithRoadmapPostData = {
			studentIds: students,
			roadmapid: this.currentRowRoadmapId
		};

		if(this.roadmapLocation=='india'){
			this.dashboardService.assignStudentWithRoadmap(mapStudentWithRoadmapPostData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					$('#assignRoadmapToStudentsModal').modal('hide');
					this.getSavedRoadmapList();
				}
			});
		}
		else{
			this.dashboardService.assignStudentWithAbroadRoadmap(mapStudentWithRoadmapPostData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					$('#assignRoadmapToStudentsModal').modal('hide');
					this.getSavedRoadmapList();
				}
			});
		}
	}

	// Un-assign
	getListOfAssignedStudents() {
		const postData = {
			roadmapid: this.currentRowRoadmapId
		};
		if(this.roadmapLocation === 'india'){
			this.dashboardService.getListOfAssignedStudents(postData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					this.getAssignedStudentsList = response.data.studentList;
				}
			});
		}
		else{
			this.dashboardService.getListOfAssignedStudentsAbroad(postData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					this.getAssignedStudentsList = response.data.studentList;
				}
			});
		}
	}

	unAssignRoadmap(studentId) {
		const postData = {
			roadmapid: this.currentRowRoadmapId,
			student_id: studentId
		};
		if(this.roadmapLocation==='india'){
			this.dashboardService.unAssignStudentFromRoadmap(postData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					this.getListOfAssignedStudents();
					this.getSavedRoadmapList();
				}
			});
		}
		else{
			this.dashboardService.unAssignStudentFromAbroadRoadmap(postData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					this.getListOfAssignedStudents();
					this.getSavedRoadmapList();
				}
			});

		}
	}

	// Rename, Copy
	roadmapNameFormSubmit() {
		this.roadmapNameFormSubmitted = true;
		this.roadmapName.setValue(this.roadmapNameForm.value.roadmap_name.trim());
		if (this.roadmapNameForm.invalid) {
			return;
		}

		if (this.roadmapAction === 'rename') {
			const renamePostData = {
				roadmapid: this.currentRowRoadmapId,
				roadmap_name: this.roadmapName.value,
				rename: 1
			};

			if(this.roadmapLocation==='india'){
				this.dashboardService.saveRoadmapName(renamePostData).subscribe(response => {
					if (response.status === true && response.statusCode === 200) {
						if (response.message === 'Name assigned successfully') {
							this.nameAlreadyExists = false;
							this.roadmapNameForm.reset();
							this.roadmapNameFormSubmitted = false;
							$('#saveRoadmapNameModal').modal('hide');
							this.getSavedRoadmapList();
						}
						else if (response.message === 'Roadmap name already exists') {
							this.nameAlreadyExists = true;
						}
					}
				});
			}
			else{
				this.dashboardService.saveAbroadRoadmapName(renamePostData).subscribe(response => {
					if (response.status === true && response.statusCode === 200) {
						if (response.message === 'Name assigned successfully') {
							this.nameAlreadyExists = false;
							this.roadmapNameForm.reset();
							this.roadmapNameFormSubmitted = false;
							$('#saveRoadmapNameModal').modal('hide');
							this.getSavedRoadmapList();
						}
						else if (response.message === 'Roadmap name already exists') {
							this.nameAlreadyExists = true;
						}
					}
				});
			}
		}
		else if (this.roadmapAction === 'copy') {
			const copyRoadmapPostData = {
				roadmapid: this.currentRowRoadmapId,
				roadmap_name: this.roadmapName.value
			};

			if(this.roadmapLocation==='india'){
			this.dashboardService.copyRoadmap(copyRoadmapPostData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					if (response.message === 'Success') {
						this.nameAlreadyExists = false;
						this.roadmapNameForm.reset();
						this.roadmapNameFormSubmitted = false;
						$('#saveRoadmapNameModal').modal('hide');
						this.getSavedRoadmapList();
					}
					else if (response.message === 'Roadmap name already exists') {
						this.nameAlreadyExists = true;
					}
				}
			});
		}
		else{
			this.dashboardService.copyAbroadRoadmap(copyRoadmapPostData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					if (response.message === 'Success') {
						this.nameAlreadyExists = false;
						this.roadmapNameForm.reset();
						this.roadmapNameFormSubmitted = false;
						$('#saveRoadmapNameModal').modal('hide');
						this.getSavedRoadmapList();
					}
					else if (response.message === 'Roadmap name already exists') {
						this.nameAlreadyExists = true;
					}
				}
			});
		}
		}
	}

	// Delete
	deleteRoadmap() {
		const deletePostData = {
			roadmapid: this.currentRowRoadmapId,
		};

		if(this.roadmapLocation==='india'){
				this.dashboardService.deleteRoadmap(deletePostData).subscribe(response => {
					if (response.status === true && response.statusCode === 200) {
						$('#deleteRoadmapModal').modal('hide');
						this.getRoadmapListPostData.offset = 0;
						this.getSavedRoadmapList();
					}
				});
		}
		else{
			this.dashboardService.deleteAbroadRoadmap(deletePostData).subscribe(response => {
				if (response.status === true && response.statusCode === 200) {
					$('#deleteRoadmapModal').modal('hide');
					this.getRoadmapListPostData.offset = 0;
					this.getSavedRoadmapList();
				}
			});
		}
	}


	resetFlag() {
		this.nameAlreadyExists = false;
	}

	goBack() {
		localStorage.removeItem('roadmap_id');
		if(localStorage.getItem('fromSavedRoadmap')!=null){
			localStorage.removeItem('fromSavedRoadmap');
		}
		this.router.navigate(['roadmap']);
	}

	downloadRoadmap(roadmap : any){
		this.isRoadmapDataLoading = true;
		this.downloadRoadmapCareers.length=0;
		this.roadmapNameForDownload =  roadmap.roadmap_name;
		localStorage.setItem('roadmap_id',roadmap.roadmap_id);
		var data = {
			target_career : null,
			backup_career : null,
			backup_career_2 : null
		};
		if(this.roadmapLocation==='india'){
			this.dashboardService.getCurrentRoadmapPreferences().subscribe((response)=>{
				data = response.data.roadmapDetails[0];
				this.getColleges(data);
			});
		}
		else{
			this.dashboardService.getAbroadCurrentRoadmapPreferences().subscribe((response)=>{
				data = response.data.roadmapDetails[0];
				this.getColleges(data);
			});
		}
	}

	public getColleges(data : any) : void{ 
		
		let targetColleges$ = of(null);
		let backupColleges$ = of(null);
		let backup2Colleges$ = of(null);
		let targetExamsList$ = of(null);
		let backupExamsList$ = of(null);
		let backup2ExamsList$ = of(null);
		
		if(data.target_career!=null){
			this.downloadRoadmapCareers.push({'career' : data.target_career,type: 'Target Career'});
			targetColleges$ = this.getShortlistedCollegesList(data.target_career);
			targetExamsList$ = this.getShortListedExams(data.target_career);
		}
		
		if(data.backup_career!=null){
			this.downloadRoadmapCareers.push({'career' : data.backup_career, type : 'Backup Career'});
			backupColleges$ = this.getShortlistedCollegesList(data.backup_career);
			backupExamsList$ = this.getShortListedExams(data.backup_career);
		}

		if(data.backup_career_2!=null){
			this.downloadRoadmapCareers.push({'career' : data.backup_career_2,type : 'Backup Career 2'});
			backup2Colleges$  = this.getShortlistedCollegesList(data.backup_career_2);
			backup2ExamsList$ = this.getShortListedExams(data.backup_career_2);
		}

		forkJoin([
			targetColleges$,
			backupColleges$,
			backup2Colleges$,
			targetExamsList$,
			backupExamsList$,
			backup2ExamsList$
		]).subscribe(([targetCollegesResponse,backupCollegesResponse,backup2CollegesResponse,
			 targetExamsResponse,backupExamsResponse,backup2ExamsResponse])=>{
				
				//Handling Responses

				if(targetCollegesResponse!=null){
					this.downloadRoadmapCareers[0]["colleges"]= targetCollegesResponse.data? targetCollegesResponse.data.colleges: [];
					if(targetExamsResponse!=null){
						this.downloadRoadmapCareers[0]["exams"] =  targetExamsResponse.data?targetExamsResponse.data.exams :[] ;
					}
				}
				
				if(backupCollegesResponse!=null){
					this.downloadRoadmapCareers[1]["colleges"]= backupCollegesResponse.data? backupCollegesResponse.data.colleges: [];
					if(backupExamsResponse!=null){
						this.downloadRoadmapCareers[1]["exams"] =  backupExamsResponse.data?backupExamsResponse.data.exams :[] ;
					}
				}

				if(backup2CollegesResponse!=null){
					this.downloadRoadmapCareers[2]["colleges"]=backup2CollegesResponse.data? backup2CollegesResponse.data.colleges: [];
					if(backup2ExamsResponse!=null){
						this.downloadRoadmapCareers[2]["exams"] = backup2ExamsResponse.data?backup2ExamsResponse.data.exams :[] ;
					}
				}

				console.log(this.downloadRoadmapCareers);

				//Waiting for 1 second to allow data to reflect in HTML
				setTimeout(()=>{
					this.downloadPdf();
					this.isRoadmapDataLoading = false;
				},1000);
		})
	}

	getShortlistedCollegesList(career : string){
		// downloadRoadmapCareers
		const data = {
			city : [],
			course : [],
			domain : career,
			duration : [],
			fees : [],
			limit : 100000,
			offset: 0,
			search : "",
			state : [],
			type : []
		}
		if(this.roadmapLocation==='india'){
			return this.dashboardService.getShortlistedCollegesList(data);
		}
		return this.dashboardService.getAbroadShortlistedCollegesList(data);
	}

	getShortListedExams(career : string){
		//Returning Null Observable for Abroad Roadmap, Since there is no Exams List as of now
		if(this.roadmapLocation !== 'india'){
			return of(null);
		}


		const data = {
			domain : career,
			limit : 100000,
			offset: 0,
			search : ""
		}
		return this.dashboardService.getSubscribedExamsListRoadmap(data);
	}

	public downloadPdf() {

		const pdfTable = this.pdfTable.nativeElement;
		var html = htmlToPdfmake(pdfTable.innerHTML,{
			tableAutoSize : true
		});
		const documentDefinition = { content: html,
			pageBreakBefore: function(currentNode) {
				return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
			},
			styles: {
				'content' : {
					'color' : '#888888',
				},
				'heading': {
					'color' : '#484848', 
					'font-weight' : '200',
				},
				'color-blue' : {
					'color' : '#007fb6',
					textAlign : 'center'
				},
				'text-bold': {
					'bold' :'bold',
				},
				'sub-heading' : {
					'color' : '#484848',
				},
				'mt-3' : {
					margin : [0, 10, 0, 0]
				}
			},
		 };
		pdfMake.createPdf(documentDefinition).open(); 
	}

	paginate(event) {
		this.getRoadmapListPostData.offset = (event.first);
		this.getSavedRoadmapList();
	}

}
