import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-abroad-colleges',
  templateUrl: './abroad-colleges.component.html',
  styleUrls: ['./abroad-colleges.component.scss']
})
export class AbroadCollegesComponent implements OnInit {

  selected: boolean[]=[];

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  isLoading = false;
  isDataLoading = false;

  target_career;
  backup_career;
  backup_career_2;
  collegeBucket = [];
  selectedColleges = [];
  unselectedColleges = [];
  viewShortlistedCollegesList = [];
  viewShortlistedClicked;

  initialShortlistedColleges = [];
  isNotSaved=true;
  nothingChanged= true;



  activeCareerTab; // target_career, backup_career, backup_career_2

  careerDomainName;

  // Filter drop-down options
  filterOptions = {
    country: [],
    institution_type: [],
    research_intensity: [],
    scholarships: []
  };

  // Filter selected values
  country: [];
  institution_type: [];
  research_intensity: [];
  scholarships: [];
  indexesOfColleges =[];  roadmapNameForm: FormGroup;
  roadmapNameFormSubmitted;
  nameAlreadyExists;

  // College List Table
  collegeListApiResponse;
  collegeListFromApi;

  isTableDataLoading = true;  // Important (if false initially, then we get 'length of undefined' error)

  limit;  // show Records
  search;



  collegeSearch = new Subject<string>();

  getCollegesListPostData: any = {};

  collegeDetails;
  isDetailAvailable;
  disableSaveRoadMap = false;
  collegeName;

  // Pagination
  noOfPages;

  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  constructor(private dashboardService: DashboardService,private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute, private router: Router) {
    // Search
    this.collegeSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.search = value;
        this.getCollegesListPostData.search = value;
        this.resetToFirstPageAndGetCollegeList(this.myDummyEvent);
      });
  }

  ngOnInit() {
    
    this.roadmapNameForm = this.formBuilder.group({
      roadmap_name: ['', Validators.required]
    });

    if(localStorage.getItem('student_id')!=null || localStorage.getItem('fromSavedRoadmap')){
        this.disableSaveRoadMap = true;
    }

    this.viewShortlistedClicked = false;
    this.limit = 10;
    this.activeCareerTab = 'target_career';

    this.target_career = localStorage.getItem('target_career');
    this.backup_career = localStorage.getItem('backup_career');
    this.backup_career_2 = localStorage.getItem('backup_career_2');

    this.careerDomainName = this.target_career;

    this.getListByDomain(this.activeCareerTab);
    this.getFiltersData();
  }


  getListByDomain(activeCareerTab) {
    // this.isDataLoading = true;

    if (activeCareerTab === 'target_career') {
      this.activeCareerTab = 'target_career';
    } else if (activeCareerTab === 'backup_career') {
      this.activeCareerTab = 'backup_career';
    } else if (activeCareerTab === 'backup_career_2') {
      this.activeCareerTab = 'backup_career_2';
    }

    this.viewShortlistedClicked = false;

    this.country = [];
    this.institution_type = [];
    this.research_intensity = [];
    this.scholarships = [];
    this.selectedColleges.length=0;
    this.unselectedColleges.length=0;

    this.getCollegesListPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(this.myDummyEvent);
    }
    this.collegeBucket.length=0
    this.getCollegeList();
    
  }

  // Get filter options data
  getFiltersData() {
    this.isDataLoading = true;

    this.dashboardService.getAbroadRoadmapCollegeMapFilters().subscribe(response => {
      this.isDataLoading = false;

      this.filterOptions.country = [];
      this.filterOptions.institution_type = [];
      this.filterOptions.research_intensity = [];
      this.filterOptions.scholarships = [];


      if (response.statusCode === 200 && response.status === true) {
        // populate country only for first time
        if (this.country.length === 0) {
          (response.data.country).forEach(element => {
            this.filterOptions.country.push({ label: element.name, value: element.country_id });
          });
        }

        (response.data.institution_type).forEach(element => {
          this.filterOptions.institution_type.push({ label: element.institution_type, value: element.institution_type });
        });

        (response.data.research_intensity).forEach(element => {
          this.filterOptions.research_intensity.push({ label: element.research_intensity, value: element.research_intensity });
        });

        (response.data.scholarships).forEach(element => {
          this.filterOptions.scholarships.push({ label: element.scholarships, value: element.scholarships });
        });
      }
    });
  }

  clearFilters() {
    this.country = [];
    this.institution_type = [];
    this.research_intensity = [];
    this.scholarships = [];

    this.resetToFirstPageAndGetCollegeList(this.myDummyEvent);
  }


  // Colleges List Table
  getCollegeList() {
    if(this.viewShortlistedClicked===true){
      return ;
    }
    this.isTableDataLoading = true;

    this.getCollegesListPostData.domain = this.careerDomainName;
    this.getCollegesListPostData.limit = this.limit;

    this.getCollegesListPostData.country = this.country;
    this.getCollegesListPostData.institution_type = this.institution_type;
    this.getCollegesListPostData.research_intensity = this.research_intensity;
    this.getCollegesListPostData.scholarships = this.scholarships;

    
    this.dashboardService.getAbroadCollegesList(this.getCollegesListPostData).subscribe(response => {
      this.isTableDataLoading = false;

      if (response.status === true && response.statusCode === 200) {
        this.collegeListApiResponse = response.data;
        this.collegeListFromApi = response.data.colleges;
        this.noOfPages = response.data.count;
        this.selected.length = this.collegeListFromApi.length;
        this.selected.fill(false);


        this.collegeListFromApi.forEach(element => {
          this.indexesOfColleges.push(element.college_id);
        });


        (response.data.colleges).forEach(element => {
          if (element.college_shortlisted != null && this.collegeBucket.indexOf(element.college_id) === -1 && this.unselectedColleges.indexOf(element.college_id) === -1) {
            this.collegeBucket.push(element.college_id);
            this.selectedColleges.push(element.college_id);
          }
        });

      }
    });

  }

  addToBucket(collegeId, checkedStatus) {
    this.nothingChanged = false;
    if (checkedStatus === false) {
      this.selectedColleges.splice(this.selectedColleges.indexOf(collegeId), 1);
      if (this.unselectedColleges.indexOf(collegeId) === -1) {
        this.unselectedColleges.push(collegeId);
      }
      // UI
      this.collegeBucket.splice(this.collegeBucket.indexOf(collegeId), 1);
    }
    else {
      if (this.unselectedColleges.indexOf(collegeId) !== -1) {
        this.unselectedColleges.splice(this.unselectedColleges.indexOf(collegeId), 1);
      }
      this.selectedColleges.push(collegeId);
      // UI
      this.collegeBucket.push(collegeId);
    }
  }

  viewCollegeDetails(collegeId, collegeName) {
    const postData = {
      college_id: collegeId,
      domain: this.careerDomainName
    };

    this.collegeName = collegeName

    this.dashboardService.getAbroadCollegeDetails(postData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.isDetailAvailable = true;
        this.collegeDetails = response.data.colleges;
        $('#collegeDetailsModal').modal('show');
      }
      if (response.status === false && response.statusCode === 200) {
        this.isDetailAvailable = false;
        $('#collegeDetailsModal').modal('show');
      }
    });
  }

  paginate(event) {
    this.getCollegesListPostData.offset = (event.first);
    this.getCollegeList();
  }

  resetToFirstPageAndGetCollegeList(event) {
    this.getCollegesListPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }
    this.getCollegeList();
  }

  resetFlag() {
    this.nameAlreadyExists = false;
  }



  viewShortlistedColleges() {
    this.viewShortlistedClicked = !this.viewShortlistedClicked;
    this.paginatorRef.changePageToFirst(this.myDummyEvent);
    this.isTableDataLoading = true;
    if (this.viewShortlistedClicked === true) {
      this.dashboardService.getAbroadShortlistedCollegesList(this.getCollegesListPostData).subscribe(response => {
        if (response.status === true && response.statusCode === 200) {
          this.collegeListFromApi = response.data.colleges;
          this.collegeListFromApi.forEach(element => {
            if(!this.collegeBucket.includes(element.college_id)){
              this.collegeBucket.push(element.college_id);
            }
          });
          this.isTableDataLoading = false;
          this.noOfPages = 0;
        }
        else if (response.status === false && response.statusCode === 200) {
          this.collegeListFromApi = '';
          this.noOfPages = 0;
          this.isTableDataLoading = false;
        }
      });
    }
    else {
      this.getCollegeList();
    }
  }

  saveColleges() {

    const saveCollegesPostData = {
      domain: this.careerDomainName,
      saveCollegeIds: this.selectedColleges,
      removeCollegeIds: this.unselectedColleges
    };


    this.dashboardService.saveAbroadColleges(saveCollegesPostData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.isNotSaved = false;
        this.nothingChanged = true;
        // this.dashboardService.setRoadmapTabFlag('enableExamTab');
      }
    });

    if(this.disableSaveRoadMap){
      $('#savedRoadMapModel').modal('show');
    }
  }

  roadmapNameFormSubmit(){
    this.roadmapNameFormSubmitted = true;
    this.dashboardService.saveAbroadRoadmapName(this.roadmapNameForm.value).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        if (response.message === 'Name assigned successfully') {
          this.nameAlreadyExists = false;
          this.roadmapNameForm.reset();
          this.roadmapNameFormSubmitted = false;
          $('#saveRoadmapNameModal').modal('hide');

          // this.removeRoadmapFlagsForStorage();
          this.router.navigate(['/saved-roadmaps','abroad']);
        }
        else if (response.message === 'Roadmap name already exists') {
          this.nameAlreadyExists = true;
        }
      }
    });
    
  }

  saveRoadmap() {
    $('#saveRoadmapNameModal').modal('show');
  }

  closeSavedModal(){
    $('#savedRoadMapModel').modal('hide');
  }

}
