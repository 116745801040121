import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ScholarshipsService } from '../../services/scholarships.service';
import { Paginator } from 'primeng/paginator';
declare var $: any;

@Component({
  selector: 'app-scholarships',
  templateUrl: './scholarships.component.html',
  styleUrls: ['./scholarships.component.scss']
})
export class ScholarshipsComponent implements OnInit {

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  optionForm: FormGroup;

  isTableDataLoading = true;
  counterAPi = 0
  // Filter drop-down options
  filterOptions = {
    scholarshipType: [],
    class: [],
    gender: [],
    religion: [],
    country: []
  };

  // Filter selected values
  scholarshipType = [];
  class = [];
  gender = [];
  religion = [];
  country = [];

  // post
  postData;

  // response
  scholarshipsList;
  count;

  // Modal data
  redirectUrl;

  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private scholarshipsService: ScholarshipsService) { }

  ngOnInit() {
    this.postData = {
      length: 10,
      mode: 'OPEN',
      page: 0
    };

   
    this.getBuddyToken();

    this.optionForm = this.formBuilder.group({
      scholarshipStatus: ['']
    });

    this.optionForm.get('scholarshipStatus').valueChanges.subscribe(optedValue => {
      this.postData.mode = optedValue;
      this.setFiltersAndGetList(0);
      if (this.paginatorRef) {
        this.paginatorRef.changePageToFirst(this.myDummyEvent);
      }
    });

  }
  
  getBuddyToken() {
    this.scholarshipsService.getTokenFromBuddyStudy().subscribe({
      next: response => {
        this.scholarshipsService.getFiltersData().subscribe(filtersResponse => {
          this.setFiltersAndGetList(0);
  
          (filtersResponse.special).forEach(element => {
            this.filterOptions.scholarshipType.push({ label: element.rulevalue, value: element.id });
          });
  
          (filtersResponse.class).forEach(element => {
            this.filterOptions.class.push({ label: element.rulevalue, value: element.id });
          });
  
          (filtersResponse.gender).forEach(element => {
            this.filterOptions.gender.push({ label: element.rulevalue, value: element.id });
          });
  
          (filtersResponse.religion).forEach(element => {
            this.filterOptions.religion.push({ label: element.rulevalue, value: element.id });
          });
  
          this.filterOptions.country = [
            { label: 'National', value: 820 },
            { label: 'International', value: 698 }
          ];
        });
      },
      error: (err) => {
        const maxRetries = 20; 
        if(this.counterAPi < maxRetries) {
          this.getBuddyToken();
          this.counterAPi +=1 ;
        }
        
      }
    });
  }


  setFiltersAndResetToPageFirst(event) {
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }
    this.setFiltersAndGetList(0);
  }

  setFiltersAndGetList(pageNumber) {
    this.isTableDataLoading = true;

    const rules = [];
    this.postData.page = pageNumber;
    // this.paginator.changePage(pageNumber);

    if (this.scholarshipType.length > 0) {
      rules.push({ rule: this.scholarshipType });
    }
    if (this.class.length > 0) {
      rules.push({ rule: this.class });
    }
    if (this.gender.length > 0) {
      rules.push({ rule: this.gender });
    }
    if (this.religion.length > 0) {
      rules.push({ rule: this.religion });
    }
    if (this.country.length > 0) {
      rules.push({ rule: this.country });
    }

    if (rules.length > 0) {
      this.postData.rules = rules;
    }
    else if (rules.length === 0) {
      this.postData.rules = rules;
    }

    this.scholarshipsService.getScholarshipsList(this.postData).subscribe(response => {
      this.isTableDataLoading = false;
      if (response.retry) {
        this.setFiltersAndGetList(this.postData.page);
        this.count = 0;
      }
      else {
        this.scholarshipsList = response.scholarships;
        this.count = response.total;
      }
    },
      (error) => {
      });
  }



  clearFiltersAndGetList() {
    this.scholarshipType = [];
    this.class = [];
    this.gender = [];
    this.religion = [];
    this.country = [];

    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(this.myDummyEvent);
    }
    this.setFiltersAndGetList(0);
  }


  viewScholarshipDetails(scholarshipSlug) {
    this.redirectUrl = 'https://www.buddy4study.com/scholarship/' + scholarshipSlug + '?utm_source=third-party-partner&utm_medium=mindler&utm_campaign=scholarship-mindler';
    // this.scholarshipDetailModal.nativeElement.modal('show');
    $('#scholarshipDetailModal').modal('show');
  }
}
