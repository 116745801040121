import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { DashboardService } from "src/app/services/dashboard.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-document-archives",
  templateUrl: "./document-archives.component.html",
  styleUrls: ["./document-archives.component.scss"],
})
export class DocumentArchivesComponent implements OnInit {
  studentId;
  isLoading = true;
  message: string = "";
  isOpened = {};
  documentArchiveUrl = environment.student_photo_baseUrl + "archive_document/";

  documentObj: { [value: string]: [] } = {};
  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => {
      this.studentId = params.get("student_id");
    });

    this.getStudentDocuments();
  }

  toggle(key: string) {
    this.isOpened[key] = !this.isOpened[key];
  }

  replaceSpacesWithUnderscores(str: string): string {
    return str.replace(/\s+/g, "_");
  }

  getIconForFile(fileName: string): string {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "pi pi-file-pdf";
      case "docx":
        return "pi pi-file-word";
      case "xlsx":
        return "pi pi-file-excel";
      case "pptx":
        return "pi pi-file-powerpoint";
      case "jpg":
        return "pi pi-image";
      case "png":
        return "pi pi-image";
      case "gif":
        return "pi pi-image";
      case "rar":
        return "pi pi-file-zip";
      default:
        return "pi pi-file";
    }
  }

  // get Action Plan Data
  getStudentDocuments() {
    this.dashboardService
      .getStudentDocs(this.studentId)
      .subscribe((response) => {
        this.isLoading = false;
        if (response.statusCode === 200 && response.status === true) {
          this.documentObj = response.data;
        } else if (response.statusCode === 200 && response.status === false) {
          this.message = response.message;
        }
      });
  }
}
