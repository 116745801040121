import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import { DashboardService } from '../../services/dashboard.service';
import { SuperadminService } from '../../services/superadmin.service';
import { PARTNER_SUBSCRIPTION_STATUS } from './partner-overview.data';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
@Component({
  selector: 'app-partners-overview',
  templateUrl: './partners-overview.component.html',
  styleUrls: ['./partners-overview.component.scss']
})
export class PartnersOverviewComponent implements OnInit {

  isLoading = false;

  statsData;

  dateRangeFilterForm: FormGroup;
  todayDate: any = new Date();

  isInvalidDateRange;
  oneDay = 1000 * 60 * 60 * 24;

  isExpired;

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  // optionForm: FormGroup;

  getPartnersPostData: any = {};

  apiResponse;
  partnersList;
  partnersListForCsvDownload;
  fileName = 'Partners List';

  noOfPages;

  partnersTableSearch = new Subject<string>();
  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  //
  todayDateForCalc = new Date();

  //subscription status 
  subscriptionStatusList = PARTNER_SUBSCRIPTION_STATUS;
  constructor(private formBuilder: FormBuilder, 
    private dashboardService: DashboardService,
    private superAdminService: SuperadminService,
    private messageService: MessageService) {
    // search
    this.partnersTableSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.setFilterAndGetList();
      });
  }

  ngOnInit() {
    this.getPartnersPostData.limit = 10;
    // this.getPartnersPostData.source = 2;
    this.getPartnersPostData.status = '';
    this.getPartnersPostData.orderBy = 'desc';

    const timeZoneOffset = (this.todayDate).getTimezoneOffset() * 60000;

    const oneYearAgoDate = (new Date(this.todayDate - timeZoneOffset)).setFullYear(this.todayDate.getFullYear() - 1);

    const formattedFromDate = new Date(oneYearAgoDate).toISOString().slice(0, -1).substring(0, 10);
    const formattedToDate = (new Date(this.todayDate - timeZoneOffset)).toISOString().slice(0, -1).substring(0, 10);

    this.getPartnersPostData.from_date = formattedFromDate;
    this.getPartnersPostData.to_date = formattedToDate;

    this.getPartnersData();

    //
    const tempDate = new Date();
    this.dateRangeFilterForm = this.formBuilder.group({
      fromDate: [new Date(tempDate.setFullYear(this.todayDate.getFullYear() - 1)), Validators.required],
      toDate: [new Date(), Validators.required]
    });

    //
    // this.optionForm = this.formBuilder.group({
    //   source: ['']
    // });

    // this.optionForm.get('source').valueChanges.subscribe(optedValue => {
    //   this.getPartnersPostData.source = optedValue;
    //   this.setFilterAndGetList();
    // });
  }

  // getter
  get fromDate() { return this.dateRangeFilterForm.get('fromDate'); }
  get toDate() { return this.dateRangeFilterForm.get('toDate'); }


  //
  setFilterAndGetList() {
    this.getPartnersPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(this.myDummyEvent);
    }
    this.getPartnersData();
  }


  getPartnersData() {
    this.superAdminService.getPartnersOverviewData(this.getPartnersPostData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.apiResponse = response.data;

        this.statsData = [
          { label: 'Partners Onboard', count: this.apiResponse.on_board_partners },
          { label: 'Started Subscription', count: this.apiResponse.started_subscription },
          { label: 'Monthly Revenue', count: ((this.apiResponse.monthly_revenue)/1000).toFixed(2) + 'k' },
          { label: 'Avg. Lifetime Value', count: (this.apiResponse.avg_liftime_value/1000).toFixed(2) + 'k' },
          { label: 'Churn Rate', count: this.apiResponse.churn_rate }
        ];

        if (this.getPartnersPostData.download == '1') {
          this.getPartnersPostData.download = '';
          this.partnersListForCsvDownload = response.data.partnerList;
          this.dashboardService.exportToCsvService(this.fileName, this.partnersListForCsvDownload);
        }
        else {
          this.partnersList = response.data.partnerList;
          this.noOfPages = response.data.count;
        }
      }
      else if (response.status === false && response.statusCode === 200) {
        this.partnersList = null;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
      }
    });
  }


  dateRangeFilterFormSubmit() {

    if (this.dateRangeFilterForm.invalid) {
      return;
    }

    const timeZoneOffset = (this.fromDate.value).getTimezoneOffset() * 60000;

    const formattedFromDate = (new Date(this.fromDate.value - timeZoneOffset)).toISOString().slice(0, -1).substring(0, 10);
    const formattedToDate = (new Date(this.toDate.value - timeZoneOffset)).toISOString().slice(0, -1).substring(0, 10);

    const dateDifference = this.getDateDifferenceByDay(formattedToDate, formattedFromDate);

    if (dateDifference >= 0) {
      this.isInvalidDateRange = false;
      this.getPartnersPostData.from_date = formattedFromDate;
      this.getPartnersPostData.to_date = formattedToDate;
      this.getPartnersData();
    }
    else {
      this.isInvalidDateRange = true;
    }
  }

  getPartnerStatus(partner) {
    // const expiryDate = new Date(partner.expiry_date);
    
    const expiryDate = moment(partner.expiry_date, "DD-MM-YYYY");
    // const diff = Math.round((new Date().getTime()/this.oneDay - expiryDate.getTime()/this.oneDay));
    const diff  = moment().diff(expiryDate, 'days');
    if (diff > 0) {
      this.isExpired = true;
    }
    else {
      this.isExpired = false;
    }

    if (this.isExpired) {
      return 'red-text';
    }
    return 'green-text';
  }


  getDateDifferenceByDay(formattedToDate, formattedFromDate) {
    const differenceMs = new Date(formattedToDate).getTime() - new Date(formattedFromDate).getTime();

    return Math.round(differenceMs / this.oneDay);
  }


  // Download CSV
  downloadCsv() {
    this.getPartnersPostData.download = '1';
    this.getPartnersData();
  }

  // Paginate
  paginate(event) {
    this.getPartnersPostData.offset = (event.first);
    this.getPartnersData();
  }

}
