import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  apiBaseUrl;
  env;

  constructor() {
    this.setUrl();
    // console.log('network service cons');
  }

  setUrl() {
    this.env = this.setENV();
    this.apiBaseUrl = environment[this.env];
  }

  setENV() {
    if (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('192.') !== -1) {
      return 'local';
    } else if (
      window.location.hostname.indexOf('pstaging') !== -1 ||
      window.location.hostname.indexOf('partneradmin') !== -1 ||
      window.location.hostname.indexOf('preproduction') !== -1
    ) {
      return 'staging';
    } else {
      return 'prod';
    }
  }

  getBaseUrls() {
    return this.apiBaseUrl;
  }
}
