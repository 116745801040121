import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { SuperadminService } from 'src/app/services/superadmin.service';

@Component({
  selector: 'app-admin-zoho-subscription',
  templateUrl: './admin-zoho-subscription.component.html',
  styleUrls: ['./admin-zoho-subscription.component.scss']
})
export class AdminZohoSubscriptionComponent implements OnInit {
  @Input() public partnerId;
  myForm: FormGroup;
  isLoading = true;
  plans: any[] | undefined;
  minDate: Date | undefined;
  constructor(private fb: FormBuilder,private superAdminService: SuperadminService,private messageService: MessageService) { }

  ngOnInit() {
    this.createForm();
    this.minStartDate();
    this.plans = [
      { name: 'Renewal', id: 1 },
      { name: 'Extention', id: 2 }
    ];
  }

  createForm() {
    this.myForm = this.fb.group({
      plan_type: ['', Validators.required],
      amount: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required]
    });
  }

  minStartDate() {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month === 0) ? 11 : month - 0;
    let prevYear = (prevMonth === 11) ? year - 1 : year;
    let nextMonth = (month === 11) ? 0 : month + 1;
    let nextYear = (nextMonth === 0) ? year + 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
  }

  onSubmit(form: FormGroup) {
    let reqObj:any = {
      "partner_id": this.partnerId,
      "session": localStorage.getItem('pp_session'),
      "plan_type": form.value.plan_type.id,
      "amount": form.value.amount,
      "start_date": moment(form.value.start_date).format('YYYY-M-D'),
      "end_date": moment(form.value.end_date).format('YYYY-M-D')
    }
    this.superAdminService.postZohoSubscriptionAutomation(reqObj).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        this.messageService.add({
          key: 'topright',
          severity: 'success',
          detail: response.message,
        });
      } else if (response.status == false && response.statusCode == 200) {
        this.messageService.add({
          key: 'topright',
          severity: 'warn',
          detail: response.message,
        });
      }
      this.myForm.reset();
    })
  }

  get plan_type() {
    return this.myForm.get("plan_type");
  }

  get amount() {
    return this.myForm.get("amount");
  }
  get start_date() {
    return this.myForm.get("start_date");
  }
  get end_date() {
    return this.myForm.get("end_date");
  }
}



