import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  isLoading = false;
  resourceList;
  loggedInUserDetails;
  count;
  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.dashboardService.decodeToken();
    console.log(this.loggedInUserDetails.partner_id)
    this.getResourceList({partner_id:this.loggedInUserDetails.partner_id});
  }


  getResourceList(partner_id) {
    this.isLoading = true;

    this.dashboardService.getResources(partner_id).subscribe(response => {
      this.isLoading = false;
      console.log(response);
      if (response["data"]) {
        this.resourceList = response.data;
      }
      else {
        this.resourceList = [];
      }
    });
  }

}
