import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloginService } from '../../../app/services/prelogin.service';
import { Subscription } from 'rxjs'
import jwt_decode from 'jwt-decode';

@Component({
	selector: 'app-login-with-mindler-token',
	templateUrl: './login-with-mindler-token.component.html',
	styleUrls: ['./login-with-mindler-token.component.scss']
})
export class LoginWithMindlerTokenComponent implements OnInit {


	public routeParams: { token: string, isLogout: string } | undefined;

	private _verifyAuthJWTSubscription$: Subscription | undefined;


	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _preLoginService: PreloginService
	) { }

	ngOnInit(): void {
		this._getToken();
	}

	private _getToken(): void {
		this.routeParams = this._activatedRoute.snapshot.params as { token: string, isLogout: string };
		this._verifyAuthJWT();
	}

	private _verifyAuthJWT() {
		if (this.routeParams) {
			if (parseInt(this.routeParams.isLogout)) {
				this._preLoginService.logOut();
			}
			else {
				this._verifyAuthJWTSubscription$ = this._preLoginService.loginWithToken({ "token": this.routeParams.token })
					.subscribe(data => {
						if (data.statusCode == 200 && data.status == false) {
							this._router.navigate(['/login']);
						}
						else if (data.statusCode == 404 && data.status == false) {
							this._router.navigate(['/login']);
						}
						else if (data.statusCode == 500 && data.status == false) {
							this._router.navigate(['/login']);
						}
						else if (data.statusCode == 200 && data.status == true) {
							const tokenData = jwt_decode(this.routeParams.token);
							this._router.navigate([tokenData.url]);
						}
					});
			}
		}
	}

	ngOnDestroy() {
		this._verifyAuthJWTSubscription$ && this._verifyAuthJWTSubscription$.unsubscribe();
	}

}
