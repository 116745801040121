import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreloginService } from '../../services/prelogin.service';
import { Router } from '@angular/router';
import { ValidationsService } from '../../services/common/validations.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private validationService: ValidationsService, private preLoginService: PreloginService) { }

  isForgotPasswordFormSubmitted = false;

  logoUrl ="https://mindler-products-images.imgix.net/confluence/general-icons/edsoul.png";

  forgotPasswordForm: FormGroup;

  userNotExists = false;
  isPasswordLinkSent = false;

  ngOnInit() {
    // redirect already logged in user to dashboard
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/my-dashboard']);
    }

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, this.validationService.validateEmail]]
    });
  }

  // getters
  get email() { return this.forgotPasswordForm.get('email'); }

  // Form submit
  forgotPasswordFormSubmit() {
    this.resetFlags();

    this.isForgotPasswordFormSubmitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.preLoginService.forgotPassword(this.forgotPasswordForm.value).subscribe(response => {
      if (response.statusCode === 200 && response.status === false) {
        this.userNotExists = response.message;
      }
      else if (response.statusCode === 200 && response.status === true) {
        this.isPasswordLinkSent = true;
        this.forgotPasswordForm.reset();
        this.isForgotPasswordFormSubmitted = false;
      }
    });
  }

  resetFlags() {
    this.userNotExists = false;
  }

}
