import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, Event, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import jwt_encode from 'jwt-encode';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { DashboardService } from '../../services/dashboard.service';
import { PreloginService } from "../../services/prelogin.service";

@Component({
	selector: 'app-student-profile',
	templateUrl: './student-profile.component.html',
	styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {
	VipStudentList;
	isVipAllowed: boolean;
	isFeaturesAccess;
	isTestAccess;
	isMatchesAccess;
	apiResponse;

	studentId;

	profileActiveMenu;

	studentProfileMenuItems;      // mobile
	mobileNavigationMenuItemName; // mobile

	profilePhotoUrl;

	loggedInUserDetails;

	tokenObject;
	currentUserId;

	public isShowProfileBuilderTab = false;

	private _profileImage = "";
	private _userRole; // 0 - Partner, 1 - Admin, 2 - Counsellor, 3 - Super-admin

	private _subscriptions$ = new SubSink();

	constructor(
		private location: Location,
		private dashboardService: DashboardService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _preLoginService: PreloginService,
	) {

		this.router.events.subscribe((event: Event) => {
			// active menu
			if ((router.url).includes('student-profile')) {
				this.setActiveTab(router);
			}

			// also
			if (event instanceof NavigationStart) {
				if ((event.url).includes('student-profile')) {
					this.setActiveTab(router);
				}
			}
		});


		//
		this.activatedRoute.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

		if (isNaN(this.studentId)) {
			this.router.navigate(['/student-list']);
		}
	}

	ngOnInit() {

		this.loggedInUserDetails = this.dashboardService.decodeToken();
		this._userRole = this._preLoginService.getCurrentRole();

		// 0 - Partner, 1 - Admin, 2 - Counsellor, 3 - Super-admin
		if (this._userRole === 0) {
			this.currentUserId = this.loggedInUserDetails.partner_id;
		} else if (this._userRole === 1) {
			this.currentUserId = this.loggedInUserDetails.counselor_id;
		} else if (this._userRole === 2) {
			this.currentUserId = this.loggedInUserDetails.counselor_id;
		} else if (this._userRole === 3) {
			this.currentUserId = this.loggedInUserDetails.admin_id;
		}


		// mobile
		this.studentProfileMenuItems = [
			{ label: 'Info', value: 'info', isExternalRoute: false },
			{ label: 'Tests', value: 'tests', isExternalRoute: false },
			{ label: 'Matches', value: 'matches', isExternalRoute: false },
			{ label: 'Notes', value: 'notes', isExternalRoute: false },
			{ label: 'Action Plan', value: 'action-plan', isExternalRoute: false },
			{ label: 'Roadmap', value: 'student-roadmap', isExternalRoute: false }
		];

		this._subscriptions$.add(
			this.dashboardService.getWhiteLabellingOptions().subscribe((response) => {
				if (response.status === true && response.statusCode === 200) {
					if (response.data.options.show_profile_builder) {
						this.isShowProfileBuilderTab = true;
						this.studentProfileMenuItems.push({ label: 'Profile Builder', value: 'profile-builder', isExternalRoute: true })
					} else {
						this.isShowProfileBuilderTab = false;
					}
				}
			})
		)

		this.dashboardService.studentInfo({ student_id: this.studentId }).subscribe(response => {
			if (response.status === false && response.statusCode === 200) {
				this.router.navigate(['/student-list']);
			}
			else if (response.status === true && response.statusCode === 200) {
				this.apiResponse = response.data;
				this.isFeaturesAccess = response.data.features;
				this.isTestAccess = this.isFeaturesAccess.Assessment;
				if (this.apiResponse.basicinfo.profile_pic != null) {
					this.profilePhotoUrl = environment.student_photo_baseUrl + this.apiResponse.basicinfo.uid + '/' + this.apiResponse.basicinfo.profile_pic;
				}

				// this.router.navigate(['info'], { relativeTo: this.activatedRoute });
			}
		});
		this.getVipStudentListData();


	}

	getVipStudentListData() {
		this.dashboardService.getStudentVip({ student_id: this.studentId }).subscribe((response) => {
			if (response.status === true && response.statusCode === 200) {
				this.VipStudentList = response.data
			}
		})
	}

	// mobile
	mobileMenuNavigateRoute(routeValue) {
		if (routeValue == 'profile-builder') {
			this.redirectProfileBuilder();
			return;
		}
		this.router.navigate([routeValue], { relativeTo: this.activatedRoute });
	}


	goBack() {
		this.router.navigate(['/student-list']);
	}

	setActiveTab(router) {
		const lastIndex = (router.url).lastIndexOf('/');
		if ((router.url).indexOf('student-roadmap') !== -1) {
			this.profileActiveMenu = 'student-roadmap';
		}
		else {
			this.profileActiveMenu = (router.url).substring(lastIndex + 1);
		}
		this.mobileNavigationMenuItemName = this.profileActiveMenu;
	}

	redirectProfileBuilder() {
		console.log("redirectProfileBuilder called", this.apiResponse);
		this.dashboardService.getStudentLoginDetails({ email: this.apiResponse.basicinfo.email }).subscribe((loginData) => {
			console.log("studentLoginDetails fetched", loginData);
			const { data } = loginData;
			if (data && data.length) {
				this.dashboardService.getProfileInfo()
					.subscribe((partnerData) => {
						if (partnerData && partnerData.data) {

							let school = '-';
							let className = '-';

							if (this.apiResponse && this.apiResponse.profileinfo) {
								school = this.apiResponse.profileinfo.school;
								className = this.apiResponse.profileinfo.class
							}
							this.tokenObject = {
								partner_name: partnerData.data.userInfo[0].name,
								partner_profile_pic: this._partnerProfileImage(),
								profile_pic: this.apiResponse.basicinfo.profile_pic ? this.apiResponse.basicinfo.profile_pic : ``,
								school: school,
								class: className,
								phone: this.apiResponse.basicinfo.phone,
								name: this.apiResponse.basicinfo.name,
								email: this.apiResponse.basicinfo.email,
								auth_id: loginData.data[0].auth_id,
								uid: this.apiResponse.basicinfo.uid,
								accesscode: "",
								assessment_type: this._getAssessmentType(),
								email_p: partnerData.data.userInfo[0].email,
								redirection_url: window.location.origin + "/partner",
								partner_url:this.apiResponse.basicinfo.domain,
								white_labelling: partnerData.data.userInfo[0].white_labelling,
								image: environment.logo_baseUrl + this.loggedInUserDetails.partner_id + '/' + partnerData.data.userInfo[0].image + '?w=350&h=75&fit=crop&crop=faces'
							}
							const token = jwt_encode(this.tokenObject, 'mindler-profile-builder');
							console.log("dfs", token)
							console.log("tokenObject", this.tokenObject);
							const url = `${this.dashboardService.getPProfileBuilderUrl()}/applicationStatus/`+ token;
							window.open(url, "_self");
						}
					})
			}
		})
	}

	private _partnerProfileImage() {
		if (this.loggedInUserDetails.profile_image != null) {
			if (this._userRole === 0) {
				this._profileImage =
					environment.partner_photo_baseUrl +
					this.currentUserId +
					"/" +
					this.loggedInUserDetails.profile_image;
			} else if (this._userRole === 1 || this._userRole === 2) {
				this._profileImage =
					environment.counsellor_photo_baseUrl +
					this.currentUserId +
					"/" +
					this.loggedInUserDetails.profile_image;
			}
		}
		return this._profileImage;
	}

	private _getAssessmentType(): string {
		const { basicinfo } = this.apiResponse;
		if (basicinfo.package_id == 1) {
			return 'Stream Assessment';
		} else if (basicinfo.package_id == 2 && basicinfo.staged_at == 2) {
			return 'Career Assessment';
		} else if (basicinfo.package_id == 2 && basicinfo.staged_at == 3) {
			return 'College Assessment';
		}
		return '';
	}

	ngOnDestroy() {
		this._subscriptions$ && this._subscriptions$.unsubscribe();
	}
}