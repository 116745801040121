import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { Router, ActivatedRoute, NavigationStart, Event, ParamMap } from '@angular/router';

@Component({
  selector: 'app-student-roadmap',
  templateUrl: './student-roadmap.component.html',
  styleUrls: ['./student-roadmap.component.scss']
})
export class StudentRoadmapComponent implements OnInit {

  activeTabName = 'preferences';
  isDisabledTab = false;

  studentId;
  isRoadMapAssigned=false;
  isIndia;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dashboardService: DashboardService) { }

  ngOnInit() {
    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));
    localStorage.setItem('student_id', this.studentId);

    this.dashboardService.getStudentRoadmapId({ student_id: this.studentId }).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.isRoadMapAssigned = true;
        localStorage.setItem('roadmap_id', response.data.roadmapid);
        this.isIndia = true;
        this.router.navigate(['preferences'], { relativeTo: this.activatedRoute });
      }
    });

    this.dashboardService.getStudentAbroadRoadmapId({ student_id: this.studentId }).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.isRoadMapAssigned = true;
        this.isIndia = false;
        localStorage.setItem('roadmap_id', response.data.roadmapid);
        this.router.navigate(['abroad/preferences'],{relativeTo: this.activatedRoute});
      }
      // else if (response.statusCode === 200 && response.status === false) {
      //   if(this.isRoadMapAssigned){

      //   }
      // }
    });
  }

  //Navigating to India or Abroad Preferences tab when user clicks on TAB
  navigateToPreferences(){
    if(this.isIndia){
      this.router.navigate(['preferences'], { relativeTo: this.activatedRoute });
    }
    else{
      this.router.navigate(['abroad/preferences'], { relativeTo: this.activatedRoute });
    }
  }

  navigateToCollegeMap(){
    if(this.isIndia){
      this.router.navigate(['college-map'],{relativeTo: this.activatedRoute});
    }
    else{
      this.router.navigate(['abroad/college-map'],{relativeTo: this.activatedRoute});
    }
  }
}
