import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-exam-corner-details',
  templateUrl: './exam-corner-details.component.html',
  styleUrls: ['./exam-corner-details.component.scss']
})
export class ExamCornerDetailsComponent implements OnInit {

  isLoading;

  mepId;
  id;

  examDetailApiResponse;
  isDataAvailable;

  examName;
  streamName;
  summary;
  eligibilityDetails;
  importantDates;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.mepId = params.get('mep_id'),
        this.id = params.get('id');
    });

    if (isNaN(this.mepId) || isNaN(this.id)) {
      this.router.navigate(['/exam-corner']);
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.dashboardService.getExamDetails({ mep_id: this.mepId, id: this.id }).subscribe(response => {
      this.isLoading = false;

      if (response.status == true && response.statusCode == 200) {
        this.isDataAvailable = true;

        this.examName = response.data.exam_detailedInfo[0].short_form;
        this.streamName = response.data.exam_detailedInfo[0].stream_name;

        this.summary = response.data.exam_detailedInfo[0].summary;
        this.eligibilityDetails = response.data.exam_detailedInfo[0].eligibility_details;
        this.importantDates = response.data.exam_details;
      }
      if (response.status == false && response.statusCode == 200) {
        this.isDataAvailable = false;
      }
    });
  }
}
