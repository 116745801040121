import { Component, OnInit, NgZone } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { WindowRefService } from '../../services/window-ref.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

import * as workerTimers from 'worker-timers';
// import { DomSanitizer } from '@angular/platform-browser';
import jwt_decode from 'jwt-decode';
declare var $: any;
enum ZOHO_SUBSCRIPTION_STATUS {
	INITIATED = 1,
	PROCESSING,
	COMPLETED
}
@Component({
	selector: 'app-upgrade',
	templateUrl: './upgrade.component.html',
	styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {

	isLoading = true;

	loggedInUserDetails;

	isTypeOne = false;

	recurring_type = 12; // monthly = 1;annually = 2;

	groupName = '';
	isGroupRow;

	isMobile;
	isViewAllEnabled = true;

	apiResponse;



	// Type-2 Specific Flags
	activePlanName;
	upgradeTypeTwoButtonClicked = false;
	planDataToSend;
	modalRefToSend;
	flagToTriggerChangeEvent = 0;
	zohoSubscriptionStatus: number = 1;
	isManualPlanLockMessage: string = "You have enrolled for manual subscription plan.<br>Please contact Mindler representative for billing information.<br> Or just write to us at partnerteam@mindler.com";
	subscriptionInitiated = false;

	//
	invoiceUrl = "";
	popupWindow: any
	paymentMsgInfo1: string;
	paymentMsgInfo2: string;
	paymentMsgMail: string;
	clickedPlanData: any;
	showPaymentConfirm = false;
	typeTwoPlanBtnDisabled: boolean = false;
	constructor(private zone: NgZone,
		private dashboardService: DashboardService,
		private windowRef: WindowRefService) {
		// subscribe to subscription change request flag
		this.dashboardService.getPlanChangeRequestFlag().subscribe(data => {
			// console.log('get flag', data);
			this.apiResponse.plan_change_or_cancellation_request = data;
		});
	}

	ngOnInit() {
		this.dashboardService.planBtnDisabledStatus.subscribe((btnDisabled) => {
			this.typeTwoPlanBtnDisabled = btnDisabled;
		})
		this.loggedInUserDetails = this.dashboardService.decodeToken();

		if (window.innerWidth <= 576) {
			this.isMobile = true;
		}
		else {
			this.isMobile = false;
		}

		this.getSubscriptionData();

	}

	// get Data
	getSubscriptionData() {
		if (this.subscriptionInitiated) {
			return;
		}
		this.isLoading = true;

		const postData = {
			recurring_type: this.recurring_type
		};


		this.dashboardService.getSubscriptionDetails(postData).subscribe(response => {
			this.isLoading = false;

			this.apiResponse = response.data;



			if (response.statusCode == 200 && response.status == true) {
				this.zone.run(() => {
					this.isTypeOne = (response.data.subscription_status == 1) ? false : true;

					// Show Type-1 screen if user's plan is activated manually (Active subscription BUT Manual source)
					if (!this.isTypeOne && response.data.source == 2) {
						this.isTypeOne = true;
					}
				});

				if (this.isTypeOne == false) {
					(response.data.plans).forEach(element => {
						if (element.activePlan == true) {
							this.zone.run(() => {
								this.activePlanName = element.plans;
							});
						}
					});
				}

				console.log(this.apiResponse);


				if (this.isTypeOne == true) {
					if (window.innerWidth <= 576) {

						const plans = response.data.plans;
						const amenities = response.data.amenities;

						for (let i = 0; i < plans.length; i++) {


							plans[i].feature = {};
							let temp;
							for (let j = 0; j < amenities.length; j++) {
								// amenities.forEach(function (amenity) {

								// plan.amenities.group = [];

								!plans[i].feature.hasOwnProperty(amenities[j].group) ? plans[i].feature[amenities[j].group] = [] : '';

								if (plans[i].plan_type === 1) {
									// console.log(plan);
									if (amenities[j].agency !== null && plans[i].feature.hasOwnProperty(amenities[j].group)) {
										// console.log(amenity.amenities)
										plans[i].feature[amenities[j].group].push(amenities[j].amenities);

									}

								} else if (plans[i].plan_type === 2) {

									if (amenities[j].professional !== null && plans[i].feature.hasOwnProperty(amenities[j].group)) {
										plans[i].feature[amenities[j].group].push(amenities[j].amenities);
									}

								} else if (plans[i].plan_type === 3) {
									if (amenities[j].starter !== null && plans[i].feature.hasOwnProperty(amenities[j].group)) {
										plans[i].feature[amenities[j].group].push(amenities[j].amenities);
									}
								}

							}

						}
						// console.log(plans);
						this.apiResponse = plans;
					}
				}
			}
		});
	}


	// populate the lower table data, implemented for the group-title row (coloured row)
	populateTable(group) {
		if (group != this.groupName) {
			this.isGroupRow = true;
			this.groupName = group;
		}
		else {
			this.isGroupRow = false;
		}
	}


	// Type -1 Mobile : 'Show Features' link visibility
	setShowFeaturesVisibility(event) {
		document.getElementById(event.id).classList.toggle('d-none');
	}

	upgradeTypeOneSubscribeAction(planData) {
		if (this.subscriptionInitiated) {
			return;
		}
		if (this.intervalId) {
			workerTimers.clearInterval(this.intervalId);
			this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.INITIATED
		}
		let PLAN_TYPE = {
			1: 'Agency Plan',
			2: 'Professional Plan',
			3: 'Starter Plan'
		}
		this.paymentMsgInfo1 = `By clicking on the continue button, you will get redirected to the payment page for the ${PLAN_TYPE[planData.plan_type]} where you can make the payment.`
		this.paymentMsgInfo2 = `For any upgrade support or queries, please feel free to contact us at `
		this.paymentMsgMail = `partner@mindler.com`
		this.clickedPlanData = planData
		this.showPaymentConfirm = true;
		$("#planActionModalUpgrade").modal('show')
	}

	closePlanActionModalUpgrage() {
		$("#planActionModalUpgrade").modal('hide');
		this.clickedPlanData = undefined
		this.showPaymentConfirm = false;
		this.dashboardService.managePlanBtnDisabled(false);
	}

	onPaymentConfirmation(planData) {
		this.upgradationProcessingPlan(planData);
	}

	upgradationProcessingPlan(planData) {
		const createSubscriptionPostData = {
			plan: planData.plan_type,
			type: this.recurring_type,
			status: planData.recurring_type == null ? 'trial' : null,
			subscription_status: planData.recurring_type == null ? null : 0
		}
		this.subscriptionInitiated = true;
		this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.INITIATED
		this.dashboardService.initiateSubscription(createSubscriptionPostData).subscribe(response => {
			this.isLoading = false;
			if (response.statusCode == 200 && response.status == true) {
				if (response.data && response.data.invoice_url) {
					// this.invoiceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(response.data.invoice_url + '&origin=' + 'https://zohosecurepay.com/')
					var left = ($(window).width() / 8), top = ($(window).height() / 8);
					this.popupWindow = window.open(response.data.invoice_url, '_blank')// "Mindler Subscription", `width=${$(window).width() - $(window).width() / 5}, height=${$(window).height() - $(window).height() / 4}, top=${top}, left=${left},toolbar=0,location=0, directories=0, status=0, menubar=0'`);;
					this.monitoringZohoSubscription();
				}
			}
		});

	}

	// Type-2 specific
	setViewAllFlag() {
		this.isViewAllEnabled = !this.isViewAllEnabled;
	}


	upgradeTypeTwoAction(data, modalRef) {
		this.dashboardService.managePlanBtnDisabled(true);
		if (this.apiResponse.plan_change_or_cancellation_request) {
			return;
		}
		if (data.action == 'Downgrade' || data.action == 'Cancel Renewal') {
			this.upgradeTypeTwoButtonClicked = true;
			this.flagToTriggerChangeEvent = this.flagToTriggerChangeEvent + 1;
			this.planDataToSend = data;
			this.modalRefToSend = modalRef;
		} else if (data.action == 'Upgrade') {
			this.upgradeTypeOneSubscribeAction(data);
		}

	}
	intervalId: any;
	monitoringZohoSubscription() {
		this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.PROCESSING;
		this.intervalId = workerTimers.setInterval(() => {
			// do something many times
			this.gettingZohoPayementStatus();
			if (ZOHO_SUBSCRIPTION_STATUS.COMPLETED == this.zohoSubscriptionStatus) {
				workerTimers.clearInterval(this.intervalId);
			}
		}, 3000);
	}

	gettingZohoPayementStatus() {
		if (this.zohoSubscriptionStatus == ZOHO_SUBSCRIPTION_STATUS.PROCESSING) {
			this.dashboardService.refreshToken().subscribe(response => {
				if (response.statusCode == 200 && response.status == true) {
					const decodedToken: any = (jwt_decode(response.token))
					const subscriptionStatus = decodedToken.subscription_status;
					//updatingToken
					if (this.clickedPlanData.plan == decodedToken.active_plan) {
						localStorage.setItem('pp_token', response.token);
						if (subscriptionStatus) {
							this.dashboardService.setFirstTimePlanPurchaseFlag();
							this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.COMPLETED;
							let updateSubscriptionData = {
								status: subscriptionStatus,
								subscription_status: subscriptionStatus
							}
							this.dashboardService.updateSubscription(updateSubscriptionData).subscribe(response => { })
							this.subscriptionInitiated = false
							this.getSubscriptionData();
							this.popupWindow.close();
							this.dashboardService.managePlanBtnDisabled(false);
						}
					}
				}
			});
		}
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.intervalId) {
			workerTimers.clearInterval(this.intervalId);
			this.popupWindow.close();
		}
	}

	get isManualPlan() {
		if (!this.apiResponse) {
			return true
		}
		let plans = this.apiResponse.plans.filter((plan) => plan.activePlan === true) || [];
		if (plans.length > 0) {
			return false;
		}
		return true;
	}

	isNumber(n) {
		return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
	}

	isCheck(n) {
		let string = '<i class="pi pi-check-circle prime-check-circle-icon"></i>';
		return n.length > 0 ? 1 : '';
		//return n.length>0?string.includes(n):'';
		//return /^-?[\d.]+(?:e-?\d+)?$/.test(n); 
	}

	// get isStarterActivePlan() {
	// 	return this.apiResponse.plans.length ? this.apiResponse.plans[2]['activePlan'] : false
	// }
}
