import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	FormControl,
} from "@angular/forms";
import { DashboardService } from "../../services/dashboard.service";
import { environment } from "../../../environments/environment";
declare var $: any;

@Component({
	selector: "app-information",
	templateUrl: "./information.component.html",
	styleUrls: ["./information.component.scss"],
})
export class InformationComponent implements OnInit {
	isLoading = true;
	services;
	requestFor;
	srvce;
	team_members;
	defaultSvg: string = "";
	serviceList = [];
	default_heading = "Discover Your Perfect Career";
	default_sub_heading =
		"Take the world’s most-advanced career assessment and find your best-fit career.";
	default_featured_photo = "https://mindlerimages.imgix.net/home/p-testi4.png";
	is_default_featured_photo = false;
	isImagefile;
	informationForm: FormGroup;
	serviceUpdateForm: FormGroup;
	upiPaymentDetailsForm: FormGroup;
	isServiceFormSubmitted: boolean;
	isInformationFormSubmitted: boolean;
	checkArray = [];
	isLargeImage = false;
	ImageUploadError = [];
	isLargeIcon = false;
	isImageUploaded = true;
	uploadedImageName = [];
	filetype: boolean;
	loggedInUserDetails;
	displayImageUrl=[];
	serviceFormTitle = "Add New Service";
	getPartnerDetailsPostData = {
		section: "102",
	};
	img_type;
	svgString: String;
	box: string = "";

	saveBtnLabel = "Save Changes";
	isBtnDisabled = true;
	isServiceBtnDisabled = false;
	editingService = false;

	uploadedQrImage = "";
	selectedCurrency: string;
	currency: any[] = [
		{ name: 'Select', code: '' },
		{ name: 'INR', code: '₹' },
		{ name: 'USD', code: '$' },
		{ name: 'IDR', code: 'Rp' },
		{name:'AED ',code:'د.إ'},
		{name:'QAR ',code:'﷼'}

	];
	submitted = false;


	@ViewChild("fileInput", { static: false })
	myFileInput: ElementRef;

	constructor(
		private formBuilder: FormBuilder,
		private dashboardService: DashboardService,
	) {

	}

	ngOnInit() {
		this.informationForm = this.formBuilder.group({
			type: ["saveData"],
			heading: [this.default_heading, Validators.required],
			sub_heading: [this.default_sub_heading, Validators.required],
			about_us: ["", Validators.maxLength(2000)],
			featured_photo: [null],
			profile_evaluation: [false],
			counselling_session: [false],
			overseas_package: [false],
			india_package: [false],
			psychometric_assessment: [false],
			college_recommendation: [false],
			counselling_stream_assessment: [false],
			upi_id: ['', []],
			qr_code: ['', []],
			whatsapp_number: ['', []],
			team_members: [""]
		});


		this.serviceUpdateForm = this.formBuilder.group({
			title: ["", [Validators.required, Validators.maxLength(30)]],
			description: ["", [Validators.required, Validators.maxLength(256)]],
			currency_symbol: [""],
			price: [""],
			image_file: ["", Validators.required],
			svgString: [""],
			id: [""],
			reset: [""],
			image_type: [""]
		});

		this.informationForm.addControl("services", new FormControl(""));

		this.getPartnerDatas();

		this.loggedInUserDetails = this.dashboardService.decodeToken();

		//
		this.informationForm.valueChanges.subscribe((val) => {
			this.saveBtnLabel = "Save Changes";
			this.isBtnDisabled = false;
		});
	}

	updateAmountValidation() {
		let curVal = this.price.value;
		if (curVal) {
			this.price.setValidators([Validators.required]);
			this.currencySymbol.setValidators([Validators.required]);
			this.currencySymbol.markAsDirty();
			this.price.markAsDirty();
		} else {
			if (this.currencySymbol.value && this.currencySymbol.value.code) {
				this.price.setValidators([Validators.required]);
				this.currencySymbol.setValidators([Validators.required]);
				this.currencySymbol.markAsDirty();
				this.price.markAsDirty();
			} else {
				this.currencySymbol.setValue(null);
				this.price.clearValidators();
				this.currencySymbol.clearValidators();
			}
		}
		this.currencySymbol.updateValueAndValidity();
		this.price.updateValueAndValidity();


	}
	updateCurrencyValidation() {
		let curVal = (this.currencySymbol.value && this.currencySymbol.value.code) || '';
		if (curVal) {
			this.price.setValidators([Validators.required]);
			this.currencySymbol.setValidators([Validators.required]);
			this.currencySymbol.markAsDirty();
			this.price.markAsDirty();
		} else {
			this.currencySymbol.setValue(null);
			if (this.price.value) {
				this.price.setValidators([Validators.required]);
				this.currencySymbol.setValidators([Validators.required]);
				this.currencySymbol.markAsDirty();
				this.price.markAsDirty();
			} else {
				this.price.clearValidators();
				this.currencySymbol.clearValidators();
			}
		}

		this.currencySymbol.updateValueAndValidity();
		this.price.updateValueAndValidity();

	}

	getPartnerDatas() {
		this.checkArray = [];
		// this.isLoading = true;
		this.dashboardService
			.getPartnerData(this.getPartnerDetailsPostData)
			.subscribe((response) => {
				this.isLoading = false;
				let result = response.data.information;
				let partnerData = response.data.partnerData[0];
				//let srvces = response.data.captured_services;
				this.srvce = response.data.services;
				this.team_members = response.data.team_members;

				this.srvce.forEach((e) => {
					if (e.svgString == e.defaultIcon) {
						e.img_type = "svg";
					}
					let selCurrencyObjArr = this.currency.filter((data) => data.code == e.currency_symbol);
					let currency = '';
					if (selCurrencyObjArr.length) {
						currency = selCurrencyObjArr[0].code;
					}
					this.checkArray.push({
						id: e.id,
						title: e.title,
						description: e.description,
						price: e.price,
						currency_symbol: currency,
						status: e.status,
						svgString: e.svgString,
						defaultIcon: e.defaultIcon,
						img_type: e.img_type,
					});
					//this.serviceList.push(e);
				});

				// console.log(this.checkArray);

				/*if(srvces.length>0){
				  (srvces).forEach(element => { 
					const objIndex = this.checkArray.findIndex((obj => obj.id == element.service_id));  
					if(objIndex!=-1){
						 this.checkArray[objIndex].status = element.status;
					   }
				  });
				}*/

				this.services = this.checkArray;


				if (response.hasOwnProperty("data")) {
					this.box = result.about_us;
					this.informationForm.patchValue(
						{
							heading: result.heading,
							sub_heading: result.sub_heading,
							about_us: result.about_us,
							featured_photo: JSON.parse(result.featured_photo),
							profile_evaluation: result.profile_evaluation,
							counselling_session: result.counselling_session,
							overseas_package: result.overseas_package,
							india_package: result.india_package,
							psychometric_assessment: result.psychometric_assessment,
							college_recommendation: result.college_recommendation,
							counselling_stream_assessment:
								result.counselling_stream_assessment,
							upi_id: partnerData.upi_id ? partnerData.upi_id : "",
							qr_code: partnerData.qr_code ? partnerData.qr_code : "",
							whatsapp_number: partnerData.whatsapp_number ? partnerData.whatsapp_number : ""
						},
						{ emitEvent: false }
					);
					if (this.informationForm.get("qr_code").value) {
						this.uploadedQrImage = environment.featured_photo_baseUrl +
							this.loggedInUserDetails.partner_id +
							"/" +
							partnerData.qr_code;
					}
				}
				if (this.informationForm.get("featured_photo").value != null) {
					this.displayImageUrl =
						this.informationForm.get("featured_photo").value.map(name => {
							return (
								environment.featured_photo_baseUrl +
								this.loggedInUserDetails.partner_id +
								"/" +
								name
							);
						}) || [];



					// Dynamic validation
					const about_us = this.informationForm.get("about_us");
					about_us.setValidators(Validators.required);
					//about_us.setValidators(Validators.maxLength(2000));
					about_us.updateValueAndValidity({ emitEvent: false });
				} else {
					this.displayImageUrl = [this.default_featured_photo];
					this.is_default_featured_photo = true;
				}
			}, (error) => {
				this.isLoading = false;
			});
	}

	onCheckboxChange(e) {
		if (e.target.checked) {
			const objIndex = this.checkArray.findIndex(
				(obj) => obj.id == e.target.value
			);
			this.checkArray[objIndex].status = true;
		} else {
			const objIndex = this.checkArray.findIndex(
				(obj) => obj.id == e.target.value
			);
			this.checkArray[objIndex].status = false;
		}

		this.isBtnDisabled = false;
	}

	// getters
	get heading() {
		return this.informationForm.get("heading");
	}
	get sub_heading() {
		return this.informationForm.get("sub_heading");
	}
	get about_us() {
		return this.informationForm.get("about_us");
	}
	get featured_photo() {
		return this.informationForm.get("featured_photo");
	}

	get title() {
		return this.serviceUpdateForm.get("title");
	}
	get description() {
		return this.serviceUpdateForm.get("description");
	}
	get price() {
		return this.serviceUpdateForm.get("price")
	}
	get currencySymbol() {
		return this.serviceUpdateForm.get("currency_symbol")
	}
	get image_file() {
		return this.serviceUpdateForm.get("image_file");
	}
	get svg_string() {
		return this.serviceUpdateForm.get("svgString");
	}
	get upi_id() {
		return this.informationForm.get("upi_id");
	}
	get qr_code() {
		return this.informationForm.get("qr_code");
	}
	uploadImages(event) {

		// Dynamic validation
		const about_us = this.informationForm.get("about_us");

		if (event.target.files.length >= 1) {
			about_us.setValidators(Validators.required);
			//about_us.setValidators(Validators.maxLength(2000));
		} else {
			about_us.clearValidators();
		}

		about_us.updateValueAndValidity();

		const uploadData = new FormData();

		const files = event.target.files;
		for (let i = 0; i < files.length; i++) {

			if (files[i].size > 500000) {
				this.ImageUploadError.push(files[i].name);
			} else {
				const file = files[i];
				const fileName = file.name;
				const name = fileName.replace(/ /g, "-");
				if (
					file.type != "image/jpeg" &&
					file.type != "image/png"
				) {
					this.isLargeImage = true;
					return;
				}
				this.isLargeImage = false;

				uploadData.append('featured_photo', file, name);
			}
		}

		uploadData.append("type", "imageUpload");


		this.dashboardService
			.uploadInformationImage(uploadData)
			.subscribe((data) => {
				this.uploadedImageName = data.featured_photos;

				let featuredPhoto = this.informationForm.get('featured_photo').value || [];


				if (this.displayImageUrl && this.displayImageUrl.length > 0) {

					this.uploadedImageName.map(name => {
						this.displayImageUrl.push(environment.featured_photo_baseUrl +
							this.loggedInUserDetails.partner_id +
							"/" +
							name);

						featuredPhoto.push(name);
					});

				} else {
					featuredPhoto = [];
					this.displayImageUrl = this.uploadedImageName.map(name => {
						featuredPhoto.push(name);
						return (
							environment.featured_photo_baseUrl +
							this.loggedInUserDetails.partner_id +
							"/" +
							name
						);
					}
					) || [];
				}
				this.informationForm.get('featured_photo').setValue(featuredPhoto);
				this.saveBtnLabel = "Save Changes";	

			});

		if (this.ImageUploadError.length > 0) {
			alert("The following images are too large to upload: " + this.ImageUploadError.join(", "));
			this.ImageUploadError = [];
		}

	}

	uploadQRImage(event) {
		if (event.target.files[0].size > 500000) {
			this.isLargeImage = true;
		} else {
			// Dynamic validation
			const about_us = this.informationForm.get("about_us");

			if (event.target.files.length >= 1) {
				about_us.setValidators(Validators.required);
				//about_us.setValidators(Validators.maxLength(2000));
			} else {
				about_us.clearValidators();
			}
			about_us.updateValueAndValidity();
			const fileName = event.target.files[0].name;
			const newFileName = fileName.replace(/ /g, "-");
			if (
				event.target.files[0].type != "image/jpeg" &&
				event.target.files[0].type != "image/png"
			) {
				this.isLargeImage = true;
				return;
			}
			this.isLargeImage = false;

			const uploadData = new FormData();
			uploadData.append("featured_photo", event.target.files[0], newFileName);
			uploadData.append("type", "imageUpload");

			this.dashboardService
				.uploadInformationImage(uploadData)
				.subscribe((data) => {
					this.uploadedImageName = data.featured_photos;
					this.uploadedQrImage =
						environment.featured_photo_baseUrl +
						this.loggedInUserDetails.partner_id +
						"/" +
						this.uploadedImageName;
					this.informationForm.patchValue({
						qr_code: this.uploadedImageName,
					});
					this.saveBtnLabel = "Save Changes";
				});
		}
	}

	informationFormSubmit() {
		this.isInformationFormSubmitted = true;
		if (this.informationForm.invalid) {
			this.informationForm.markAllAsTouched();
			return;
		}

		this.saveBtnLabel = "Saving...";

		this.informationForm.patchValue({
			services: this.checkArray,
		});
		let team = this.getTeamMemberArr();
		this.informationForm.get("team_members").setValue(team);
		//// console.log(this.informationForm.value); return;

		this.dashboardService
			.setInformationDetails(this.informationForm.value)
			.subscribe((data) => {
				if (data.statusCode == 500 && data.status == false) {
					this.saveBtnLabel = "Save Changes";
				} else {
					this.is_default_featured_photo = false;
					this.saveBtnLabel = "Saved";
					setTimeout(() => {
						this.saveBtnLabel = "Save Changes";
						this.isBtnDisabled = true;
					}, 3000);
				}
			});
	}

	uploadIcon(event) {
		this.isLargeIcon = false;
		// console.log("YO");
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			if (file.type != "image/jpeg" && file.type != "image/png") {
				this.filetype = true;
				return;
			}
			//Checking the size of the file
			if (event.target.files[0].size > 500000) {
				this.isLargeIcon = true;
				return;
			}
			this.serviceUpdateForm.get("image_file").setValue(file);
			// console.log("Checking");
			this.serviceUpdateForm.patchValue({
				image_file: file,
			});
			this.isImagefile = file;
			//this.isServiceBtnDisabled = false;
		}
	}

	resetToDefault() {
		this.serviceUpdateForm.patchValue({
			svgString: this.defaultSvg,
			image_type: "svg",
			reset: 1,
		});
		this.svgString = this.defaultSvg;
		this.img_type = "svg";
		this.isImagefile = false;
		this.filetype = false;
		this.saveBtnLabel = 'Save Changes';
	}

	addService() {
		this.isServiceFormSubmitted = false;
		this.editingService = false;
		this.myFileInput.nativeElement.value = "";
		this.serviceUpdateForm.reset();
		this.serviceUpdateForm.patchValue({
			id: ""
		});
		this.isImagefile = false;
		this.filetype = false;
		this.requestFor = "add";
		this.saveBtnLabel = "Save";
		this.isServiceBtnDisabled = false;

	}



	updateService(event) {
		this.editingService = true;
		let currency = event.currency_symbol;
		let currencyObj = null;
		if (currency) {
			currencyObj = this.currency.filter((data) => data.code === currency);
		}
		this.serviceUpdateForm.patchValue({
			title: event.title,
			description: event.description,
			price: event.price,
			id: event.id,
			image_file: event.image_file,
			currency_symbol: currencyObj ? currencyObj[0] : null
		});
		if (event.img_type == 'svg') {
			this.serviceUpdateForm.patchValue({
				img_type: 'svg',
				svgString: event.svgString
			})
			this.isImagefile = false;
		}
		this.svgString = event.svgString;
		this.filetype = false;
		this.serviceFormTitle = "Edit Service";
		this.defaultSvg = event.defaultIcon;
		if (this.defaultSvg != null || event.svgString != null) {
			this.image_file.clearValidators();
			this.image_file.updateValueAndValidity();
		}
		this.img_type = event.img_type;
		this.requestFor = "update";
		this.isServiceBtnDisabled = false;
		this.saveBtnLabel = 'Save';
	}

	serviceFormSubmit() {
		this.serviceUpdateForm.markAllAsTouched();
		this.title.setValue((this.serviceUpdateForm.value.title || '').trim());
		this.description.setValue((this.serviceUpdateForm.value.description || '').trim());
		const id = this.serviceUpdateForm.get("id").value;
		this.updateAmountValidation();
		this.updateCurrencyValidation();
		// if(!this.currencySymbol.value.code && this.price.value) {
		// 	this.currencySymbol.markAsTouched();
		// 	this.currencySymbol.setValue(null);
		// 	return;
		// }
		this.isServiceFormSubmitted = true;
		if (this.serviceUpdateForm.invalid || (!this.isImagefile && !id)) {
			return;
		}

		let formValues;

		if (this.isImagefile) {
			const formData = new FormData();
			formData.append("id", this.serviceUpdateForm.get("id").value);
			formData.append("image", this.serviceUpdateForm.get("image_file").value);
			formData.append("title", this.serviceUpdateForm.get("title").value);
			formData.append(
				"description",
				this.serviceUpdateForm.get("description").value
			);
			let price = this.serviceUpdateForm.get("price").value;
			if (price !== 0 && (price == undefined || price == null || price == "")) {
				price = '';
			}
			let currencySymbolVal = this.serviceUpdateForm.get("currency_symbol").value;
			formData.append("currency_symbol", currencySymbolVal ? currencySymbolVal.code : '');
			formData.append("price", price);
			formValues = formData;
		} else {
			formValues = this.serviceUpdateForm.value;
			formValues.currency_symbol = formValues.currency_symbol ? formValues.currency_symbol.code : '';
		}

		this.isServiceBtnDisabled = true;
		this.saveBtnLabel = "Saving...";
		// console.log(formValues);
		this.dashboardService.addUpdateServices(formValues).subscribe((data) => {
			if (data.statusCode == 500 && data.status == false) {
			} else {
				if (this.editingService) {
					const objIndex = this.checkArray.findIndex(
						(obj) => obj.id == data.data.id
					);
					this.checkArray[objIndex].title = data.data.title;
					this.checkArray[objIndex].description = data.data.description;
					this.checkArray[objIndex].price = data.data.price;
					this.checkArray[objIndex].img_type = data.data.img_type;
					this.checkArray[objIndex].svgString = data.data.svgString;
				} else {
					this.checkArray.push(data.data);
				}

				// console.log(this.checkArray);
				$("#s_e_popup").modal("hide");
				this.saveBtnLabel = "Saved";
				setTimeout(() => {
					this.saveBtnLabel = "Save";
				}, 3000);
			}
			this.getPartnerDatas();
		});
	}

	removeFeaturedPhoto(index: string) {
		const removedImageUrl = this.displayImageUrl.splice(+index, 1)[0]; // Remove imageUrl from array

		let featuredPhoto = this.informationForm.get('featured_photo').value;
		featuredPhoto.splice(index, 1);
		this.informationForm.get('featured_photo').setValue(featuredPhoto);

		this.dashboardService
			.removeFeaturedPhoto({ type: "removeFeaturedPhoto", imageUrl: removedImageUrl })
			.subscribe((data) => {
				if (data.statusCode === 500 && data.status === false) {
				} else {
					this.displayImageUrl = [this.default_featured_photo];
					this.is_default_featured_photo = true;
				}
			});
	}

	/*****************Form Add team member********** */
	onTeamSaveText = '';
	addNewTeamLabel = '';
	displayTeamModal: boolean;
	addTeamMemberForm: FormGroup = this.cretaFormTeam()
	showTeamModalDialog() {
		this.activeImgSrc = "https://mindlerpartner.imgix.net/images/team-member.svg";
		this.addTeamMemberForm.reset();
		this.addTeamMemberForm.patchValue({
			active: "1",
			isNewFileUploaded: '0'
		})
		this.displayTeamModal = true;
		this.addNewTeamLabel = "Add New Team Member";
		this.onTeamSaveText = "Save";
	}

	activeImgSrc = "https://mindlerpartner.imgix.net/images/team-member.svg";

	onTeamMemberEditIconClick(data) {
		this.addTeamMemberForm.reset();
		this.addNewTeamLabel = "Edit New Team Member";
		this.activeImgSrc = data.image || "https://mindlerpartner.imgix.net/images/team-member.svg";
		this.addTeamMemberForm.patchValue({
			name: data.name,
			team_member_id: data.team_member_id,
			description: data.description,
			designation: data.designation,
			image: data.image,
			linkedin_url: data.linkedin_url,
			active: data.active,
			isNewFileUploaded: data.image ? 0 : 0
		})
		this.displayTeamModal = true;
		this.onTeamSaveText = "Update";

	}

	cretaFormTeam() {
		let Constants = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
		return this.formBuilder.group({
			name: ["", [Validators.required, Validators.maxLength(30)]],
			description: ["", [Validators.required, Validators.maxLength(256)]],
			designation: ["", [Validators.required]],
			image: [""],
			linkedin_url: ["", [Validators.pattern(Constants)]],
			team_member_id: [""],
			isNewFileUploaded: ["0", [Validators.required]],
			active: ["1", [Validators.required]]

		});

	}

	onsubmitTeamForm() {
		this.addTeamMemberForm.markAllAsTouched();
		if (this.addTeamMemberForm.invalid) {
			return
		}
		let formValue = this.addTeamMemberForm.getRawValue();
		let formData = new FormData();
		if (formValue.isNewFileUploaded) {

			formData.append('name', formValue.name);
			formData.append('description', formValue.description);
			formData.append('designation', formValue.designation);
			formData.append('linkedin_url', formValue.linkedin_url ? formValue.linkedin_url : '');
			formData.append('team_member_id', formValue.team_member_id ? formValue.team_member_id : "");
			formData.append('image', formValue.image)
			formData.append('isNewFileUploaded', formValue.isNewFileUploaded);
			formData.append('active', formValue.active || "1");
			this.onTeamSaveText = "Saving";

		} else {
			formData.append('name', formValue.name);
			formData.append('description', formValue.description);
			formData.append('designation', formValue.designation);
			formData.append('linkedin_url', formValue.linkedin_url);
			formData.append('team_member_id', formValue.team_member_id ? formValue.team_member_id : "");
			formData.append('isNewFileUploaded', "0");
			formData.append('image', this.activeImgSrc);
			formData.append('active', formValue.active || "1")
			this.onTeamSaveText = "Updating";
		}

		this.dashboardService.getNewTeamMember(formData).subscribe((data) => {
			this.displayTeamModal = false;
			this.getPartnerDatas();
		})

	}

	getTeamMemberArr() {
		if (!this.team_members || this.team_members.length == 0) {
			return [];
		}
		return this.team_members.map((data) => {
			return {
				"team_member_id": data.team_member_id,
				"active": data.active
			}
		})
	}

	onActiveCheckBoxClick(event, index) {
		let checked = event.target.checked ? 1 : 0;
		this.team_members[index]["active"] = checked;
		this.isBtnDisabled = false;
	}


	uploadProfilePhoto(event) {
		const file = event.target.files[0];
		this.getTeamMemberControl('image').setValue(file);
		this.getTeamMemberControl('isNewFileUploaded').setValue(1);
	}

	selectFile() {
		document.getElementById('fileInputBelow').click();
	}

	getTeamMemberControl(controlName) {
		return this.addTeamMemberForm.get(controlName);
	}

	/**************************************************** ***********/

	get whatsapp_number() {
		return this.informationForm.get("whatsapp_number");
	}
}