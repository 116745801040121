import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
@Component({
  selector: 'app-college-map',
  templateUrl: './college-map.component.html',
  styleUrls: ['./college-map.component.scss']
})
export class CollegeMapComponent implements OnInit {

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  isLoading = false;
  isDataLoading = false;

  target_career;
  backup_career;
  backup_career_2;

  activeCareerTab; // target_career, backup_career, backup_career_2

  //Variable to store current Active Career (0-> target_career, 1->backup_career, 2-> backup_career_2)
  currentActiveCareer = 0;

  careerDomainName;
  filterOptionsArray =[];

  // Filter drop-down options
  filterOptions = {
    states: [],
    cities: [],
    courses: [],
    duration: [],
    feeRange: [],
    collegeType: []
  };
  isFilterDataAvailable;

  // Filter selected values
  state=[[],[],[]]; 
  city =[[],[],[]]; 
  course=[[],[],[]]; 
  duration=[[],[],[]];  // collegeDuration
  fees=[[],[],[]];  // selectedFees
  type=[[],[],[]]; // selectedTypeOfCollege


  // College List Table
  collegeListApiResponse;
  collegeListFromApi;

  isTableDataLoading = true;  // Important (if false initially, then we get 'length of undefined' error)

  // Arrays
  collegeBucket = [];
  selectedColleges = [];
  unselectedColleges = [];
  viewShortlistedCollegesList = [];
  viewShortlistedClicked;

  initialShortlistedColleges = [];

  limit;  // show Records
  search;

  collegeSearch = new Subject<string>();

  getCollegesListPostData: any = {};

  // Pagination
  noOfPages;

  disabledSaveBtn;
  isNotSaved = true;

  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) {
    // Search
    this.collegeSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.search = value;
        this.getCollegesListPostData.search = value;
        this.resetToFirstPageAndGetCollegeList(this.myDummyEvent);
      });
  }

  ngOnInit() {
    this.limit = 10;
    this.activeCareerTab = 'target_career';

    this.target_career = localStorage.getItem('target_career');
    this.backup_career = localStorage.getItem('backup_career');
    this.backup_career_2 = localStorage.getItem('backup_career_2');

    this.careerDomainName = this.target_career;

    // Fee Range (Filter)
    this.filterOptions.feeRange = [
      { label: '0 - 2 L', value: '0-200000' },
      { label: '2 - 5 L', value: '200000-500000' },
      { label: '5 - 10 L', value: '500000-1000000' },
      { label: '10 - 15 L', value: '1000000-1500000' },
      { label: '15 + L', value: '1500000-5000000' }
    ];

    // Type of College (Filter)
    this.filterOptions.collegeType = [
      { label: 'College', value: 'college' },
      { label: 'University', value: 'university' },
      { label: 'Evening College', value: 'evening_college' },
      { label: 'Distance Education', value: 'Distance-Educationt' }
    ];


    this.getFiltersAndListByDomain(this.activeCareerTab);
  }


  getFiltersAndListByDomain(activeCareerTab) {
    this.isDataLoading = true;

    if (activeCareerTab === 'target_career') {
      this.activeCareerTab = 'target_career';
    } else if (activeCareerTab === 'backup_career') {
      this.activeCareerTab = 'backup_career';
    } else if (activeCareerTab === 'backup_career_2') {
      this.activeCareerTab = 'backup_career_2';
    }

    this.getCollegesListPostData.limit = this.limit = 10;
    this.getCollegesListPostData.search = this.search = '';
    this.getCollegesListPostData.state = [];
    this.getCollegesListPostData.city = [];
    this.getCollegesListPostData.course = [];
    this.getCollegesListPostData.duration = [];
    this.getCollegesListPostData.fees = [];
    this.getCollegesListPostData.type = [];

    this.selectedColleges = [];
    this.unselectedColleges = [];
    this.collegeBucket = [];

    this.viewShortlistedClicked = false;

    this.getCollegesListPostData.offset = 0;

    this.getCollegeList();

    this.setCareerAndGetData();
  }

  // Get filter options data
  setCareerAndGetData() {
    const getFiltersPostData: any = {
      domain: this.careerDomainName
    };

    if (this.state[this.currentActiveCareer].length > 0) {
      getFiltersPostData.state = this.state[this.currentActiveCareer];
    }
    else if (getFiltersPostData.hasOwnProperty('state')) {
      delete getFiltersPostData.state;
    }

    this.dashboardService.getRoadmapCollegeMapFilters(getFiltersPostData).subscribe(response => {
      this.isDataLoading = false;

      this.filterOptions.cities = [];
      this.filterOptions.courses = [];
      this.filterOptions.duration = [];

      if (this.state[this.currentActiveCareer].length === 0) {
        this.filterOptions.states = [];
      }

      if (response.statusCode === 200 && response.status === true) {
        // populate states only for first time
        if (this.state[this.currentActiveCareer].length === 0) {
          (response.data.states).forEach(element => {
            if(element.state!=null && element.state.trim().length!=0){
              this.filterOptions.states.push({ label: element.state, value: element.state });
            }
          });
        }

        (response.data.city).forEach(element => {
          if(element.city!=null && element.city.trim().length!=0){
            this.filterOptions.cities.push({ label: element.city, value: element.city });
          }
        });

        (response.data.courses).forEach(element => {
          this.filterOptions.courses.push({ label: element.short_head_display, value: element.short_head_display });
        });

        if (response.data.stream_details.mindler_career === 1) {
          this.isFilterDataAvailable = false;
        }
        else {
          this.isFilterDataAvailable = true;
          (response.data.years).forEach(element => {
            this.filterOptions.duration.push({ label: element.duration_year + ' year', value: element.duration_year });
          });
        }
      }
    });
  }

  // update city filter options based on selected state
  getCityByState(event) {
    this.setCareerAndGetData();
  }

  clearFilters() {
    this.state[this.currentActiveCareer] = [];
    this.city[this.currentActiveCareer] = [];
    this.course[this.currentActiveCareer] = [];
    this.duration[this.currentActiveCareer] = [];
    this.fees[this.currentActiveCareer] = [];
    this.type[this.currentActiveCareer] = [];

    this.resetToFirstPageAndGetCollegeList(this.myDummyEvent);
  }


  // Colleges List Table

  getCollegeList() {
    if(this.viewShortlistedClicked===true){
      return;
    }
    this.getCollegesListPostData.domain = this.careerDomainName;
    this.getCollegesListPostData.limit = this.limit;

    this.getCollegesListPostData.state = this.state[this.currentActiveCareer];
    this.getCollegesListPostData.city = this.city[this.currentActiveCareer];
    this.getCollegesListPostData.course = this.course[this.currentActiveCareer];
    this.getCollegesListPostData.duration = this.duration[this.currentActiveCareer];
    this.getCollegesListPostData.fees = this.fees[this.currentActiveCareer];
    this.getCollegesListPostData.type = this.type[this.currentActiveCareer];

    this.isTableDataLoading = true;

    this.dashboardService.getCollegesList(this.getCollegesListPostData).subscribe(response => {
      this.isTableDataLoading = false;
      // console.log('college list - ', response);
      if (response.status === true && response.statusCode === 200) {
        this.collegeListApiResponse = response.data;
        this.collegeListFromApi = response.data.colleges;
        this.noOfPages = response.data.count;
        (response.data.colleges).forEach(element => {
          if (element.college_shortlisted != null && this.collegeBucket.indexOf(element.college_id) === -1 && this.unselectedColleges.indexOf(element.college_id) === -1) {
            this.collegeBucket.push(element.college_id);
            this.selectedColleges.push(element.college_id);
            this.initialShortlistedColleges.push(element.college_id);
          }
        });
      }
    });
  }


  addToBucket(collegeId, checkedStatus) {
    if (checkedStatus === false) {
      this.selectedColleges.splice(this.selectedColleges.indexOf(collegeId), 1);
      if (this.unselectedColleges.indexOf(collegeId) === -1 && this.initialShortlistedColleges.indexOf(collegeId) !== -1) {
        this.unselectedColleges.push(collegeId);
      }
      // UI
      this.collegeBucket.splice(this.collegeBucket.indexOf(collegeId), 1);
    }
    else {
      if (this.unselectedColleges.indexOf(collegeId) !== -1) {
        this.unselectedColleges.splice(this.unselectedColleges.indexOf(collegeId), 1);
      }
      this.selectedColleges.push(collegeId);
      // UI
      this.collegeBucket.push(collegeId);
    }
  }


  saveColleges() {
    if (this.collegeBucket.length <= 0) {
      return;
    }

    const saveCollegesPostData = {
      domain: this.careerDomainName,
      saveCollegeIds: this.selectedColleges,
      removeCollegeIds: this.unselectedColleges
    };

    this.dashboardService.saveColleges(saveCollegesPostData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.isNotSaved = false;
        this.dashboardService.setRoadmapTabFlag('enableExamTab');
      }
    });
  }


  viewShortlistedColleges() {
    this.viewShortlistedClicked = !this.viewShortlistedClicked;
    this.paginatorRef.changePageToFirst(this.myDummyEvent);
    if (this.viewShortlistedClicked === true) {
      this.dashboardService.getShortlistedCollegesList(this.getCollegesListPostData).subscribe(response => {
        if (response.status === true && response.statusCode === 200) {
          this.collegeListFromApi = response.data.colleges;
          this.noOfPages = 0;
        }
        else if (response.status === false && response.statusCode === 200) {
          this.collegeListFromApi = '';
          this.noOfPages = 0;
        }
      });
    }
    else {
      this.getCollegeList();
    }
  }


  proceedToNext() {
    if (this.collegeBucket.length <= 0) {
      return;
    }
    this.router.navigate(['../exam-shortlist'], { relativeTo: this.activatedRoute });
  }


  paginate(event) {
    this.getCollegesListPostData.offset = (event.first);
    this.getCollegeList();
  }

  resetToFirstPageAndGetCollegeList(event) {
    this.getCollegesListPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }
    if (this.viewShortlistedClicked === true) {
      this.viewShortlistedClicked = !this.viewShortlistedClicked;
      this.getCollegesListPostData.limit = this.limit;
      this.viewShortlistedColleges();
    }
    else {
      this.getCollegeList();
    }
  }

  changeCurrentActiveState(stateToChange : number){
    this.currentActiveCareer = stateToChange;
  }

}
