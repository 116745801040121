import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { DashboardService } from '../../services/dashboard.service';
import { PreloginService } from '../../services/prelogin.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	isLoading = false;
	loggedInUserDetails;

	profileForm: FormGroup;
	isProfileFormSubmitted: boolean;

	saveBtnLabel = 'Save Changes';
	isBtnDisabled = true;

	emailId;
	userId;

	currentUserRole;
	previousPhoneNumber = "";

	//
	isLargeImage = false;
	isImageUploaded = true;
	uploadedImageName = '';
	displayImageUrl = '';
	uploadValidImage = false;

	//
	userRole;
	organizationTypeOptions = []


	constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService, private preLoginService: PreloginService) { }

	ngOnInit() {
		this.loggedInUserDetails = this.dashboardService.decodeToken();

		this.userRole = this.preLoginService.getCurrentRole();

		if (this.userRole === 0) {
			this.userId = this.loggedInUserDetails.partner_id;
		}
		else if (this.userRole === 1 || this.userRole === 2) {
			this.userId = this.loggedInUserDetails.counselor_id;
		}

		this.profileForm = this.formBuilder.group({
			name: ['', [Validators.required, Validators.pattern('[A-Za-z0-9 ]+'), Validators.maxLength(60)]],
			phone: ['', Validators.required],
			// email_id: ['', this.validationService.validateEmail],
			profile_image: [''],
			current_city: ['', [Validators.required]],
			designation: ['', [Validators.required]],
			organization_type: ['', [Validators.required]],
		});

		this.getProfileInfo();

		//
		this.profileForm.valueChanges.subscribe(val => {
			this.saveBtnLabel = 'Save Changes';
			this.isBtnDisabled = false;
		});

		this.organizationTypeOptions = [
			{ name: 'Career Coach' },
			{ name: 'Counselling Organization' },
			{ name: 'Study Abroad Consultant' },
			{ name: 'Coaching Centre' },
			{ name: 'Training Institute' },
			{ name: 'School' },
			{ name: 'Others' }
		]
	}

	// getters
	get name() { return this.profileForm.get('name'); }
	get phone() { return this.profileForm.get('phone'); }
	// get email_id() { return this.profileForm.get('email_id'); }
	get profile_image() { return this.profileForm.get('profile_image'); }
	get current_city() { return this.profileForm.get('current_city'); }
	get designation() { return this.profileForm.get('designation'); }
	get organization_type() { return this.profileForm.get('organization_type'); }


	getProfileInfo() {
		this.isLoading = true;

		this.dashboardService.getProfileInfo().subscribe(response => {
			this.isLoading = false;

			if (response.statusCode === 200 && response.status === true) {
				this.emailId = response.data.userInfo[0].email;

				if ((response.data.userInfo[0]).hasOwnProperty('phone')) {
					this.profileForm.patchValue({
						phone: response.data.userInfo[0].phone
					}, { emitEvent: false, onlySelf: true });
					this.previousPhoneNumber = response.data.userInfo[0].phone;
				}
				else if ((response.data.userInfo[0]).hasOwnProperty('mobile')) {
					this.profileForm.patchValue({
						phone: response.data.userInfo[0].mobile
					}, { emitEvent: false });
					this.previousPhoneNumber = response.data.userInfo[0].mobile
				}

				this.profileForm.patchValue({
					name: response.data.userInfo[0].name,
					// email_id: response.data.userInfo[0].email
				}, { emitEvent: false });
				this.profileForm.patchValue({
					current_city: response.data.userInfo[0].current_city,
					// email_id: response.data.userInfo[0].email
				}, { emitEvent: false });
				this.profileForm.patchValue({
					designation: response.data.userInfo[0].designation,
					// email_id: response.data.userInfo[0].email
				}, { emitEvent: false });

				if (response && response.data && response.data.userInfo && response.data.userInfo[0] && response.data.userInfo[0].organization_type) {
					this.organization_type.patchValue({
						name: response.data.userInfo[0].organization_type,
					}, { emitEvent: false });
				}


				this.onOrganizationTypeChange()

				if ((response.data.userInfo[0]).hasOwnProperty('profile_image') && response.data.userInfo[0].profile_image != null) {
					if (this.userRole === 0) {
						this.displayImageUrl = environment.partner_photo_baseUrl + this.userId + '/' + response.data.userInfo[0].profile_image;
					}
					else if (this.userRole === 1 || this.userRole === 2) {
						this.displayImageUrl = environment.counsellor_photo_baseUrl + this.userId + '/' + response.data.userInfo[0].profile_image;
					}

					this.profileForm.patchValue({
						profile_image: response.data.userInfo[0].profile_image
					}, { emitEvent: false });
				}

				if (this.userRole === 0 || this.userRole === 1) {
					this.currentUserRole = 'Admin';
				}
				else if (this.userRole === 2) {
					this.currentUserRole = 'Counsellor';
				}
			}
		});
	}


	//
	uploadImage(event) {
		this.uploadValidImage = false;
		if (event.target.files[0].size > 500000) {
			this.isLargeImage = true;
		}
		else {
			this.isLargeImage = false;

			const fileName = event.target.files[0].name;
			var fileNameparts = fileName.split(".");
			if (fileNameparts[fileNameparts.length - 1] != "png" && fileNameparts[fileNameparts.length - 1] != "jpeg") {
				this.isLargeImage = true;
				this.uploadValidImage = true;
				return;
			}
			const newFileName = fileName.replace(/ /g, '-');

			const uploadData = new FormData();
			uploadData.append('image', event.target.files[0], newFileName);

			this.dashboardService.uploadProfileImage(uploadData).subscribe(data => {
				this.uploadedImageName = data.name;

				if (this.userRole === 0) {
					this.displayImageUrl = environment.partner_photo_baseUrl + this.userId + '/' + this.uploadedImageName;
				}
				else if (this.userRole === 1 || this.userRole === 2) {
					this.displayImageUrl = environment.counsellor_photo_baseUrl + this.userId + '/' + this.uploadedImageName;
				}

				this.profileForm.patchValue({
					profile_image: this.uploadedImageName
				});

				this.saveBtnLabel = 'Save Changes';
			});
		}
	}

	onOrganizationTypeChange() {
		const { value: { name: organizationTypeValue } } = this.organization_type;
		if (organizationTypeValue === 'Others') {
			this.designation.setValidators([Validators.required]);
		} else {
			this.designation.clearValidators();
			this.designation.setValue('');
		}
		this.designation.updateValueAndValidity();
	}


	profileFormSubmit() {
		this.isProfileFormSubmitted = true;

		//Removing leading and trailing spaces from 'Name Field'
		this.profileForm.patchValue({
			name: this.name.value.trim(),
		});

		if (this.profileForm.invalid) {
			return;
		}

		this.saveBtnLabel = 'Saving...';
		const { value: { name: organizationTypeValue } } = this.organization_type;
		this.dashboardService.updateProfileInfo({ ...this.profileForm.value, organization_type: organizationTypeValue, }).subscribe(response => {
			this.isProfileFormSubmitted = false;
			this.saveBtnLabel = 'Saved';
			setTimeout(() => {
				this.saveBtnLabel = 'Save Changes';
				this.isBtnDisabled = true;
			}, 3000);

			// update token
			localStorage.setItem('pp_token', response.data.token);
			this.dashboardService.setProfileImageFlag({ imageUrl: this.displayImageUrl, name: this.name.value });


			this.getProfileInfo();
		},
			error => {
				this.saveBtnLabel = 'Save Changes';
				this.isBtnDisabled = true;
				this.getProfileInfo();
			});
	}


	//
	// telInputObject(obj) {
	//   console.log(obj);
	//   obj.setCountry('in');
	// }

	// Telephone Input
	getNumber(fullNumber) {
		console.log(this.previousPhoneNumber + " " + fullNumber);
		if (fullNumber != this.previousPhoneNumber) {
			this.profileForm.patchValue({
				phone: fullNumber
			});
		}
	}

	// hasError(event: any): void {
	// 	if (!event && this.phone.value !== '') {
	// 		this.phone.setErrors({ invalidPhoneNumber: true });
	// 	}
	// }

	hasError(event: any): void {
		const phoneNumberValue = this.profileForm.get('phone').value;
		if (phoneNumberValue) {
			if (phoneNumberValue.length > 14) {
				this.profileForm.get('phone').setErrors({ invalidPhoneNumber: true });
			} else {
				// Clear the error if the number is within the valid range.
				this.profileForm.get('phone').setErrors(null);
			}
		}
	}

}
