import { Component, Input, OnInit } from '@angular/core';
import { SuperadminService } from 'src/app/services/superadmin.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { ValidationsService } from 'src/app/services/common/validations.service';
@Component({
  selector: 'app-admin-virtual-internships',
  templateUrl: './admin-virtual-internships.component.html',
  styleUrls: ['./admin-virtual-internships.component.scss']
})
export class AdminVirtualInternshipsComponent implements OnInit {
  vipTablelist = [];
  @Input() public partnerId;
  vipCount;
  hasStatusOneProduct = false;
  serviceTwoResponse = [];
  errorMsg;
  addVipBtn = "Add VIPs";
  totalRecords;
  first: number = 0;
  rows: number = 10;
  page = 1;
  serialNumber = 1;
  messageOnInput;


  constructor(private superAdminService: SuperadminService, private dashboardService: DashboardService,
    private messageService: MessageService, private validationService: ValidationsService) { }

  ngOnInit() {
    this.fetchPartnerVipPayments();
    this.getAllFeaturesAndServices();
    this.superAdminService.changesOccur.subscribe((data)=>{
      this.getAllFeaturesAndServices()
  })
  }
  onPageChange(event) {
    this.serialNumber = event.first + 1;
    this.first = event.first;
    // this.rows = event.rows;
    this.page = event.page + 1;
    this.fetchPartnerVipPayments()
  }
  fetchPartnerVipPayments() {
    let reqObj = {
      "partner_id": this.partnerId,
      "limit": this.rows,
      "page_no": this.page
    }
    this.superAdminService.getpartnerVipPayments(reqObj).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        const formattedData = response.data.vipPayments.map(item => ({
          ...item,
          purchased_on: moment(item.purchased_on).format('DD MMM YYYY')
        }));

        this.vipTablelist = formattedData;
        this.totalRecords = response.data.totalVip
      } else if (response.status == false && response.statusCode == 200) {
        this.totalRecords = 0;
      }
    })
  }
  getAllFeaturesAndServices() {
    this.serviceTwoResponse = [];
    let reqObj = {
      "partner_id": this.partnerId
    };
    this.superAdminService.getallServicesAndFeatures(reqObj).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        this.serviceTwoResponse = response.data.servicesArray2;
      }
      // if (this.serviceTwoResponse.length) {
      //   function hasStatusOne(data: any[]): boolean {
      //     return data.some(item => item.status === 1);
      //   }
      //   this.hasStatusOneProduct = hasStatusOne(this.serviceTwoResponse);
      // }
      const hasVirtualInternship = this.serviceTwoResponse.some(item => item.product_name === "Virtual Internship" && item.status === 1);
      this.hasStatusOneProduct = hasVirtualInternship;
    })
  }
  getbtnDisabled() {
    if (!this.hasStatusOneProduct) {
      return true
    } else {
      return false
    }
  }

  downloadCsv() {
    const csvData = this.convertToCSV(this.vipTablelist);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'vip_payments_list.csv');
  }

  convertToCSV(data: any[]) {
    const header = 'S.no.,Purchased On,Quantity,Status,Used On';
    const rows = data.map((item, index) => {
      const serialNumber = index + 1;
      const rowData = [
        serialNumber,
        item.purchased_on,
        item.quantity,
        item.status,
        '-'
      ];
      return rowData.join(',');
    });
    return header + '\n' + rows.join('\n');
  }


  addVipCounts() {
    this.addVipBtn = "Adding VIPs";
    let reqObj = {
      "partner_id": this.partnerId,
      "count": this.vipCount,
      "plan_id": 1,
    }
    this.superAdminService.addVipCount(reqObj).subscribe(response => {
      if (response.status) {
        console.log("addvipMessage", response.message)
        this.vipCount = ""
        this.fetchPartnerVipPayments();
        this.messageService.add({
          key: 'topright',
          severity: 'success',
          detail: response.message,
        });
        this.addVipBtn = "Added VIPs";
      }
    })
  }
  //only numeric event
  onlyNumericInput(event) {
    return this.validationService.isNumericInput(event);
  }
  public isNumeric(value: any): boolean {
    return /^-?\d+$/.test(value);
  }
  public checkNumericInput(): void {
    this.addVipBtn = "Add VIPs"
    if (!this.isNumeric(this.vipCount)) {
      this.errorMsg = "Please enter a valid number";
    } else {
      this.errorMsg = "";
    }
  }


}
