import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-vip',
  templateUrl: './vip.component.html',
  styleUrls: ['./vip.component.scss']
})
export class VipComponent implements OnInit {
  VipStudentList;
  studentId;
  isLoading = true;
  isVipAllowed:boolean;
  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getVipStudentListData();
  }

  getVipStudentListData() {
    this.isLoading = true;

    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));
    this.dashboardService.getStudentVip({ student_id: this.studentId }).subscribe((response) => {
      this.isLoading = false;
      if (response.status === true && response.statusCode === 200) {
        this.VipStudentList = response.data.vipData
        this.isVipAllowed = response.data.isVipAllowed
      }
    })
  }



}
