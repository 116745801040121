import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamMemberRole'
})
export class TeamMemberRolePipe implements PipeTransform {

  transform(roleFlag: any, ...args: any[]): any {
    if (roleFlag == 1) {
      return 'Admin';
    } else if (roleFlag == 2) {
      return 'Counsellor';
    }
  }

}
