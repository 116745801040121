import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-whitelabelling-options',
	templateUrl: './whitelabelling-options.component.html',
	styleUrls: ['./whitelabelling-options.component.scss'],
})
export class WhitelabellingOptionsComponent implements OnInit {
	isLoading = false;
	featuresList = [];
	productUpdated = [];
	preActive = [];
	whiteLabellingOptions = [
		{ label: 'Career Library Search', value: 'career_library', show: true },
		{ label: 'Blog', value: 'blog', show: true },
		{ label: 'Career library links on matches page', value: 'my_matches', show: true },
		{ label: 'Counselor login link', value: 'counselor_login', show: true },
		{ label: 'Report Page Custom Url', value: 'site_address', show: true },
		{ label: 'Mail Statistics', value: 'mail_statistics', show: true },
		{ label: 'Show UPI Payment Details', value: 'show_payment_details', show: true },
		{ label: 'Allow Roadmap create by Student', value: 'allow_roadmap_create_by_student', show: true },
	];




	selectedFeatures: any = {};

	saveBtnLabel = 'Saved';
	isAgencyPlan = false;
	constructor(
		private formBuilder: FormBuilder,
		private dashboardService: DashboardService,
		private messageService: MessageService
	) { }

	ngOnInit() {
		this.getFeaturesToggle();
		// this.getWhiteLabellingSettings();
		//this.checkPlan();
	}
	// private checkPlan() {
	//   const decoded = this.dashboardService.decodeToken()
	//   if (decoded && decoded.active_plan === "Agency") {
	//     this.isAgencyPlan = true;

	//   }

	// }
	// getWhiteLabellingSettings() {
	// 	this.isLoading = true;
	// 	this.dashboardService.getWhiteLabellingOptions().subscribe((response) => {
	// 		this.isLoading = false;

	// 		if (response.status === true && response.statusCode === 200) {
	// 			if (response.data.options.career_library) {
	// 				this.selectedFeatures.career_library = true;
	// 			} else {
	// 				this.selectedFeatures.career_library = false;
	// 			}

	// 			if (response.data.options.blog) {
	// 				this.selectedFeatures.blog = true;
	// 			} else {
	// 				this.selectedFeatures.blog = false;
	// 			}

	// 			if (response.data.options.my_matches) {
	// 				this.selectedFeatures.my_matches = true;
	// 			} else {
	// 				this.selectedFeatures.my_matches = false;
	// 			}

	// 			if (response.data.options.counselor_login) {
	// 				this.selectedFeatures.counselor_login = true;
	// 			} else {
	// 				this.selectedFeatures.counselor_login = false;
	// 			}

	// 			if (response.data.options.mail_statistics) {
	// 				this.selectedFeatures.mail_statistics = true;
	// 			} else {
	// 				this.selectedFeatures.mail_statistics = false;
	// 			}

	// 			if (response.data.options.show_payment_details) {
	// 				this.selectedFeatures.show_payment_details = true;
	// 			} else {
	// 				this.selectedFeatures.show_payment_details = false;
	// 			}

	// 			if (response.data.options.show_profile_builder) {
	// 				this.selectedFeatures.show_profile_builder = true;
	// 			} else {
	// 				this.selectedFeatures.show_profile_builder = false;
	// 			}

	// 			if (response.data.options.show_scholarship_tab) {
	// 				this.selectedFeatures.show_scholarship_tab = true;
	// 			} else {
	// 				this.selectedFeatures.show_scholarship_tab = false;
	// 			}



	// 			if (response.data.options.site_address) {
	// 				this.selectedFeatures.site_address = response.data.options.site_address;
	// 			} else {
	// 				this.selectedFeatures.site_address = '';
	// 			}

	// 			if (response.data.options.allow_roadmap_create_by_student) {
	// 				this.selectedFeatures.allow_roadmap_create_by_student = true;
	// 			} else {
	// 				this.selectedFeatures.allow_roadmap_create_by_student = false;
	// 			}

	// 			let white_labelling = response.data.options.white_labelling;

	// 			this.whiteLabellingOptions.forEach((element) => {
	// 				if (white_labelling) {
	// 					element.show = true;
	// 				}
	// 				else {
	// 					if (element.value == "mail_statistics" || element.value == "show_payment_details") {
	// 						element.show = true;
	// 					} else {
	// 						element.show = false;
	// 					}
	// 				}
	// 			});
	// 			if (response.data.options.show_profile_builder_option) {
	// 				this.whiteLabellingOptions.push({ label: 'Show Profile Builder', value: 'show_profile_builder', show: true })
	// 			} else {
	// 				this.selectedFeatures.show_profile_builder_option = false;
	// 			}

	// 			if (response.data.options.show_scholarship_tab_option) {
	// 				this.whiteLabellingOptions.push({ label: 'Show Scholarship', value: 'show_scholarship_tab', show: true })
	// 			} else {
	// 				this.selectedFeatures.show_scholarship_tab_option = false;
	// 			}

	// 			/* if (response.data.options.show_one_page_summary_report_option) {
	// 				this.whiteLabellingOptions.push({ label: 'Show One Page Summary Report', value: 'show_one_page_summary_report', show: true })
	// 			} else {
	// 				this.selectedFeatures.show_one_page_summary_report_option = false;
	// 			} */


	// 		}
	// 	});
	// }
	featuresListBkp = [];
	getFeaturesToggle() {
		this.isLoading = true;
		this.dashboardService.getPartnerFeatures().subscribe((response) => {
			if (response.status === true && response.statusCode === 200) {
				this.preActive = [];
				this.featuresList = response.data

				for (let f of this.featuresList) {
					// if (f.status == 1) {
					// 	this.preActive.push(f.id)
					// }
					this.preActive.push(
						{
							id: f.id,
							status: f.status ? 1 : 0,
							is_checked:f.is_checked
						}
					)

				}
			}
		})
		this.isLoading = false;
	}

	onFeaturesChange(event, i) {
		if (event.target.checked) {
			this.featuresList[i].is_checked = 1;
			this.saveBtnLabel = "Save Changes"
		} else {
			this.featuresList[i].is_checked = 0;
			this.saveBtnLabel = "Save Changes"
		}


	}

	onSubmit() {
		this.productUpdated = [];
		for (let index in this.featuresList) {
			if(this.featuresList[index]['is_checked']!== this.preActive[index]['is_checked']) {
				this.productUpdated.push({
					id: this.featuresList[index].id,
					status: this.featuresList[index].is_checked ? 1 : 0
				});
			}
			
		}
		const payload = {
			product_updated: this.productUpdated
		};

		this.dashboardService.postWhitelabellingPartnerFeatures(payload).subscribe(response => {
			if (response.status == true && response.statusCode == 200) {
				this.messageService.add({
					key: 'topright',
					severity: 'success',
					detail: response.message,
				});
				this.saveBtnLabel = "Saved"
				this.getFeaturesToggle();
				this.dashboardService.getDashboardData().subscribe(response => {
					this.dashboardService.setCareerLibraryFlag({career_library_enabled : response.data.is_careerlibrary_menu_enabled,url : response.data.website});
				})
			}
		})
	}

	// whiteLabellingOptionsFormSubmit() {
	// 	this.saveBtnLabel = 'Saving';
	// 	this.dashboardService
	// 		.setWhiteLabellingOptions(this.selectedFeatures)
	// 		.subscribe(
	// 			(response) => {
	// 				if (response.status === true && response.statusCode === 200) {
	// 					this.saveBtnLabel = 'Saved';
	// 				}
	// 			},
	// 			(error) => {
	// 				this.saveBtnLabel = 'Save Changes';
	// 			}
	// 		);
	// }
}
