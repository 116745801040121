import { Component, OnInit} from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MessageService } from 'primeng/api';

interface Tab {
  id: number;
  name: string;
  image: string;
  event_track_name: string;
}

@Component({
  selector: 'app-nexus',
  templateUrl: './nexus.component.html',
  styleUrls: ['./nexus.component.scss'],
})
export class NexusComponent implements OnInit {
  isLoading = true;
  message: string;
  studentId: string;
  step_count = 1;
  imageBaseUrl= "https://mindler-products-images.imgix.net/"
  matchesData=[];
  isImmerseWhiteLabelled;
  immerseUrl;
  gradesOptions = [
    { label: '8th', id: 1 },
    { label: '9th', id: 2 },
    { label: '10th', id: 3 },
    { label: '11th', id: 4 },
    { label: '12th', id: 5 }
  ];

  curriculumOptions = [];



  selectedMatches = [];

  selectedGradeId=null;
  selectedBoard=null;
  showCurriculumDropdown = false;
  topTabs=[];
  selectedTab: Tab;
  selectedMatch;

  interDisciplinaryData;
  profileData;
  softSkillsData;
  roleModelData;
  subjectCombinationData;
  vciData;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router, private messageService: MessageService) {}

  ngOnInit() {
    this.getMatches();
    this.getWhiteLabelling();
  }


  toggleSelection(match) {
    const index = this.selectedMatches.findIndex(selectedMatch => selectedMatch.id === match.id);
    if (index > -1) {
      this.selectedMatches.splice(index, 1); 
    } else {
      this.selectedMatches.push(match);
    }
  }

  handleTabClick(tab: Tab) {
    this.selectedTab = tab;
  }

  handleNextTab(nextTab: Tab){
    this.selectedTab= this.topTabs[nextTab.id];
  }

  handleBackTab(currentTab: Tab){
    this.selectedTab= this.topTabs[currentTab.id-2];
  }

  handlePrevStep(){
    this.step_count=2;
    this.selectedBoard=null;
  }

  isSelected(match) {
    return this.selectedMatches.some(selectedMatch => selectedMatch.id === match.id);
  }

  getWhiteLabelling(){
    this.dashboardService.getWhiteLabellingOptions().subscribe((response) => {
      if (response.status === true && response.statusCode === 200) {
          this.isImmerseWhiteLabelled=response.data.options.is_immrse_whitelabeled;
          this.immerseUrl=response.data.options.immrse_url;
      }
    })
  }

  getMatches() {
    this.isLoading = true;

    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => {
      this.studentId = params.get('student_id');
    });

    this.dashboardService.studentMatches({ student_id: this.studentId }).subscribe(response => {
      if (response.status === false && response.statusCode === 200) {
        this.message = response.message;
        this.isLoading = false;
      } else if (response.status === true && response.statusCode === 200) {
        if (response.data.match.length === 0) {
          this.message = 'Assessments not completed yet.';
          this.isLoading = false;
        } else {
          this.matchesData = response.data.match;
          this.matchesData.forEach(match => {
            match.icon = this.imageBaseUrl+"confluence/assessment/matches-icons/"+match.icon;
          });
          this.getSchoolBoards();
        }
      }
    });
  }

  getStudentNexusResults() {
    this.dashboardService.getStudentNexusResults(this.studentId).subscribe(response => {
      this.isLoading = false;
      if (response.status === false && response.statusCode === 200) {
        this.message = response.message;
      } else if (response.status === true && response.statusCode === 200) {
        if (response.data.length !== 0) {
          this.step_count = 2;
          const careerIds = [
            response.data[0].career_1,
            response.data[0].career_2,
            response.data[0].career_3
        ];

        careerIds.forEach(careerId => {
            const match = this.matchesData.find(match => match.id === careerId);
            if (match) {
                this.selectedMatches.push(match);
            }
        });

        this.selectedGradeId = this.gradesOptions.find(grade => grade.id === response.data[0].grade_id);

        if(response.data[0].board_id){
          this.showCurriculumDropdown=true;
          this.selectedBoard = this.curriculumOptions.find(board => board.id === response.data[0].board_id);
        }

        }
      }
    });
  }

  getInterDisciplinaryCareers(){
    this.dashboardService.getInterDisciplinaryCareers({
      career_1: this.selectedMatches[0].id,
      career_2: this.selectedMatches[1].id,
      career_3: this.selectedMatches[2].id,
      board_id: this.selectedBoard ? this.selectedBoard.id : null,
      grade_id: this.selectedGradeId.id === 3 ? 3 : null
    }).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
          if(this.selectedGradeId.id==3){
            this.interDisciplinaryData = response.data.data.disciplinaryData;
            this.subjectCombinationData = response.data.data.subjectCombination; 
          }
          else if(this.selectedGradeId.id==1||this.selectedGradeId.id==2){
            this.subjectCombinationData = response.data.data; 
          }
          else{
            this.interDisciplinaryData = response.data.data;
          }
      }
    });
  }

  getProfileBuildingActivities(){
    this.dashboardService.getProfileBuildingActivities({
      domain_id:[this.selectedMatches[0].id,this.selectedMatches[1].id,this.selectedMatches[2].id]
    }).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.profileData = response.data.profileData;
      }
    });
  }

  getSoftSkill(){
    this.dashboardService.getSoftSkill({
      domain_id:[this.selectedMatches[0].id,this.selectedMatches[1].id,this.selectedMatches[2].id]
    }).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.softSkillsData = response.data.profileData;
      }
    });
  }

  getRoleModel(){
    this.dashboardService.getRoleModel({
      domain_id:[this.selectedMatches[0].id,this.selectedMatches[1].id,this.selectedMatches[2].id]
    }).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.roleModelData = response.data.profileData;
      }
    });
  }

  getVciData(){
    this.dashboardService.getVciData({
      domain_id:[this.selectedMatches[0].id,this.selectedMatches[1].id,this.selectedMatches[2].id]
    }).subscribe(data => {
      const response = data.apiData; 
      const env = data.env;
      if (response.status === true && response.statusCode === 200) {
        let immrseLiveUrl = '';

        if (env!='prod') {
            immrseLiveUrl = 'https://vipdev.mindler.com/';
        } else if (this.isImmerseWhiteLabelled) {
            immrseLiveUrl = this.immerseUrl;
        } else {
            immrseLiveUrl = 'https://mindler.immrse.in/';
        }
        
        this.vciData = response.data.profileData.map(profile => {
          return {
            ...profile,
            immerse_career_url: immrseLiveUrl + 'cr/' + profile.career_url
          };
        });
      }
    });
  }

  getSchoolBoards(){
    this.dashboardService.getSchoolBoards().subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.curriculumOptions = response.data.schoolBoard;
        this.getStudentNexusResults();
      }
    });
  }

  storeResults(){
    this.dashboardService.storeNexusResults({
      student_id: this.studentId,
      career_1: this.selectedMatches[0].id,
      career_2: this.selectedMatches[1].id,
      career_3: this.selectedMatches[2].id,
      board_id: this.selectedBoard ? this.selectedBoard.id : null,
      grade_id: this.selectedGradeId.id
    }).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
          if(this.selectedGradeId.id==3){
            this.interDisciplinaryData = response.data.data.disciplinaryData;
            this.subjectCombinationData = response.data.data.subjectCombination; 
          }
          else if(this.selectedGradeId.id==1||this.selectedGradeId.id==2){
            this.subjectCombinationData = response.data.data; 
          }
          else{
            this.interDisciplinaryData = response.data.data;
          }
      }
    });
  }


  nextStep() {
    if(this.step_count ===2 && this.selectedMatches.length!==3){
      this.messageService.add({
        key:"top-center",
        severity: 'error',
        detail: 'Select exactly 3 matches',
      });
      return;
    }
    else if(this.step_count ===2 && this.selectedGradeId===null){
      this.messageService.add({
        key:"top-center",
        severity: 'error',
        detail: 'Select your grade',
      });
      return;
    }
    else if(this.step_count ===2 && this.selectedGradeId.id<=3 && this.selectedBoard===null){
      this.messageService.add({
        key:"top-center",
        severity: 'error',
        detail: 'Select your curriculum',
      });
      return;
    }

    this.step_count += 1;
    if(this.step_count === 2){
    }
    else if(this.step_count===3){

      this.storeResults();

      if (this.selectedGradeId.id === 1 || this.selectedGradeId.id === 2) {
        this.topTabs = [
            { id: 1, name: "Profile Building Activities", image: "profileBuildingActivities_coloured.svg", event_track_name: "Profile Building Activities" },
            { id: 2, name: "Subject Combinations", image: "subjectCombinations_coloured.svg", event_track_name: "Subject Combinations" },
            { id: 3, name: "Soft Skills", image: "softSkills_coloured.svg", event_track_name: "Soft Skills" },
            { id: 4, name: "Role Models", image: "roleModel_coloured.svg", event_track_name: "Role Models" },
            { id: 5, name: "Virtual Career Simulator", image: "vci_coloured.svg", event_track_name: "Virtual Career Simulator" }
        ];
      } else if (this.selectedGradeId.id === 4 || this.selectedGradeId.id === 5) {
        this.topTabs = [
            { id: 1, name: "Profile Building Activities", image: "profileBuildingActivities_coloured.svg", event_track_name: "Profile Building Activities" },
            { id: 2, name: "Inter-Disciplinary Careers", image: "interDisciplinaryCareers_coloured.svg", event_track_name: "Inter-Disciplinary Careers" },
            { id: 3, name: "Soft Skills", image: "softSkills_coloured.svg", event_track_name: "Soft Skills" },
            { id: 4, name: "Role Models", image: "roleModel_coloured.svg", event_track_name: "Role Models" },
            { id: 5, name: "Virtual Career Simulator", image: "vci_coloured.svg", event_track_name: "Virtual Career Simulator" }
        ];
      } else if (this.selectedGradeId.id === 3) {
        this.topTabs = [
            { id: 1, name: "Profile Building Activities", image: "profileBuildingActivities_coloured.svg", event_track_name: "Profile Building Activities" },
            { id: 2, name: "Inter-Disciplinary Careers", image: "interDisciplinaryCareers_coloured.svg", event_track_name: "Inter-Disciplinary Careers" },
            { id: 3, name: "Subject Combinations", image: "subjectCombinations_coloured.svg", event_track_name: "Subject Combinations" },
            { id: 4, name: "Soft Skills", image: "softSkills_coloured.svg", event_track_name: "Soft Skills" },
            { id: 5, name: "Role Models", image: "roleModel_coloured.svg", event_track_name: "Role Models" },
            { id: 6, name: "Virtual Career Simulator", image: "vci_coloured.svg", event_track_name: "Virtual Career Simulator" }
        ];
      }

      this.getInterDisciplinaryCareers();
      this.getProfileBuildingActivities();
      this.getRoleModel();
      this.getSoftSkill();
      this.getVciData();

      this.selectedMatch=this.selectedMatches[0];
      this.selectedTab=this.topTabs[0];

    }
  }

  onGradeChange(selectedGrade) {
    this.showCurriculumDropdown = [1, 2, 3].includes(selectedGrade.id); 
    if(!this.showCurriculumDropdown){
      this.selectedBoard=null;
    }
  }

  handleMatchClick(match) {
    this.selectedMatch = match; 
  }

  hasMatchingVci(): boolean {
    return this.vciData.some(vci => vci.career_domain_id === this.selectedMatch.id);
  }

  handleRedirect(url: string | null): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
