import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {

  isLoading = true;

  loggedInUserDetails;

  getPartnerDetailsPostData = {
    section: '101'
  };

  displayImageUrl = '';
  brandingForm: FormGroup;

  color_1: string;
  color_2: string;

  originalPrimaryColor = '#007fb6';
  originalSecondaryColor = '#ea6915';
  // logoImagePath: string;

  isLargeImage = false;
  uploadedImageName = '';

  saveBtnLabel = 'Save Changes';
  isBtnDisabled = true;

  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService) { }

  ngOnInit() {
    this.color_1 = '#007fb6';
    this.color_2 = '#ea6915';

    this.brandingForm = this.formBuilder.group({
      type: ['changes'],
      image: [''],
      primary_color: [''],
      secondary_color: [''],
      orgnization: [''],
      og_description: ['', [Validators.required]]
    });

    this.getPartnerData();

    this.loggedInUserDetails = this.dashboardService.decodeToken();

    //
    this.brandingForm.valueChanges.subscribe(val => {
      this.saveBtnLabel = 'Save Changes';
      this.isBtnDisabled = false;
    });
  }

  // getters
  get orgnization() { return this.brandingForm.get('orgnization'); }
  get og_description() { return this.brandingForm.get('og_description'); }

  getPartnerData() {
    this.dashboardService.getPartnerData(this.getPartnerDetailsPostData).subscribe(response => {
      this.isLoading = false;

      this.color_1 = response.data.primary_color;
      this.color_2 = response.data.secondary_color;

      this.og_description.patchValue(response.data.og_description);
      
      if (response.data.image) {
        this.displayImageUrl = environment.logo_baseUrl + this.loggedInUserDetails.partner_id + '/' + response.data.image+'?w=350&h=75&fit=crop&crop=faces';
        this.brandingForm.patchValue({
          image: response.data.image
        }, { emitEvent: false });
      }
      this.brandingForm.patchValue({
        orgnization: response.data.orgnization
      }, { emitEvent: false });
    });
  }


  uploadImage(event) {
    if (event.target.files[0].size > 500000) {
      this.isLargeImage = true;
    }
    else {
      this.saveBtnLabel = 'Save Changes';

      this.isLargeImage = false;

      const fileName = event.target.files[0].name;
      
      if(event.target.files[0].type!="image/jpeg" && event.target.files[0].type!="image/png"){
        this.isLargeImage = true;   //Making Error Message to Red since file is not of specified type
        return;
      }
      const newFileName = fileName.replace(/ /g, '-');

      const uploadData = new FormData();
      uploadData.append('image', event.target.files[0], newFileName);
      uploadData.append('partner_id', this.loggedInUserDetails.partner_id);

      this.dashboardService.uploadImage(uploadData).subscribe(data => {
        this.uploadedImageName = data.name;
        this.displayImageUrl = environment.logo_baseUrl + this.loggedInUserDetails.partner_id + '/' + this.uploadedImageName+'?w=350&h=75&fit=crop&crop=faces';
        this.brandingForm.patchValue({
          image: this.uploadedImageName
        });
        this.saveBtnLabel = 'Save Changes';
      });
    }
  }

  brandingFormSubmit() {
    this.saveBtnLabel = 'Saving...';

    this.brandingForm.patchValue({
      primary_color: this.color_1,
      secondary_color: this.color_2
    }, { emitEvent: false });

    this.dashboardService.setBrandingDetails(this.brandingForm.value).subscribe(data => {
      if (data.statusCode === 500 && data.status === false) {

      }
      else {
        this.saveBtnLabel = 'Saved';
        setTimeout(() => {
          this.saveBtnLabel = 'Save Changes';
          this.isBtnDisabled = true;
        }, 3000);
      }
    });
  }

  revertToOriginal() {
    this.color_1 = this.originalPrimaryColor;
    this.color_2 = this.originalSecondaryColor;

    this.brandingForm.patchValue({
      primary_color: this.color_1,
      secondary_color: this.color_2
    });
  }

  onChange() {
    this.saveBtnLabel = 'Save Changes';
    this.isBtnDisabled = false;
  }

  removeLogo(){
    this.dashboardService.setBrandingDetails({type:'removelogo'}).subscribe(data => {
      if (data.statusCode === 500 && data.status === false) {

      }
      else {
        this.displayImageUrl = '';
        
      }
    });
  }

}
