import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { SuperadminService } from '../../services/superadmin.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms'
import { ValidationsService } from '../../services/common/validations.service';
import { environment } from '../../../environments/environment';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-admin-my-team',
  templateUrl: './admin-my-team.component.html',
  styleUrls: ['./admin-my-team.component.scss']
})

export class AdminMyTeamComponent implements OnInit {

  @Input() public partnerId;

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;
  // @ViewChild('editTeamMemberModalRef', { static: true }) editTeamMemberModal: ElementRef;

  isLoading = true;

  optionForm: FormGroup;

  getTeamPostData = {
    partner_id: Number,
    role: '',
    offset: '',
    search: ''
  };
  teamList;
  teamListForCsvDownload;

  // Delete Team Member
  actionPostData = {
    partner_id: Number,
    counselor_id: Number,
    action: Number
  };

  // Edit Team Member
  editMemberForm: FormGroup;
  editFormSubmitted = false;

  isLargeImage = false;
  isImageUploaded = true;
  uploadedImageName = '';
  displayImageUrl;

  // Reset Password
  isResetLinkSent = false;
  resetButtonText = 'Reset Password';
  resetButtonIndex;

  // Pagination
  noOfPages;

  // Filter
  filters;
  status = [];

  //
  teamSearch = new Subject<string>();
  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService, private superAdminService: SuperadminService) {
    // search
    this.teamSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.resetToPageFirst(this.myDummyEvent);
        this.getTeamList();
      });
  }

  ngOnInit() {
    this.getTeamPostData.partner_id = this.partnerId;

    this.filters = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 2 }
    ];

    this.optionForm = this.formBuilder.group({
      role: ['']
    });

    this.getTeamList();

    this.optionForm.get('role').valueChanges.subscribe(optedValue => {
      this.getTeamPostData.role = optedValue;
      this.resetToPageFirst(this.myDummyEvent);

      this.getTeamList();
    });


    // Edit Team Member Form
    this.editMemberForm = this.formBuilder.group({
      partner_id: [this.partnerId],
      name: ['', [Validators.required, Validators.pattern('[(A-Za-z0-9 )]+')]],
      email: [''],
      phone: ['', Validators.required],
      role: [''],
      status: [''],
      image: [''],
      counselor_id: ['']
    });
  }

  // getters
  get name() { return this.editMemberForm.get('name'); }
  get phone() { return this.editMemberForm.get('phone'); }
  get image() { return this.editMemberForm.get('image'); }
  get counselorId() { return this.editMemberForm.get('counselor_id'); }


  setFiltersAndGetList(event) {
    if (this.status.length == 2 || this.status.length == 0) {
      if (this.getTeamPostData.hasOwnProperty('status')) {
        delete this.getTeamPostData['status'];
      }
      this.resetToPageFirst(this.myDummyEvent);
      this.getTeamList();
    }
    else {
      this.getTeamPostData['status'] = event.value[0];
      this.resetToPageFirst(this.myDummyEvent);
      this.getTeamList();
    }
  }

  resetToPageFirst(event) {
    this.getTeamPostData.offset = '0';
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }
  }

  getTeamList() {

    this.superAdminService.getPartnerTeamList(this.getTeamPostData).subscribe(response => {
      this.isLoading = false;

      if (response.data != null) {
        this.teamList = response.data.counselorDetails;
        this.noOfPages = response.data.count;

        (this.teamList).forEach(element => {
          if (element.image != null) {
            element.image = environment.counsellor_photo_baseUrl + element.counselor_id + '/' + element.image;
          }
        });
      }
      else {
        this.teamList = null;
      }
    });
  }

  // Download CSV
  downloadCsv() {
    const fileName = 'Partner_Team';

    this.superAdminService.getTeamListForCsvDownload(this.getTeamPostData).subscribe(response => {
      if (response.data != null) {
        this.teamListForCsvDownload = response.data.counselorDetails;
        this.dashboardService.exportToCsvService(fileName, this.teamListForCsvDownload);
      }
      else {
        this.teamListForCsvDownload = null;
      }
    });
  }


  // delete/activate/deactivate member form step-1 (Set remove params)
  setActionParameters(counselorId, action) {
    this.actionPostData.partner_id = this.partnerId;
    this.actionPostData.counselor_id = counselorId;
    this.actionPostData.action = action;

    if (action == 1 || action == 2) {
      this.submitMemberAction();
    }
  }

  // delete/activate/deactivate member form step-2
  submitMemberAction() {
    this.superAdminService.teamMemberAction(this.actionPostData).subscribe(response => {
      this.getTeamList();
    });
  }


  // Edit Team Members - Set Params
  setEditTeamMemberParameters(member) {
    this.editFormSubmitted = false;

    this.editMemberForm.patchValue({
      name: member.name,
      email: member.email,
      phone: member.phone,
      image: member.image,
      role: member.role,
      status: member.status,
      counselor_id: member.counselor_id
    });
  }

  //
  uploadImage(event) {
    if (event.target.files[0].size > 500000) {
      this.isLargeImage = true;
    }
    else {
      this.isLargeImage = false;

      const fileName = event.target.files[0].name;
      const newFileName = fileName.replace(/ /g, '-');

      const uploadData = new FormData();
      uploadData.append('image', event.target.files[0], newFileName);
      uploadData.append('counselor_id', this.counselorId.value);

      this.superAdminService.uploadMemberImage(uploadData).subscribe(data => {
        this.uploadedImageName = data.name;

        this.displayImageUrl = environment.counsellor_photo_baseUrl + this.counselorId.value + '/' + this.uploadedImageName;

        this.editMemberForm.patchValue({
          image: this.displayImageUrl
        });
      });
    }
  }

  // Edit Team Members - Form Submit
  editMemberFormSubmit() {
    this.editFormSubmitted = true;

    if (this.editMemberForm.invalid) {
      return;
    }

    this.editMemberForm.patchValue({
      image: this.uploadedImageName
    });

    this.superAdminService.updateMember(this.editMemberForm.value).subscribe(data => {
      this.getTeamList();
    });
    // console.log(this.editTeamMemberModal);
    // this.editTeamMemberModal.nativeElement.modal('hide');
    $('#editTeamMember').modal('hide');
  }

  // reset password
  resetMemberPassword(memberId, index) {
    this.resetButtonIndex = index;

    const postData = {
      partner_id: this.partnerId,
      counselor_id: memberId
    };

    this.superAdminService.sendResetPasswordLink(postData).subscribe(response => {
      this.isResetLinkSent = true;
      this.resetButtonText = 'Link Sent';

      this.updateResetBtnText();
      // this.getTeamList();
    });
  }

  updateResetBtnText() {
    setTimeout(() => {
      this.resetButtonText = 'Reset Password';
    }, 5000);
  }

  paginate(event) {
    this.getTeamPostData.offset = (event.first);
    this.getTeamList();
  }

  // Telephone Input
  getNumber(fullNumber) {
    this.editMemberForm.patchValue({
      phone: fullNumber
    });
  }

  hasError(event: any): void {
    if (!event && this.phone.value !== '') {
      this.phone.setErrors({ invalidPhoneNumber: true });
    }
  }

}

