import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss']
})
export class LockScreenComponent implements OnInit {
  @Input() lockMessage: string = "Your free trial has expired.<br> Please subscribe to get access to this page.";
  @Input() showSubscribeButton: boolean = true;

  studentCSV;

  constructor(private dashboardService: DashboardService) {
    this.dashboardService.getStudentsCSV().subscribe(data => {
      console.log(data.data.content);
      this.studentCSV = data.data.content;
      var studentCsvEle = document.getElementById('studentCSV') as HTMLAnchorElement;
      if(studentCsvEle) {
        studentCsvEle.href = "data:text/csv;base64," + btoa(this.studentCSV);
      }
    });
  }

  ngOnInit() {
  }

}
