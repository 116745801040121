import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ValidationsService } from 'src/app/services/common/validations.service';
import { Paginator } from 'primeng/paginator';
declare var $: any;

@Component({
  selector: 'app-exam-shortlist',
  templateUrl: './exam-shortlist.component.html',
  styleUrls: ['./exam-shortlist.component.scss']
})
export class ExamShortlistComponent implements OnInit {

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  isLoading = false;
  isDataLoading = false;
  isTableDataLoading = true;  // Important (if false initially, then length of undefined error)

  target_career;
  backup_career;
  backup_career_2;

  activeCareerTab; // target_career, backup_career, backup_career_2

  careerDomain;

  examListApiResponse;
  examListFromApi;

  //
  collegeListApiResponse;
  examName;
  isCollegeFound;

  viewSubscribedClicked;


  limit;  // show Records
  search;

  examSearch = new Subject<string>();

  getExamListPostData: any = {};

  // Pagination
  noOfPages;

  // saveRoadmap
  roadmapNameForm: FormGroup;
  roadmapNameFormSubmitted;
  nameAlreadyExists;

  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  showSaveButton = true;

  constructor(private router: Router, private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService) {
    this.examSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.search = value;
        this.getExamListPostData.search = value;
        this.setFilterAndGetExamList(this.myDummyEvent);
      });
  }

  ngOnInit() {
    this.limit = 10;
    this.search = '';
    this.getExamListPostData.search = '';

    this.target_career = localStorage.getItem('target_career');
    this.backup_career = localStorage.getItem('backup_career');
    this.backup_career_2 = localStorage.getItem('backup_career_2');

    this.careerDomain = this.target_career;
    this.getExamListByDomain(this.careerDomain);

    this.activeCareerTab = 'target_career';

    this.roadmapNameForm = this.formBuilder.group({
      roadmap_name: ['', [Validators.required,Validators.maxLength(50)]]
    });

    if ((localStorage.getItem('student_id'))) {
      this.showSaveButton = false;
    }
    else {
      this.showSaveButton = true;
    }
  }

  // getters
  get roadmapName() { return this.roadmapNameForm.get('roadmap_name'); }


  getExamListByDomain(activeCareerTab) {
    if (activeCareerTab === 'target_career') {
      this.activeCareerTab = 'target_career';
    } else if (activeCareerTab === 'backup_career') {
      this.activeCareerTab = 'backup_career';
    } else if (activeCareerTab === 'backup_career_2') {
      this.activeCareerTab = 'backup_career_2';
    }

    this.limit = this.getExamListPostData.limit = 10;
    this.search = this.getExamListPostData.search = '';
    this.viewSubscribedClicked = false;

    // this.getExamList();
    this.setFilterAndGetExamList(this.myDummyEvent);
  }

  setFilterAndGetExamList(event) {
    this.getExamListPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }

    if (this.viewSubscribedClicked === true) {
      this.viewSubscribedClicked = !this.viewSubscribedClicked;
      this.getExamListPostData.limit = this.limit;
      this.viewSubscribedExams();
    }
    else {
      this.getExamList();
    }
  }


  getExamList() {
    this.getExamListPostData.domain = this.careerDomain;
    this.getExamListPostData.limit = this.limit;

    if (this.viewSubscribedClicked == true) {
      this.viewSubscribedClicked = false;
    }
    this.isTableDataLoading = true;

    this.dashboardService.getRoadmapExamsList(this.getExamListPostData).subscribe(response => {
      this.isTableDataLoading = false;
      if (response.status === true && response.statusCode === 200) {
        this.examListApiResponse = response.data;
        this.examListFromApi = response.data.exams;
        this.noOfPages = response.data.count;
      }
    });
  }


  saveRoadmap() {
    if (localStorage.getItem('fromSavedRoadmap') != null && localStorage.getItem('savedRoadmapName') !== null) {
      this.removeRoadmapFlagsForStorage();
      this.router.navigate(['/saved-roadmaps','india']);
    }
    else if (localStorage.getItem('student_id') != null) {
      //
    }
    else {
      $('#saveRoadmapNameModal').modal('show');
    }
  }

  shortlistOrRemoveCollege(collegeId, collegeShortlisted) {
    const saveId = [];
    const removeId = [];

    if (collegeShortlisted) {
      removeId.push(collegeId);
    }
    else {
      saveId.push(collegeId);
    }

    const saveCollegesPostData = {
      domain: this.careerDomain,
      saveCollegeIds: saveId,
      removeCollegeIds: removeId
    };

    this.dashboardService.saveColleges(saveCollegesPostData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.viewCollegeList(this.examName);
      }
    });
  }


  roadmapNameFormSubmit() {
    this.roadmapName.setValue(this.roadmapNameForm.value.roadmap_name.trim());
    this.roadmapNameFormSubmitted = true;
    if (this.roadmapNameForm.invalid) {
      return;
    }

    this.dashboardService.saveRoadmapName(this.roadmapNameForm.value).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        if (response.message === 'Name assigned successfully') {
          this.nameAlreadyExists = false;
          this.roadmapNameForm.reset();
          this.roadmapNameFormSubmitted = false;
          $('#saveRoadmapNameModal').modal('hide');

          this.removeRoadmapFlagsForStorage();
          this.router.navigate(['/saved-roadmaps','india']);
        }
        else if (response.message === 'Roadmap name already exists') {
          this.nameAlreadyExists = true;
        }
      }
    });
  }

  resetFlag() {
    this.nameAlreadyExists = false;
  }

  removeRoadmapFlagsForStorage() {
    localStorage.removeItem('roadmap_id');
    localStorage.removeItem('fromSavedRoadmap');
    localStorage.removeItem('savedRoadmapName');
    localStorage.removeItem('target_career');
    localStorage.removeItem('backup_career');
    localStorage.removeItem('backup_career_2');
  }


  viewSubscribedExams() {
    this.viewSubscribedClicked = !this.viewSubscribedClicked;

    this.getExamListPostData.domain = this.careerDomain;

    if (this.viewSubscribedClicked == true) {
      this.dashboardService.getSubscribedExamsListRoadmap(this.getExamListPostData).subscribe(response => {
        if (response.status === true && response.statusCode === 200) {
          this.examListFromApi = response.data.exams;
          this.examListFromApi.map((exam) => {
            exam.exam_shortlisted = 9989;   // any dummy value to display the check mark.
          });
          this.noOfPages = 0;
        }
        else if (response.status === false && response.statusCode === 200) {
          this.examListFromApi = '';
          this.noOfPages = 0;
        }
      });
    }
    else {
      this.getExamList();
    }
  }


  viewCollegeList(examShortForm) {
    this.examName = examShortForm;

    const postData = {
      short_form: examShortForm,
      domain: this.careerDomain
    };

    this.dashboardService.getCollegeListForExamRoadmap(postData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.isCollegeFound = true;
        this.collegeListApiResponse = response.data;
        $('#collegeListModal').modal('show');
      }
      if (response.status === false && response.statusCode === 200) {
        this.isCollegeFound = false;
        $('#collegeListModal').modal('show');
      }
    });
  }


  paginate(event) {
    this.getExamListPostData.offset = (event.first);
    this.getExamList();
  }
}
