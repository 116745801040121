import { Component, NgZone, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessageService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { WindowRefService } from 'src/app/services/window-ref.service';
declare var $: any;

@Component({
  selector: 'app-generate-coupons',
  templateUrl: './generate-coupons.component.html',
  styleUrls: ['./generate-coupons.component.scss']
})
export class GenerateCouponsComponent implements OnInit {
  // isLoading = true;
  loggedInUserDetails;
  isBuyMoreVipButtonClicked = false;
  displayGenerateCouponModal: boolean;
  partnerServiceListOne = [];
  partnerServiceListTwo = [];
  CouponValue = 0; //initial value
  generateValue = 0; //initial value for generate coupons
  currentRemainingVip = 0;
  totalRemainingVip = 0;
  currentRemainingAssessmentCoupon = 0;
  totalRemainingAssessmentCoupon = 0;
  isVirtualTrue: boolean = false;
  updatedAddedProduct = [];
  savingText = "Generate Coupons";
  preActive = [];
  isUserTouchedVirtualServiceSecond = {};
  subProductsAdded;
  pamAccess;
  msgShow;
  subscriptionStats;
  negativeValueError = false;
  serviceOneForm = this.fb.group({
    Assessment: [''],
    sub_product: this.fb.group({
      sub_product_name: ['']
    }),
    Activities: [''],
    ActionPlan: [''],
    ReportAndMatches: [''],
  })

  @Output() submitGenerateCoupon = new EventEmitter();
  constructor(private dashboardService: DashboardService, private messageService: MessageService, private fb: FormBuilder,
    private windowRef: WindowRefService, private zone: NgZone,) { }

  onAssessmentChange(event) {
    let OnSubProductDependency = this.serviceOneForm.get('sub_product').get('sub_product_name');
    if (event && event.target.checked) {
      OnSubProductDependency.enable()
    } else {

      OnSubProductDependency.disable();
      OnSubProductDependency.setValue(null)
    }
    this.onRadioChange(null);
  }

  onRadioChange(event) {
    let OnSubProductDependency = this.serviceOneForm.get("Activities");
    let onSubMatches = this.serviceOneForm.get("ReportAndMatches");
    let OnSubActionPlan = this.serviceOneForm.get("ActionPlan");

    if (!this.pamAccess) {
      OnSubProductDependency.disable()
      onSubMatches.disable();
      OnSubActionPlan.disable();
      return;
    }
    if (event && event.target.checked) {
      OnSubProductDependency.enable()
      onSubMatches.enable();
      OnSubActionPlan.enable();
    } else {
      OnSubProductDependency.disable();
      onSubMatches.disable();
      OnSubActionPlan.disable();
      OnSubProductDependency.setValue(null);
      onSubMatches.setValue(null);
      OnSubActionPlan.setValue(null);
    }
  }


  ngOnInit() {
    this.loggedInUserDetails = this.dashboardService.decodeToken();
    this.getAssessmentCouponPartnerAndServices();
    this.onAssessmentChange(null)
  }

  showGenerateCouponDialog() {
    if (!this.totalRemainingAssessmentCoupon) {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Please upgrade your account to Generate Coupons. ",
      });
      return
    } else {
      this.displayGenerateCouponModal = !this.displayGenerateCouponModal;
    }
  }
  showGenerateDialog() {
    if (!this.totalRemainingVip) {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Please upgrade your account to Generate Vip. ",
      });
      return
    } else {
      this.displayGenerateCouponModal = !this.displayGenerateCouponModal;
    }
  }
  closeGeneratePopup() {
    this.displayGenerateCouponModal = false;
  }
  closex() {
    this.isBuyMoreVipButtonClicked = false;
  }


  getAssessmentCouponPartnerAndServices() {
    // this.isLoading = true;

    this.updatedAddedProduct = [];
    this.subProductsAdded = [];
    this.dashboardService.getPartnerServicesAndFeatures().subscribe((response) => {
      this.partnerServiceListOne = response.data.serviceArray1;
      this.partnerServiceListOne = this.partnerServiceListOne.map((data) => {
        data['preStatus'] = data.status;
        return data;
      })
      this.partnerServiceListTwo = response.data.serviceArray2;
      this.partnerServiceListTwo = this.partnerServiceListTwo.map((data) => {
        data['preStatus'] = data.status;
        return data;
      })
      this.subscriptionStats = response.data.subscriptionStatus;
      this.pamAccess = response.data.pam_access;
      if (!this.pamAccess) {
        let OnSubProductDependency = this.serviceOneForm.get('sub_product').get('sub_product_name');
        OnSubProductDependency.enable();
      }
      for (let record of this.partnerServiceListOne) {
        if (record.product_name == 'Assessment' && record.status == 1) {
          this.serviceOneForm.get('Assessment').setValue(true);
          this.serviceOneForm.enable()
        }
        if (record.product_name == 'Activities' && record.status == 1) {
          this.serviceOneForm.get('Activities').setValue(true);
        }
        if (record.product_name == 'Action Plan' && record.status == 1) {
          this.serviceOneForm.get('ActionPlan').setValue(true)
        }
        if (record.product_name == 'Report & Matches' && record.status == 1) {
          this.serviceOneForm.get('ReportAndMatches').setValue(true);
        }
      }
      const virtualInternship = this.partnerServiceListTwo.find(product => product.product_name === "Virtual Internship");
      if (virtualInternship) {
        // Access the "remaining" property value
        this.totalRemainingVip = virtualInternship.remaining;
        if (virtualInternship.status === 1) {
          this.isVirtualTrue = true
        }
      }


      const assessmentCoupon = this.partnerServiceListOne.find(product => product.product_name === "Assessment");
      if (assessmentCoupon) {
        // Access the "remaining" property value
        this.totalRemainingAssessmentCoupon = assessmentCoupon.remaining;
      }
      // this.isLoading = false;
    })
  }
  getVirtual() {
    if (this.isVirtualTrue) {
      if (this.CouponValue <= 0) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  increment() {
    if (this.CouponValue >= this.totalRemainingVip) {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Limit exceeds",
      });
    } else {
      this.CouponValue++;
      this.currentRemainingVip = this.totalRemainingVip - this.CouponValue;
    }
  }

  checkVipInputValue() {
    this.currentRemainingVip = this.totalRemainingVip - this.CouponValue;
    if (this.CouponValue > this.totalRemainingVip) {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Limit exceeds",
      });
    }
  }

  allValuesStartWithSTR(): boolean {
    if (!this.subscriptionStats) {
      return false
    } else {
      if (this.subscriptionStats.plan_id === 'FREEUSER') {
        return true;
      } else {
        return this.subscriptionStats.plan_id.startsWith('STR');
      }
    }

  }

  validateCouponInput(inputElement: HTMLInputElement) {
    const inputValue = inputElement.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    inputElement.value = sanitizedValue;

    const numericValue = parseInt(sanitizedValue, 10);
    this.negativeValueError = numericValue < 0;
  }

  decrement() {
    if (this.CouponValue > 0) {
      this.CouponValue--;
      this.currentRemainingVip = this.totalRemainingVip - this.CouponValue;
    }
  }
  generateIncrement() {
    if (this.generateValue >= this.totalRemainingAssessmentCoupon) {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Limit exceeds",
      });
    } else {
      this.generateValue++;
    }
  }

  generateVipCouponCaseIncrement() {
    this.generateValue++;
  }


  checkAssessmentInputValue() {
    if (this.generateValue > this.totalRemainingAssessmentCoupon) {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Limit exceeds",
      });
    }
  }

  generateDecrement() {
    if (this.generateValue > 0) {
      this.generateValue--;
    }
  }

  getService1FormData() {
    this.updatedAddedProduct = [];
    this.subProductsAdded = [];
    let StreamCarrerCollege = this.serviceOneForm.get('sub_product').get('sub_product_name').value;
    let Activites = this.serviceOneForm.get('Activities').value ? 1 : 0;

    let Assessment = this.serviceOneForm.get('Assessment').value ? 1 : 0;
    let ActionPlan = this.serviceOneForm.get('ActionPlan').value ? 1 : 0;
    let ReportAndMatches = this.serviceOneForm.get('ReportAndMatches').value ? 1 : 0;
    // let Report = this.serviceOneForm.get('Report').value ? 1 : 0;
    if (StreamCarrerCollege) {
      this.subProductsAdded.push(StreamCarrerCollege);
    }
    if (Activites) {
      let subMappingId = this.partnerServiceListOne.filter(d => d.product_name == "Activities")[0]['id'];
      this.updatedAddedProduct.push(subMappingId);
    }
    if (Assessment) {
      let subMappingId = this.partnerServiceListOne.filter(d => d.product_name == "Assessment")[0]['id'];
      this.updatedAddedProduct.push(subMappingId);
    }
    if (ActionPlan) {
      let subMappingId = this.partnerServiceListOne.filter(d => d.product_name == "Action Plan")[0]['id'];
      this.updatedAddedProduct.push(subMappingId);
    }
    if (ReportAndMatches) {
      let subMappingId = this.partnerServiceListOne.filter(d => d.product_name == "Report & Matches")[0]['id'];
      this.updatedAddedProduct.push(subMappingId);
    }
  }

  checkInputValue() {
    this.getService1FormData()
    if (!this.pamAccess) {
      this.partnerServiceListTwo.forEach((service, index) => {
        if (service.status == 1) {
          this.updatedAddedProduct.push(service.id)
        }
      })
    }
    if (this.pamAccess) {
      this.partnerServiceListTwo.forEach((service, index) => {
        if (service.status == 1) {
          this.updatedAddedProduct.push(service.id)
        } else if (this.isUserTouchedVirtualServiceSecond[index] && service.status == 0) {
          this.updatedAddedProduct.slice(0, index)
        }
      })
    }
  }

  submitAdminAccess() {
    // this.updatedAddedProduct = [];
    // this.subProductsAdded = [];

    this.checkInputValue();
    let reqObj: any = {
      "products": this.updatedAddedProduct,
      "sub_products": this.subProductsAdded,
      "quantity": this.generateValue,
      "per_coupon_vip": this.CouponValue,
    }
    if (!this.isVipValid()) {
      if (this.isValid()) {
        if (!this.generateValue) {
          this.messageService.add({
            key: 'topright',
            severity: 'warn',
            detail: 'Coupon should not be Zero',
          });
        }
        if (this.generateValue) {
          if (this.updatedAddedProduct.includes(6) && this.CouponValue == 0) {
            this.messageService.add({
              key: 'topright',
              severity: 'warn',
              detail: 'VIP should not be Zero',
            });
          } else {
            this.dashboardService.addAssessmentCoupons(reqObj).subscribe(response => {
              if (response.status == true && response.statusCode == 200) {
                this.isUserTouchedVirtualServiceSecond = {}
                this.messageService.add({
                  key: 'topright',
                  severity: 'success',
                  detail: response.message,
                });
                this.displayGenerateCouponModal = false;
                this.CouponValue = 0;
                this.generateValue = 0;
                this.getAssessmentCouponPartnerAndServices();
                this.isVirtualTrue = false;
                this.currentRemainingVip = 0;

                //reset form
                this.serviceOneForm.reset();
                this.submitGenerateCoupon.emit();
              } else {
                this.messageService.add({
                  key: 'topright',
                  severity: 'warn',
                  detail: response.message,
                });
              }
            })
          }
        }

      } else {
        this.messageService.add({
          key: 'topright',
          severity: 'warn',
          detail: "Please add Career, Stream or College First",
        });
      }
    } else {
      if (!this.generateValue) {
        this.messageService.add({
          key: 'topright',
          severity: 'warn',
          detail: 'Coupon should not be Zero',
        });
      }
      if (this.generateValue) {
        if (this.updatedAddedProduct.includes(6) && this.CouponValue == 0) {
          this.messageService.add({
            key: 'topright',
            severity: 'warn',
            detail: 'VIP should not be Zero',
          });
        } else {
          this.dashboardService.addAssessmentCoupons(reqObj).subscribe(response => {
            if (response.status == true && response.statusCode == 200) {
              this.isUserTouchedVirtualServiceSecond = {}
              this.messageService.add({
                key: 'topright',
                severity: 'success',
                detail: response.message,
              });
              this.displayGenerateCouponModal = false;
              this.CouponValue = 0;
              this.generateValue = 0;
              this.getAssessmentCouponPartnerAndServices();
              this.isVirtualTrue = false;
              this.currentRemainingVip = 0;
              //reset form
              this.serviceOneForm.reset();
              this.submitGenerateCoupon.emit();
            } else {
              this.messageService.add({
                key: 'topright',
                severity: 'warn',
                detail: response.message,
              });
            }
          })
        }
      }
    }


  }

  isVipValid() {
    this.checkInputValue();
    if (this.updatedAddedProduct.length == 1 && this.updatedAddedProduct.includes(6)) {
      return true
    }
    return false
  }

  isValid() {
    this.checkInputValue();
    if ((this.subProductsAdded.includes(1)) || (this.subProductsAdded.includes(2)) || (this.subProductsAdded.includes(3))) {
      return true
    }
    return false;
  }

  onServiceProductChange(event, index, service) {
    if (event.target.checked) {



      this.isUserTouchedVirtualServiceSecond[index] = false;
      let findVirtualIndex = service.product_name === 'Virtual Internship';
      if (findVirtualIndex) {
        this.isVirtualTrue = true
      }
      service.status = 1;
    } else {
      this.isUserTouchedVirtualServiceSecond[index] = true;
      let findVirtualIndex = service.product_name === 'Virtual Internship';
      if (findVirtualIndex) {
        this.isVirtualTrue = false
        this.CouponValue = 0;
      }
      service.status = 0;
    }

  }

  buyMoreVipAction() {
    this.isBuyMoreVipButtonClicked = true;
    this.displayGenerateCouponModal = false;
  }


}
