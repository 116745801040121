import { Component, OnInit } from '@angular/core';
import { PreloginService } from '../../services/prelogin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadFilesService } from '../../services/lazy-load-files.service';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  loading = true;
  isInvalid = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private preLoginService: PreloginService, private lazyLoadService: LazyLoadFilesService) {
    // redirect already logged in user to dashboard
    this.checkEmail();
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/my-dashboard']);
    }
  }

  checkEmail() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty('verification') && params.verification) {
        // localStorage.setItem('pp_session', '');
        // localStorage.setItem('pp_token', '');
        this.preLoginService.setLoggedInFlagForNav(false);
        const verificationToken = params.verification;
  
        const postData = {
          verification: verificationToken,
        };
        this.preLoginService.verifyEmail(postData).subscribe(response => {
          this.loading = false;
          if (response.status === true && response.statusCode === 200) {
            localStorage.setItem('pp_session', response.session);
            localStorage.setItem('pp_token', response.token);
            this.preLoginService.setLoggedInFlagForNav(true);
  
            localStorage.removeItem('signUpEmail');
            localStorage.removeItem('signUpStep');
  
            this.lazyLoadService.loadRazorpayScript();
  
            // redirect to the profile
            this.router.navigate(['/my-profile']);
          }
          else if (response.status === false && response.statusCode === 200) {
            this.preLoginService.logOut();
            this.router.navigate(['/login']);
            // this.loading = false;
            // this.isInvalid = true;
          }
        },
          error => {
            this.loading = false;
            this.isInvalid = true;
          });
      }

    });
  }

  ngOnInit() { }

}
