import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss']
})
export class ActionPlanComponent implements OnInit {

  isLoading = false;
  message;
  studentId;

  isActionPlanPresent = false;

  actionPlanText = null;
  attachments: any = [];
  actionPlanId;

  getActionPlansPostData;
  actionPlansList;

  actionPlanResourceBaseUrl;

  saveBtnLabel = 'Save';
  isSavedOnce = false;
  isImageUploading = false;

  shareBtnLabel = 'Share with Student';

  fileTooBig = false;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

    this.actionPlanResourceBaseUrl = environment.action_plan_resource_baseUrl + this.studentId + '/';

    this.getActionPlanData();
  }


  // get Action Plan Data
  getActionPlanData() {
    this.isLoading = true;
    this.actionPlanText = null;
    this.attachments = [];
    this.actionPlanId = null;

    this.getActionPlansPostData = {
      student_id: this.studentId
    };

    this.dashboardService.getActionPlans(this.getActionPlansPostData).subscribe(response => {
      this.isLoading = false;
      if (response.statusCode === 200 && response.status === true) {
        this.actionPlansList = response.data.actionplans;

        if (response.data.actionplans.length > 0) {
          this.actionPlansList.forEach(actionPlan => {
            if (actionPlan.shared == null) {
              this.isActionPlanPresent = true;

              this.actionPlanText = actionPlan.action_plan;
              if (actionPlan.attachments !== 'null') {
                this.attachments = JSON.parse(actionPlan.attachments);
              }
              this.actionPlanId = actionPlan.action_plan_id;
              return false;
            }
          });
        }

      }
      else if (response.statusCode === 200 && response.status === false) {
        this.message = response.message;
      }
    });
  }


  // Save Action Plan
  actionPlanFormSubmit() {
    this.isSavedOnce = false;

    if ((this.actionPlanText != null && this.actionPlanText !== '') || this.attachments.length > 0) {
      const postData = {
        student_id: this.studentId,
        action_plan: this.actionPlanText,
        attachments: this.attachments,
        action_plan_id: this.actionPlanId
      };

      this.saveBtnLabel = 'Saving...';

      this.dashboardService.addActionPlan(postData).subscribe(response => {
        if (response.statusCode === 200 && response.status === true) {
          this.actionPlanId = response.action_plan_id;
          this.saveBtnLabel = 'Saved';
          this.updateSaveBtnLabel();
          this.isSavedOnce = true;
        }
      },
        error => {
          this.saveBtnLabel = 'Save';
        });
    }
  }

  updateSaveBtnLabel() {
    setTimeout(() => {
      this.saveBtnLabel = 'Save';
    }, 2000);
  }

  // upload Resources
  uploadFile(event) {
    this.fileTooBig = false;
    var size = parseInt((event.target.files[0].size / 1024).toFixed(2));
    if(size>10000){
      this.fileTooBig = true;
      return ;
    }
    this.isImageUploading = true;
    const fileName = event.target.files[0].name;
    const newFileName = fileName.replace(/ /g, '-');

    const uploadData = new FormData();
    uploadData.append('student_id', this.studentId);
    // 3rd parameter is used to give our name to file, means replace actual name with our name
    uploadData.append('attachment', event.target.files[0], newFileName);


    this.dashboardService.uploadActionPlanResources(uploadData).subscribe(response => {
      this.isImageUploading = false;
      if (response.statusCode === 200 && response.status === true) {
        this.attachments.push(response.name);
      }
    });
  }


  removeAttachment(attachment) {
    if (this.attachments.indexOf(attachment) !== -1) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);
    }
  }


  sharePlanWithStudent() {
    this.shareBtnLabel = 'Share with Student...';
    const postData = {
      student_id: this.studentId,
      action_plan_id: this.actionPlanId
    };

    this.dashboardService.shareActionPlan(postData).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.shareBtnLabel = 'Shared with Student';
        this.updateShareBtnLabelAndUI();
        this.isSavedOnce = false;
      }
    },
      error => {
        this.shareBtnLabel = 'Share with Student';
      });
  }

  updateShareBtnLabelAndUI() {
    setTimeout(() => {
      this.shareBtnLabel = 'Share with Student';
      this.getActionPlanData();
    }, 2000);
  }


  //
  parseToJson(attachments) {
    return JSON.parse(attachments);
  }

}
