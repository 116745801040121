import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { SuperadminService } from '../../services/superadmin.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-admin-payment',
  templateUrl: './admin-payment.component.html',
  styleUrls: ['./admin-payment.component.scss']
})
export class AdminPaymentComponent implements OnInit {

  @Input() public partnerId;

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  isLoading = true;

  getPaymentsPostData = {
    partner_id: Number,
    status: '',
    orderBy: '',
    search: '',
    offset: '',
    limit: ''
  };
  paymentsList;

  // Pagination
  noOfPages;

  paymentsTableSearch = new Subject<string>();
  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  // comp
  generatePaymentLinkForm: FormGroup;
  isGeneratePaymentLinkFormSubmitted;
  today = new Date();

  constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private superAdminService: SuperadminService) {
    // search
    this.paymentsTableSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.setFilterAndGetList();
      });
  }


  ngOnInit() {
    this.getPaymentsPostData.partner_id = this.partnerId;
    this.getPaymentsPostData.limit = '10';


    this.getPaymentsList();

    this.generatePaymentLinkForm = this.formBuilder.group({
      partner_id: [this.partnerId],
      amount: ['', [Validators.required, Validators.pattern('^0*[1-9][0-9]*$')]],
      expiry_date: ['', Validators.required],
      partial_payment: [false],
      invoice_note_description: ['', Validators.required]
    });
  }

  // getters
  get amount() { return this.generatePaymentLinkForm.get('amount'); }
  get expiry_date() { return this.generatePaymentLinkForm.get('expiry_date'); }
  get partial_payment() { return this.generatePaymentLinkForm.get('partial_payment'); }
  get invoice_note_description() { return this.generatePaymentLinkForm.get('invoice_note_description'); }
  //
  setFilterAndGetList() {
    this.getPaymentsPostData.offset = '0';
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(this.myDummyEvent);
    }
    this.getPaymentsList();
  }

  isUnixTimestamp(value: any): boolean {
    const num = Number(value);
    // Check if the value can be converted to a number, is an integer, and is positive
    // Also check if the value makes sense as a Unix timestamp by comparing it to a reasonable minimum Unix timestamp
    // Here we use the Unix timestamp for 2000-01-01, but you can adjust this as needed
    return !isNaN(num) && Number.isInteger(num) && num >= 946684800;
  }

  // only number input
  onlyNumericInput(event) {
    return this.validationService.isNumericInput(event);
  }


  getPaymentsList() {
    this.isLoading = true;

    this.superAdminService.getAllPayments(this.getPaymentsPostData).subscribe(response => {
      this.isLoading = false;

      if (response.status === true && response.statusCode === 200) {
        if (response.data != null) {
          this.paymentsList = response.data.invoices;
          this.noOfPages = response.data.count;
        }
      }
      else if (response.status === false && response.statusCode === 200) {
        this.paymentsList = null;
        this.noOfPages = 0;
      }
    });
  }


  //
  generatePaymentLinkFormSubmit() {
    this.isGeneratePaymentLinkFormSubmitted = true;

    if (this.generatePaymentLinkForm.invalid) {
      return;
    }

    const timeZoneOffset = (this.today).getTimezoneOffset() * 60000;
    const formattedExpiryDate = new Date(this.expiry_date.value - timeZoneOffset).toISOString().slice(0, -1).substring(0, 10);

    const createPaymentLinkPostData = {
      partner_id: this.partnerId,
      amount: this.amount.value,
      expire_by: formattedExpiryDate,
      partial_payment: this.partial_payment.value,
      invoice_note_description: this.invoice_note_description.value
    };

    this.superAdminService.createPaymentLink(createPaymentLinkPostData).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.isGeneratePaymentLinkFormSubmitted = false;
        this.generatePaymentLinkForm.reset();
        this.setFilterAndGetList();
      }
    });
  }


  // copyToClipboard(elementRef) {
  //   const range = document.createRange();
  //   range.selectNode(elementRef);
  //   window.getSelection().addRange(range);

  //   try {
  //     const successful = document.execCommand('copy');
  //     if (successful) {
  //       // console.log('success');
  //     }
  //   } catch (err) {
  //     // console.log('Oops, unable to copy');
  //   }

  //   // Remove the selections - NOTE: Should use removeRange(range) when it is supported
  //   window.getSelection().removeAllRanges();
  // }

  copyToClipboard(elementRef) {
    const textToCopy = elementRef.textContent || elementRef.innerText;
    console.log(textToCopy); // Log the URL
    navigator.clipboard.writeText(textToCopy).then(() => {
      // console.log('success');
    }, (err) => {
      // console.log('Oops, unable to copy');
    });
  }


  // Paginate (with serialNumber update)
  paginate(event) {
    this.getPaymentsPostData.offset = (event.first);
    this.getPaymentsList();
  }

}
