import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  isLoading = true;
  studentId;

  quickNotesForm: FormGroup;
  detailNotesForm: FormGroup;

  isQuickNotesFormSubmitted: boolean;
  isDetailNotesFormSubmitted: boolean;

  isEditEnabled = false;

  getStudentNotesPostData;
  getApiResponse;
  message;
  isQuickNotesValueModified;

  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));
    this.getStudentNotesData();

    this.quickNotesForm = this.formBuilder.group({
      quick_note: ['', Validators.required]
    });

    this.detailNotesForm = this.formBuilder.group({
      detail_note: ['', Validators.required]
    });
  }

  // getters
  get quick_note() { return this.quickNotesForm.get('quick_note'); }
  get detail_note() { return this.detailNotesForm.get('detail_note'); }


  setEditFlag() {
    this.isEditEnabled = !this.isEditEnabled;
  }

  // getNotes
  getStudentNotesData() {
    this.isLoading = true;

    this.getStudentNotesPostData = {
      student_id: this.studentId
    };

    this.dashboardService.getStudentNotes(this.getStudentNotesPostData).subscribe(response => {
      this.isLoading = false;
      // console.log('notes', response);
      // if (response.status == false && response.statusCode == 200) {
      //   this.message = response.message;
      // }
      // else if (response.statusCode == 200 && response.status == true) {
      //   if (response.data.notes.length == 0) {
      //     this.message = 'Notes is not available for this student.';
      //   }
        // else {
          this.getApiResponse = response.data;
          if (this.getApiResponse.hasOwnProperty('quickNotes')) {
            this.quickNotesForm.patchValue({
              quick_note: response.data.quickNotes.notes
            });
          }
        // }
      // }
    });
  }

  // add Detail Note Submit
  detailNotesFormSubmit() {
    this.isDetailNotesFormSubmitted = true;

    if (this.detailNotesForm.invalid) {
      return;
    }
    else {
      const postData = {
        student_id: this.studentId,
        notes: this.detail_note.value
      };

      this.dashboardService.addDetailedNote(postData).subscribe(response => {
        if (response.statusCode == 200 && response.status == true) {
          this.detailNotesForm.reset();
          this.isDetailNotesFormSubmitted = false;
          this.getStudentNotesData();
        }
      });
    }
  }

  // Quick Note Submit
  quickNotesFormSubmit() {
    this.isQuickNotesFormSubmitted = true;

    if (this.quickNotesForm.invalid) {
      return;
    }
    else {
      const postData = {
        student_id: this.studentId,
        notes: this.quick_note.value
      };
      this.dashboardService.addQuickNote(postData).subscribe(response => {
        if (response.statusCode == 200 && response.status == true) {
          this.isEditEnabled = false;
          this.quickNotesForm.reset();
          this.isQuickNotesFormSubmitted = false;
          this.getStudentNotesData();
        }
      });
    }
  }
}
