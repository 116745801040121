export let PARTNER_SUBSCRIPTION_STATUS = [
    {
        "value" : "starter_quarterly",
        "label" : "Starter Quarterly"
    },
    {
        "value"  : "starter_yearly",
        "label" : "Starter Yearly"
    },
    {
        "value"  :  "professional_quarterly",
        "label" : "Professional Quarterly"
    },
    {
        "value"  :  "professional_yearly",
        "label" : "Professional Yearly"
    },
    {
        "value"  :  "agency_quarterly",
        "label" : "Agency Quarterly"
    },
    {
        "value"  :  "agency_yearly",
        "label" : "Agency Yearly"
    },
    {
        "value"  : "manual_subscriptions",
        "label" : "Manual Subscriptions"
    },
    {
        "value"  : "free_trial",
        "label" : "Free Trial"
    },
    {
        "value"  : "trial_expired",
        "label" : "Trial Expired"
    },
    {
        "value"  : "unpaid_invoice",
        "label" : "Unpaid Invoice"
    },
    {
        "value"  : "paid_stopped",
        "label" : "Paid Stopped"
    }
]