import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { environment } from '../../../environments/environment';
import { SuperadminService } from '../../services/superadmin.service';

@Component({
	selector: 'app-admin-branding',
	templateUrl: './admin-branding.component.html',
	styleUrls: ['./admin-branding.component.scss']
})
export class AdminBrandingComponent implements OnInit {

	@Input() public partnerId;
	isLoading = true;

	getPartnerDetailsPostData = {
		partner_id: Number,
		section: '101'
	};

	displayImageUrl = '';
	brandingForm: FormGroup;

	color_1: string;
	color_2: string;

	isLargeImage = false;
	uploadedImageName = '';

	saveBtnLabel = 'Saved';
	isBtnDisabled = true;

	enableCareerLibrary = false;

	show_payment_details = false;
	mail_statistics = false;
	enableProfileBuilder = false;
	enableScholarship = false;
	toggleOnePageSummaryReport = false;
	toggleLanguageOption = false;
	toggleAllowRoadmapEditing = false;
	toggleAllowRoadmapCreation = false;


	constructor(
		private formBuilder: FormBuilder,
		private dashboardService: DashboardService,
		private superAdminService: SuperadminService) { }

	ngOnInit() {
		this.getPartnerDetailsPostData.partner_id = this.partnerId;

		this.color_1 = '#007fb6';
		this.color_2 = '#ea6915';

		this.brandingForm = this.formBuilder.group({
			partner_id: [this.partnerId],
			image: [''],
			report_image: [],
			primary_color: [''],
			secondary_color: [''],
			orgnization: [''],
			og_description: ['', [Validators.required]],
		});

		this.getPartnerData();

		//
		this.brandingForm.valueChanges.subscribe(val => {
			this.saveBtnLabel = 'Save Changes';
			this.isBtnDisabled = false;
		});
	}

	// getters
	get orgnization() { return this.brandingForm.get('orgnization'); }

	getPartnerData() {
		this.superAdminService.getPartnerData(this.getPartnerDetailsPostData).subscribe(response => {
			this.isLoading = false;

			this.color_1 = response.data.primary_color;
			this.color_2 = response.data.secondary_color;

			this.og_description.patchValue(response.data.og_description);
			const showProfileBuilder = response.data.show_profile_builder_option;
			const showScholarship = response.data.show_scholarship_tab_option;
			const showOnePageSummaryReport = response.data.show_one_page_summary_report;
			const showLanguageOption = response.data.is_language_option;
			const showAllowRoadmapEditing = response.data.allow_roadmap_editing;
			const showAllowRoadmapCreation = response.data.allow_roadmap_create_by_student;

			var careerLibraryStatus = response.data.is_careerlibrary_menu_enabled;
			//var roadmapEditingStatus = response.data.allow_roadmap_editing;
			this.show_payment_details = response.data.show_payment_details == 1;
			this.mail_statistics = response.data.mail_statistics == 1;
			this.enableCareerLibrary = (careerLibraryStatus === null || careerLibraryStatus == 0) ? false : true;
			//for enable/disable ProfileBuilder checkbox.
			this.enableProfileBuilder = (showProfileBuilder === null || showProfileBuilder == 0) ? false : true;
			//for enable/disable Scholarship checkbox.
			this.enableScholarship = (showScholarship === null || showScholarship == 0) ? false : true;
			//for enable/disable One PageSummary Report checkbox.
			this.toggleOnePageSummaryReport = (showOnePageSummaryReport == null || showOnePageSummaryReport == 0) ? false : true;
			//for enable/disable Language Option checkbox.
			this.toggleLanguageOption = (showLanguageOption == null || showLanguageOption == 0) ? false : true;

			//for enable/disable Allow Student Roadmap Editing checkbox.
			this.toggleAllowRoadmapEditing = (showAllowRoadmapEditing == null || showAllowRoadmapEditing == 0) ? false : true;

			//for enable/disable Allow Roadmap Creation checkbox.
			this.toggleAllowRoadmapCreation = (showAllowRoadmapCreation == null || showAllowRoadmapCreation == 0) ? false : true;

			if (response.data.image) {
				this.displayImageUrl = environment.logo_baseUrl + this.partnerId + '/' + response.data.image;
				this.brandingForm.patchValue({
					image: response.data.image
				}, { emitEvent: false });
			}
			this.brandingForm.patchValue({
				orgnization: response.data.orgnization
			}, { emitEvent: false });
		});
	}


	uploadImage(event) {
		if (event.target.files[0].size > 500000) {
			this.isLargeImage = true;
		}
		else {
			this.saveBtnLabel = 'Save Changes';

			this.isLargeImage = false;

			const fileName = event.target.files[0].name;
			const newFileName = fileName.replace(/ /g, '-');

			const uploadData = new FormData();
			uploadData.append('image', event.target.files[0], newFileName);
			uploadData.append('partner_id', this.partnerId);
			uploadData.append('bucket', 'partner-logo');

			this.superAdminService.uploadImage(uploadData).subscribe(data => {
				this.uploadedImageName = data.name;
				this.displayImageUrl = environment.logo_baseUrl + this.partnerId + '/' + this.uploadedImageName;
				this.brandingForm.patchValue({
					image: this.uploadedImageName
				});
				this.saveBtnLabel = 'Save Changes';
			});
		}
	}

	brandingFormSubmit() {
		this.saveBtnLabel = 'Saving...';

		this.brandingForm.patchValue({
			primary_color: this.color_1,
			secondary_color: this.color_2,
			report_image: this.brandingForm.get('image').value
		}, { emitEvent: false });

		const payload = {
			is_careerlibrary_menu_enabled: this.enableCareerLibrary ? 1 : 0,
			allow_roadmap_editing: this.toggleAllowRoadmapEditing ? 1 : 0,
			allow_roadmap_create_by_student: this.toggleAllowRoadmapCreation ? 1 : 0,
			show_payment_details: this.show_payment_details ? 1 : 0,
			mail_statistics: this.mail_statistics ? 1 : 0,
			show_profile_builder_option: this.enableProfileBuilder ? 1 : 0,
			show_scholarship_tab_option: this.enableScholarship ? 1 : 0,
			show_one_page_summary_report: this.toggleOnePageSummaryReport ? 1 : 0,
			is_language_option: this.toggleLanguageOption ? 1 : 0,
			...this.brandingForm.value
		}
		this.superAdminService.setBrandingDetails(payload).subscribe(data => {
			if (data.statusCode === 200 && data.status === true) {
				this.saveBtnLabel = 'Saved';
				this.isBtnDisabled = true;
			}
		});
	}


	onChange() {
		this.saveBtnLabel = 'Save Changes';
		this.isBtnDisabled = false;
	}

	get og_description() { return this.brandingForm.get('og_description'); }

}

