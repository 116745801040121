import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-exam-corner',
  templateUrl: './exam-corner.component.html',
  styleUrls: ['./exam-corner.component.scss']
})
export class ExamCornerComponent implements OnInit {

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

  isLoading = true;
  isTableDataLoading = true;

  optionForm: FormGroup;

  filtersData;

  getExamListPostData = {
    filter: '',
    stream_name: '',
    mode: '',
    type: '',   // application status
    recognized: '',
    search: '',
    // location: '',
    offset: 0
  };

  examListApiResponse;
  examListFromApi;

  //
  collegeListApiResponse;
  examName;
  isCollegeFound;

  // Exam bucket
  examBucket = [];
  subscribedExams = [];
  unsubscribedExams = [];
  viewSubscribedExamsList = [];
  viewSubscribedClicked;

  // Pagination
  noOfPages;

  //
  examSearch = new Subject<string>();

  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService) {
    // search
    this.examSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.setFilterAndGetExamList(this.myDummyEvent);
      });
  }


  ngOnInit() {
    this.optionForm = this.formBuilder.group({
      location: ['']
    });

    this.optionForm.get('location').valueChanges.subscribe(optedValue => {
      // this.getExamListPostData.location = optedValue;
      this.getExamListPostData.offset = 0;
      this.getExamList();
    });

    this.getExamListPostData.filter = 'No';

    this.getExamFiltersData();

    this.getExamList();

  }
  isValidDate(date:any) {
    return (
      !!date &&
      moment(date).isValid() &&
      moment(date).year() >= moment().year() &&
      moment(date).isSameOrAfter(moment().subtract(6, 'months'))
    );
  }
  getExamFiltersData() {
    this.dashboardService.getExamFiltersData().subscribe(response => {
      this.isLoading = false;
      if (response.status == true && response.statusCode == 200) {
        this.filtersData = response.data;
      }
    });
  }


  setFilterAndGetExamList(event) {
    this.getExamListPostData.filter = 'Yes';
    this.getExamListPostData.offset = 0;
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(event);
    }
    this.getExamList();
  }


  getExamList() {
    if (this.viewSubscribedClicked == true) {
      this.viewSubscribedClicked = false;
    }
    this.isTableDataLoading = true;

    this.dashboardService.getExamsList(this.getExamListPostData).subscribe(response => {
      this.isTableDataLoading = false;

      if (response.status == true && response.statusCode == 200) {
        this.examListApiResponse = response.data;
        this.examListFromApi = response.data.examList;
        this.noOfPages = response.data.count;

        (response.data.examList).forEach(element => {
          if (element.subscribed != null && this.examBucket.indexOf(element.mep_id) === -1 && this.unsubscribedExams.indexOf(element.mep_id) === -1) {
            this.examBucket.push(element.mep_id);
            this.subscribedExams.push(element.mep_id);
          }
        });
      }
    });
    // this.paginatorRef.changePage(0);
  }


  viewCollegeList(examShortForm) {
    this.examName = examShortForm;

    this.dashboardService.getCollegeListForExam({ short_form: examShortForm }).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        this.isCollegeFound = true;
        this.collegeListApiResponse = response.data;
        $('#collegeListModal').modal('show');
      }
      if (response.status == false && response.statusCode == 200) {
        this.isCollegeFound = false;
        $('#collegeListModal').modal('show');
      }
    });
  }


  addToBucket(examMepId, checkedStatus) {
    if (checkedStatus == false) {
      this.subscribedExams.splice(this.subscribedExams.indexOf(examMepId), 1);
      if (this.unsubscribedExams.indexOf(examMepId) === -1) {
        this.unsubscribedExams.push(examMepId);
      }
      // UI
      this.examBucket.splice(this.examBucket.indexOf(examMepId), 1);
    }
    else {
      if (this.unsubscribedExams.indexOf(examMepId) !== -1) {
        this.unsubscribedExams.splice(this.unsubscribedExams.indexOf(examMepId), 1);
      }
      this.subscribedExams.push(examMepId);
      // UI
      this.examBucket.push(examMepId);
    }
  }


  saveExams() {
    this.dashboardService.addExamsToPartnerBucket({ subscribe: this.subscribedExams, unsubscribe: this.unsubscribedExams }).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        // console.log(response);
      }
    });
    $('#subscribeExams').modal('show');
  }


  viewSubscribedExams() {
    this.viewSubscribedClicked = !this.viewSubscribedClicked;

    if (this.viewSubscribedClicked == true) {
      this.dashboardService.getSubscribedExamsList().subscribe(response => {
        if (response.status == true && response.statusCode == 200) {
          this.examListFromApi = response.data.subscriptionList;
          this.noOfPages = 0;

          (response.data.subscriptionList).forEach(element => {
            if (element.subscribed != null && this.examBucket.indexOf(element.mep_id) === -1 && this.unsubscribedExams.indexOf(element.mep_id) === -1) {
              this.examBucket.push(element.mep_id);
              this.subscribedExams.push(element.mep_id);
            }
          });
        }
      });
    }
    else {
      this.getExamList();
    }
  }


  paginate(event) {
    this.getExamListPostData.filter = 'Yes';
    this.getExamListPostData.offset = (event.first);
    this.getExamList();
  }

  closeSavedModal() {
    $('#subscribeExams').modal('hide');
  }

}
