import { Component, OnInit, Input } from '@angular/core';
import { SuperadminService } from '../../services/superadmin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-admin-subscription',
  templateUrl: './admin-subscription.component.html',
  styleUrls: ['./admin-subscription.component.scss']
})
export class AdminSubscriptionComponent implements OnInit {

	@Input() public partnerId;
	isLoading=false;
	apiResponse: any;
	freeTrials:any;
	subscriptionInfoDetails: boolean = false;
	isActivationSubscriptionEnabled = false;
	activateSubscriptionBtnLabel = "Reactivate"
	disableActivateSubscriptionBtn = undefined;

	constructor(private formBuilder: FormBuilder, private superAdminService: SuperadminService, private messageService: MessageService) { }

	ngOnInit() {
		this.getPartnerSubscriptionDetails();
	}
	getPartnerSubscriptionDetails() {
		const postData = {
			partner_id: this.partnerId
		};
		this.isLoading = true;
		this.superAdminService.getPartnerSubscriptionDetails(postData).subscribe(response => {
		if (response.status === true && response.statusCode === 200) {
			this.apiResponse = response.data;
			if(this.apiResponse) {
				if (this.apiResponse.hasOwnProperty('free_trial')) {
					this.freeTrials = response.data.free_trial;
				} else {
					if (this.apiResponse.subscription_id && this.apiResponse.reactive_subscription == 1) {
						this.isActivationSubscriptionEnabled = true;
					}
				}
			}
			this.subscriptionInfoDetails = true;
			this.isLoading = false;
		}
		if(this.apiResponse==null){
			this.apiResponse={
				package_selected:"",
				start_date: "",
				renew_date:"",
				signed_up_on:""
			}
		}
		});
	}
	
	activateSubscription() {
		if (this.disableActivateSubscriptionBtn) {
			return;
		}
		const postData = {
			partner_id: this.partnerId,
			subscription_id: this.apiResponse.subscription_id
		};
		this.activateSubscriptionBtnLabel = 'Reactivating...';
		this.disableActivateSubscriptionBtn = 'disabled';
		this.superAdminService.reactivateSubscription(postData).subscribe(response => {
			if (response.statusCode === 200 && response.status == true) {
				this.activateSubscriptionBtnLabel = 'Reactivated';
				setTimeout(() => {
					this.isActivationSubscriptionEnabled = false;
				}, 700);
				this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });
				this.getPartnerSubscriptionDetails();
			} else {
				this.activateSubscriptionBtnLabel = 'Reactivate';
				this.disableActivateSubscriptionBtn = '';
				this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
			}
		});
	}


}
