import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.scss']
})
export class PerformanceReportComponent implements OnInit {

  isLoading = false;

  statsData;

  dateRangeFilterForm: FormGroup;

  //
  todayDate = new Date();

  getReportPostData: any = {};

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getReportPostData.filterByCounselor = '';

    this.statsData = [
      { label: 'Students Assigned', count: 32 },
      { label: 'Reports Generated', count: 8 },
      { label: 'Sessions', count: 12 },
      { label: 'Action Plans', count: 45 },
      { label: 'Roadmaps', count: 2 }
    ];


    //
    this.dateRangeFilterForm = this.formBuilder.group({
      fromDate: [new Date(), Validators.required],
      toDate: [new Date(), Validators.required]
    });
  }

  // getter
  get fromDate() { return this.dateRangeFilterForm.get('fromDate'); }
  get toDate() { return this.dateRangeFilterForm.get('toDate'); }


  dateRangeFilterFormSubmit() {

    if (this.dateRangeFilterForm.invalid) {
      return;
    }

    const timeZoneOffset = (this.fromDate.value).getTimezoneOffset() * 60000;

    const formattedFromDate = (new Date(this.fromDate.value - timeZoneOffset)).toISOString().slice(0, -1).substring(0, 10);
    const formattedToDate = (new Date(this.toDate.value - timeZoneOffset)).toISOString().slice(0, -1).substring(0, 10);

    console.log(formattedFromDate);
    console.log(formattedToDate);

    const oneDay = 1000 * 60 * 60 * 24;
    const differenceMs = new Date(formattedToDate).getTime() - new Date(formattedFromDate).getTime();

    const dateDifference = Math.round(differenceMs / oneDay);
    console.log(dateDifference);

    // console.log(new Date(formattedFromDate));

    if (dateDifference >= 0) {
      console.log('call api');
      // Will not work due to format and date-string
      // this.dateRangeFilterForm.patchValue({
      //   fromDate: formattedFromDate,
      //   toDate: formattedToDate
      // });

    }
  }

}
