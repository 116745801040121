import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MessageService } from 'primeng/api';
import jwt_encode from 'jwt-encode';

@Component({
	selector: 'app-tests',
	templateUrl: './tests.component.html',
	styleUrls: ['./tests.component.scss']
})
export class TestsComponent implements OnInit {

	isLoading = true;

	message;

	studentId;
	isSummaryReportGenerate = false;
	testTabsData = [];
	activeTab;

	apiResponse;

	testNameForChart;
	testDataForChart = [];

	studentReportUrl;
	reportPdfName;

	reportApiDomain;

	studentInfo;
	public showOnePageSummaryReport = false;
	public generateSummaryReportUrl = ``;

	constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router, private messageService: MessageService) { }

	ngOnInit() {
		this.getTestResults();
	}

	getTestResults() {
		this.isLoading = true;

		// Accessing parent route params
		this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

		this.dashboardService.getStudentTestResults({ student_id: this.studentId }).subscribe(response => {
			this.isLoading = false;

			// console.log('test--', response);
			if (response.status === false && response.statusCode === 200) {
				this.message = response.message;
			}
			else if (response.status === true && response.statusCode === 200) {
				if (response.data.os_report.length == 0) {
					this.message = 'Assessments not started yet.';
				}
				else {
					this.apiResponse = response.data;
					this.showOnePageSummaryReport = this.apiResponse.show_one_page_summary_report;
					this.generateSummaryReportUrl = this.apiResponse.generate_summary_report_url;

					this.testTabsData = [
						{ name: 'Orientation <br> Style', imgUrl: 'http://mindlerdashboard.imgix.net/test-o.png', value: 'os', enabled: (this.apiResponse.os_report.length > 0) ? true : false },
						{ name: 'Interest', imgUrl: 'http://mindlerdashboard.imgix.net/test-i.png', value: 'interest', enabled: (this.apiResponse.get_i_report.length > 0) ? true : false },
						{ name: 'Personality', imgUrl: 'http://mindlerdashboard.imgix.net/test-p.png', value: 'personality', enabled: (this.apiResponse.get_p_report.length > 0) ? true : false },
						{ name: 'Aptitude', imgUrl: 'http://mindlerdashboard.imgix.net/test-a.png', value: 'aptitude', enabled: (this.apiResponse.get_a_report.length > 0) ? true : false }
					];
					// EQ (not for stream user)
					if (this.apiResponse.basicinfo.package_id != 1) {
						this.testTabsData.push({ name: 'Emotional <br> Quotient', imgUrl: 'http://mindlerdashboard.imgix.net/test-eq.png', value: 'eq', enabled: (this.apiResponse.get_e_report.length > 0) ? true : false });
					}

					this.displayChartForTestResult('os');

					if ((this.apiResponse.basicinfo.domain).indexOf('https://') === -1) {
						this.reportApiDomain = 'https://' + this.apiResponse.basicinfo.domain;
					}else if((this.apiResponse.basicinfo.domain).includes('http://')){
						this.reportApiDomain = 'http://' + this.apiResponse.basicinfo.domain;
				}
					else {
						this.reportApiDomain = this.apiResponse.basicinfo.domain;
					}

					if (this.apiResponse.download_report === 1) {
						if (this.apiResponse.basicinfo.pdf == null) {
							this.generateStudentReport();
						}
						else {
							this.studentReportUrl = environment.student_report_baseUrl + this.apiResponse.basicinfo.uid + '/' + this.apiResponse.basicinfo.pdf;
							this.reportPdfName = this.apiResponse.basicinfo.pdf;
						}
					}
				}
			}
		});
	}

	displayChartForTestResult(testCategory) {
		this.studentInfo = this.dashboardService.getStudentBasicInfo({ student_id: this.studentId }).subscribe(response => {
			if (testCategory === 'os') {
				this.activeTab = 'os';
				this.testNameForChart = 'Orientation Style';
				this.testDataForChart = this.apiResponse.os_report;
			}
			else if (testCategory === 'interest') {
				this.activeTab = 'interest';
				this.testNameForChart = 'Interest';
				this.testDataForChart = this.apiResponse.get_i_report;
			}
			else if (testCategory === 'personality') {
				this.activeTab = 'personality';
				this.testNameForChart = 'Personality';
				this.testDataForChart = this.apiResponse.get_p_report;
			}
			else if (testCategory === 'aptitude') {
				this.activeTab = 'aptitude';
				this.testNameForChart = 'Aptitude';
				this.testDataForChart = this.apiResponse.get_a_report;
			}
			else if (testCategory === 'eq' && response.data.basicinfo.package_id == 2) {
				this.activeTab = 'eq';
				this.testNameForChart = 'Emotional Quotient';
				this.testDataForChart = this.apiResponse.get_e_report;
			}
		})
	}


	generateStudentReport() {
		this.dashboardService.getStudentReport(this.studentId, this.reportApiDomain).subscribe(response => {
			// it generates the pdf and updates in database and also gets uploaded to s3 but do not sends a success response
			// so, handling our next task in error part
		},
			error => {
				if (error.status === 200 && error.statusText === 'OK') {
					this.dashboardService.getStudentBasicInfo({ student_id: this.studentId }).subscribe(response => {
						if (response.status === true && response.statusCode === 200) {
							this.studentReportUrl = environment.student_report_baseUrl + response.data.basicinfo.uid + '/' + response.data.basicinfo.pdf;
							this.reportPdfName = response.data.basicinfo.pdf;
						}
					});
				}
			});
	}



	generateSummaryReport() {
		window.open(this.generateSummaryReportUrl, '_blank');
	}

	viewStudentReport() {
		if (this.reportPdfName) {
			window.open(this.studentReportUrl, '_blank');
		}
	}

}
