import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss']
})
export class MatchesComponent implements OnInit {

  isLoading = true;
  message;
  studentId;

  data: any;
  options: any;

  apiResponse;

  matchNameForChart;
  matchDataForChart = [];
  displayChartMatchId;

  anotherCareerValue = '';

  @ViewChild('matchesChartRef', { static: true }) matchesChartRef: HTMLElement;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.getMatches();
  }

  getMatches() {
    this.isLoading = true;

    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

    this.dashboardService.studentMatches({ student_id: this.studentId }).subscribe(response => {
      this.isLoading = false;

      if (response.status == false && response.statusCode == 200) {
        this.message = response.message;
      }
      else if (response.status == true && response.statusCode == 200) {
        if (response.data.match.length == 0) {
          this.message = 'Assessments not completed yet.';
        }
        else {
          this.apiResponse = response.data;
          this.displayChartForMatch(response.data.match[0]);  // optional parameter not passed
        }
      }
    });
  }

  // 'el' is an optional parameter ('?' is placed to make it optional)
  displayChartForMatch(matchData, el?: HTMLElement) {

    this.displayChartMatchId = matchData.id;

    this.matchNameForChart = matchData.career_domain_name;

    this.matchDataForChart = [];

    this.matchDataForChart.push({ name: 'Orientation Style', score: matchData.osmatch });
    this.matchDataForChart.push({ name: 'Interest', score: matchData.inmatch });
    this.matchDataForChart.push({ name: 'Personality', score: matchData.psmatch });
    this.matchDataForChart.push({ name: 'Aptitude', score: matchData.apmatch });
    if (this.apiResponse.basicinfo.package_id == 2) {
      this.matchDataForChart.push({ name: 'Emotional Quotient', score: matchData.eqmatch });
    }

    if (el) {
      el.scrollIntoView();    // scroll to chart on careerName click in table (see effect on mobile)
    }
  }

  addAnotherCareer() {
    const extraMatch = this.apiResponse.another_careers.find(element => element.id == this.anotherCareerValue);
    this.apiResponse.match[5] = extraMatch;
  }

}
