import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatherModule } from 'angular-feather';
// tslint:disable-next-line: max-line-length
import {
  CheckSquare, Mail, PhoneCall, Compass, Eye, EyeOff, PlusCircle,
  XCircle, ChevronDown, ChevronUp, Menu, ChevronLeft,
  Layout, User, Briefcase, Monitor, Users, Calendar, Grid, BookOpen, LifeBuoy, Key, LogOut, FileText,
  ExternalLink, Edit, Copy, Trash2, UserPlus, UserMinus, Lock, X, Paperclip, Check,Settings,Download
} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  CheckSquare,
  Mail,
  PhoneCall,
  Compass,
  Eye,
  EyeOff,
  PlusCircle,
  XCircle,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  Menu,
  Layout,
  User,
  Briefcase,
  Monitor,
  Users,
  Calendar,
  Grid,
  BookOpen,
  LifeBuoy,
  Key,
  LogOut,
  FileText,
  ExternalLink,
  Edit,
  Copy,
  Trash2,
  UserPlus,
  UserMinus,
  Lock,
  X,
  Paperclip,
  Check,
  Settings,
  Download
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
