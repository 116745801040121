import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  contactForm: FormGroup;
  isContactFormSubmitted: boolean;

  feedbackMessage;
  isSuccess;

  actionBtnLabel = "Submit";

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      message: ["", Validators.required],
    });
  }

  // getters
  get message() {
    return this.contactForm.get("message");
  }

  contactFormSubmit() {
    this.isContactFormSubmitted = true;

    this.message.setValue(this.contactForm.value.message.trim());

    if (this.contactForm.invalid) {
      return;
    }

    this.actionBtnLabel = "Submit...";

    this.dashboardService.submitMessage(this.contactForm.value).subscribe(
      (response) => {
        if (response.status === true && response.statusCode === 200) {
          this.actionBtnLabel = "Submitted";

          this.isContactFormSubmitted = false;
          this.contactForm.reset();

          this.isSuccess = true;
          this.feedbackMessage = "Message sent successfully.";

          setTimeout(() => {
            this.feedbackMessage = null;
            this.actionBtnLabel = "Submit";
          }, 3000);
        }
      },
      (error) => {
        this.isSuccess = false;
        this.feedbackMessage = "Some error occurred, Please try again.";
        setTimeout(() => {
          this.feedbackMessage = null;
          this.actionBtnLabel = "Submit";
        }, 3000);
      }
    );
  }
}
