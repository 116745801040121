import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { MessageService } from 'primeng/api';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as _ from 'lodash';
declare var $: any;
@Component({
	selector: 'app-student-list',
	templateUrl: './student-list.component.html',
	styleUrls: ['./student-list.component.scss']
})
export class StudentListComponent implements OnInit {

	@ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;

	loggedInUserDetails;
	isLoading = true;

	private _subscriptions$ = new SubSink();
	isShowProfileBuilderTab: boolean;

	optionForm: FormGroup;
	scheduleSessionForm: FormGroup;
	isBuyMoreVipButtonClicked = false;
	getStudentListPostData: any = {};

	studentList;
	partnerServiceList;
	partnerServiceListOne; 
	partnerServiceListTwo;
	counselorList;
	studentListForCsvDownload;
	updatedAddedProduct = [];
	updatedRemovedProduct = [];
	updatedSubProduct = [];
	isPartner;
	isUserTouchedVirtualServiceSecond = {};

	// Pagination
	noOfPages;
	pageNumber;
	arr = Array; // Array type captured in a variable

	// Sort
	sortBy;

	// schedule session
	minDate = new Date();
	maxDate = new Date();

	// add note
	quickNotesForm: FormGroup;
	isQuickNotesFormSubmitted: boolean;
	studentId;

	// Assign
	assignButtonText = 'Assign';
	assignButtonIndex;
	assignCounsellorPostData: any = {};

	//Reset Password
	resetPasswordForm: FormGroup;
	isResetPasswordFormSubmitted: boolean;

	//
	studentSearch = new Subject<string>();

	myDummyEvent = new Event('dummyEvent', { bubbles: true });
	currentRemainingVip = 0;
	expandAllAccordion: any;
	defaultExpend = false;
	vipValue = 0; // Initial value
	ishideShow = false;
	expandCollapse = "Expand All"
	isupChevron = {};
	totalRemainingVip = 0;
	isVirtualTrue: boolean = false;
	unlockServices;
	pamAccess;
	negativeValueError = false;
	studentListBkp = [];
	remain = {};
	couponRemain = {};
	isShow = {};
	fieldTouched = {};
	constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService,
		private elementRef: ElementRef, private renderer: Renderer2, private messageService: MessageService, private windowRef: WindowRefService
	) {
		// search
		this.studentSearch.pipe(
			debounceTime(400),
			distinctUntilChanged())
			.subscribe(value => {
				this.setFiltersAndGetList(this.myDummyEvent);
			});
	}

	ngOnInit() {

		this.getStudentListPostData.filterByCounselor = '';
		this.getStudentListPostData.filterByTestStatus = '';
		this.getStudentListPostData.filterByProfileStatus = '';
		this.getStudentListPostData.filterByUserType = '';
		this.getStudentListPostData.filterByProfileBuilderStatus = '';
		this.getStudentListPostData.offset = '';
		this.getStudentListPostData.search = '';
		this.getStudentListPostData.sortBy = 'order by u.created_on desc';

		this.loggedInUserDetails = this.dashboardService.decodeToken();

		if (this.loggedInUserDetails.partner_id !== null && this.loggedInUserDetails.role === null) {
			this.getStudentListPostData.filterByCounselor = '';
			this.isPartner = true;   // Partner
		}
		else if (this.loggedInUserDetails.counselor_id != null) {
			if (this.loggedInUserDetails.role === 1) {
				this.getStudentListPostData.filterByCounselor = '';
				this.isPartner = true;   // Admin
			}
			if (this.loggedInUserDetails.role === 2) {
				this.getStudentListPostData.filterByCounselor = this.loggedInUserDetails.counselor_id;
				this.isPartner = false;   // Counsellor
			}
		}

		this.optionForm = this.formBuilder.group({
			filterByCounselor: ['']
		});

		// schedule session
		this.scheduleSessionForm = this.formBuilder.group({
			session_date: [''],
			session_time: [''],
			session_medium: ['']
		});

		// add notes form
		this.quickNotesForm = this.formBuilder.group({
			quick_note: ['', Validators.required]
		});

		// Reset Password
		this.resetPasswordForm = this.formBuilder.group({
			password: ['', Validators.required]
		});

		this.getStudentsList();

		this.optionForm.get('filterByCounselor').valueChanges.subscribe(optedValue => {
			this.getStudentListPostData.filterByCounselor = optedValue;
			this.getStudentListPostData.offset = '';
			this.getStudentsList();
		});

		// schedule session
		this.minDate.setDate(this.minDate.getDate() + 2);
		this.maxDate.setDate(this.minDate.getDate() + 12);

		this._getWhileLabelOption();

	}

	// schedule session
	// getters (scheduleSessionForm)
	get session_date() { return this.scheduleSessionForm.get('session_date'); }
	get session_time() { return this.scheduleSessionForm.get('session_time'); }
	get session_medium() { return this.scheduleSessionForm.get('session_medium'); }

	// getters
	get quick_note() { return this.quickNotesForm.get('quick_note'); }
	get password() { return this.resetPasswordForm.get('password'); }

	private _getWhileLabelOption() {

		this._subscriptions$.add(
			this.dashboardService.getWhiteLabellingOptions().subscribe((response) => {
				if (response.status === true && response.statusCode === 200) {
					if (response.data.options.show_profile_builder) {
						this.isShowProfileBuilderTab = true;
					} else {
						this.isShowProfileBuilderTab = false;
					}
				}
			})
		)

	}


	setFiltersAndGetList(event) {
		this.getStudentListPostData.offset = 0;
		if (this.paginatorRef) {
			this.paginatorRef.changePageToFirst(event);
		}
		this.getStudentsList();
	}

	setFiltersByAssessment(event) {

		this.getStudentListPostData.offset = 0;
		if (this.paginatorRef) {
			this.paginatorRef.changePageToFirst(event);
		}
		this.getStudentsList();

	}

	getCounselloerUnlock(studentData) {
		let student = _.cloneDeep(studentData)
		let unlockServices = "";
		let a = [...student.student_access.serviceArray1]

		// let b = ([...student.student_access.serviceArray1].map((d) => {
		// 	d.sub_product = d.sub_product.map((r) => {
		// 		r.sub_product_name = r.sub_product_name + " Assesment"
		// 		return r
		// 	})
		// 	return d.sub_product
		// }));
		// if (b.length) {
		// 	b = b[0]
		// }
		let counsellor = [...a, ...student.student_access.serviceArray2]
		let counsellorStudentStatusOne = counsellor.filter((r) => {
			return (r.student_status == 1)
		})
		if (counsellorStudentStatusOne.length) {
			unlockServices = counsellorStudentStatusOne.map(d => (d.product_name || d.sub_product_name)).join(",");
		}
		return unlockServices;
	}

	getStudentsList() {
		this.isLoading = true;
		let studentListResponse;

		if (this.getStudentListPostData.filterByCounselor == this.loggedInUserDetails.partner_id) {
			this.getStudentListPostData.filterByPartner = this.getStudentListPostData.filterByCounselor;
			delete this.getStudentListPostData.filterByCounselor;
		}
		else {
			if ((this.getStudentListPostData).hasOwnProperty('filterByPartner')) {
				delete this.getStudentListPostData.filterByPartner;
			}
		}

		this._subscriptions$.add(this.dashboardService.getStudentList(this.getStudentListPostData).subscribe(response => {
			this.studentList = [];
			this.updatedAddedProduct = [];
			this.updatedRemovedProduct = [];
			this.updatedSubProduct = [];
			if (response.data != null) {
				this.counselorList = response.data.counselorList;
				this.noOfPages = response.data.count;
				this.studentList = response.data.studentList;
				this.pamAccess = response.data.pam_access

				this.studentListBkp = _.cloneDeep(this.studentList);

				for (let index in this.studentList) {
					for (let data of this.studentList[index].student_access.serviceArray2) {
						if (data.product_name === 'Virtual Internship') {
							this.remain[index] = 0;
						}
					}
					for (let data of this.studentList[index].student_access.serviceArray1) {
						if (data.product_name === 'Assessment') {
							this.couponRemain[index] = data.remaining;
						}
					}

				}
			}
			else {
				this.studentList = null;
			}
			this.isLoading = false;

		}));

	}

	getPreActiveByIndex(parentIndex, type = 'PREACTIVE') {
		let preActive = [];
		let subPreActive = []
		for (let s1 of this.studentListBkp[parentIndex].student_access.serviceArray1) {

			if (s1.student_status === 1) {
				preActive.push(s1.id);
			}
			if (s1.product_name === "Assessment") {
				// if need sub product in api
				for (let sub of s1.sub_product) {
					if (sub.sub_product_status === 1) {
						subPreActive.push(sub.sub_product_id);
					}
				}
			}

		}

		for (let s2 of this.studentListBkp[parentIndex].student_access.serviceArray2) {
			if (s2.student_status === 1) {
				preActive.push(s2.id);
			}
		}
		if (type === "SUB_PRE") {
			return subPreActive;
		}
		return preActive;
	}

	getServiceFormData(parentIndex) {
		let preActive = this.getPreActiveByIndex(parentIndex);
		let subPreActive = this.getPreActiveByIndex(parentIndex, "SUB_PRE");
		for (let s1 of this.studentList[parentIndex].student_access.serviceArray1) {

			if (s1.student_status === 1) {
				if (preActive.includes(s1.id)) {
					//nothing
				} else {
					this.updatedAddedProduct.push(s1.id);
				}
			} else {
				if (preActive.includes(s1.id)) {
					this.updatedRemovedProduct.push(s1.id);
				} else {
					// nothing
				}
			}
			if (s1.product_name === "Assessment") {
				for (let sub of s1.sub_product) {
					// if need sub product in api
					if (sub.sub_product_status === 1) {
						if (subPreActive.includes(sub.sub_product_id)) {
							// 	//nothing
						} else {
							this.updatedSubProduct.push(sub.sub_product_id);
						}
					}
					else {
						if (subPreActive.includes(sub.sub_product_id)) {
							this.updatedSubProduct.push(sub.sub_product_id);
						} else {
							// nothing
						}
					}
				}
			}

		}

		for (let s2 of this.studentList[parentIndex].student_access.serviceArray2) {
			if (s2.student_status === 1) {
				if (preActive.includes(s2.id)) {
					//nothing
				} else {
					this.updatedAddedProduct.push(s2.id);
				}
			} else {
				if (preActive.includes(s2.id)) {
					this.updatedRemovedProduct.push(s2.id);
				} else {
					// nothing
				}
			}
		}

	}

	submitAccess(student, parentIndex) {
		this.updatedAddedProduct = [];
		this.updatedRemovedProduct = [];
		this.updatedSubProduct = [];

		this.getServiceFormData(parentIndex);
		if (this.remain[parentIndex] > 0) {
			for (let s2 of this.studentList[parentIndex].student_access.serviceArray2) {
				if (s2.product_name == "Virtual Internship") {
					if (!this.updatedAddedProduct.includes(6)) {
						this.updatedAddedProduct.push(s2.id);
					}
				}
			}
		}
		if (this.updatedSubProduct.includes(1) || this.updatedSubProduct.includes(2) || this.updatedSubProduct.includes(3)) {
			if (!this.updatedAddedProduct.includes(1)) {
				this.updatedAddedProduct.push(1)
			}
		}


		// if (!this.remain[parentIndex]) {
		// 	let returnFlag = false;
		// 	for (let s2 of this.studentList[parentIndex].student_access.serviceArray2) {
		// 		if (s2.product_name == "Virtual Internship" && s2.student_status == 1) {
		// 			returnFlag = true;
		// 		}
		// 	}
		// 	if (returnFlag) {
		// 		this.messageService.add({
		// 			key: 'topright',
		// 			severity: 'warn',
		// 			detail: "Vip Count Should be atleast 1",
		// 		});
		// 		return;
		// 	}
		// }

		let reqObj: any = {
			"student_id": student.uid,
			"product_added": this.updatedAddedProduct,
			"product_disabled": this.updatedRemovedProduct,
			"vip_count": this.remain[parentIndex],
			"sub_product_added": this.updatedSubProduct
		}
		if (this.updatedAddedProduct.length == 0 && this.updatedRemovedProduct.length == 0 && this.updatedSubProduct.length == 0 && !(this.remain[parentIndex])) {
			this.messageService.add({
				key: 'topright',
				severity: 'warn',
				detail: "Please select or modify Some services before submit.",
			});

			return
		}
		else {
			if (!this.couponRemain[parentIndex] && this.updatedAddedProduct.includes(1)) {
				this.messageService.add({
					key: 'topright',
					severity: 'warn',
					detail: 'You have not enough coupons to provide',
				});
				return
			}
			if (!this.remain[parentIndex] && this.updatedAddedProduct.includes(6) ) {
				this.messageService.add({
					key: 'topright',
					severity: 'warn',
					detail: 'VIP should not be Zero',
				});
				return 
			}
			if ((this.remain[parentIndex] && this.updatedAddedProduct.includes(6)) && (!this.couponRemain[parentIndex] && this.updatedAddedProduct.includes(1)) ) {
				this.messageService.add({
					key: 'topright',
					severity: 'warn',
					detail: 'Coupon should not be Zero',
				});
				return
			}
			if ((!this.remain[parentIndex] && this.updatedAddedProduct.includes(6)) && (this.couponRemain[parentIndex] && this.updatedAddedProduct.includes(1)) ) {
				this.messageService.add({
					key: 'topright',
					severity: 'warn',
					detail: 'VIP should not be Zero',
				});
				return
			}
			this.dashboardService.updateStudentServices(reqObj).subscribe(response => {
				if (response.status == true && response.statusCode == 200) {
					this.isUserTouchedVirtualServiceSecond = {}
					this.messageService.add({
						key: 'topright',
						severity: 'success',
						detail: response.message,
					});
					this.isVirtualTrue = true
					this.remain[parentIndex] = 0;
					this.getStudentsList();
				}
			})
		}
	}

	//buy vip function
	buyMoreVipAction() {
		this.isBuyMoreVipButtonClicked = true;
	}
	closex() {
		this.isBuyMoreVipButtonClicked = false;
	}


	// Download CSV
	downloadCsv() {
		const fileName = 'student-list';

		this.getStudentListPostData['download'] = 1;

		this.dashboardService.getStudentList(this.getStudentListPostData).subscribe(response => {
			if (response.data != null) {
				this.studentListForCsvDownload = response.data.studentList;
				// Adding product_name column to each student's data
				// this.studentListForCsvDownload.forEach(student => {
				// 	const productNames = [];
				// 	for (const serviceArray1 in student.student_access) {
				// 		if (student.student_access.hasOwnProperty(serviceArray1)) {
				// 			student.student_access[serviceArray1].forEach(service => {
				// 				if (service.student_status === 1) {
				// 					productNames.push(service.product_name);
				// 				}
				// 			});
				// 		}
				// 	}
				// 	student['product_name'] = productNames.join(', ');
				// });

				this.studentListForCsvDownload = this.studentListForCsvDownload.map((student: any) => {
					student.student_access = [...student.student_access.serviceArray1, ...student.student_access.serviceArray2].filter((access: any) => {
						return access.student_status == 1 && access.partner_status == 1;
					}).map((d1) => d1.product_name).join(" , ");
					return student;
				})
				//Removing unneccesary fields for end user 
				this.studentListForCsvDownload.forEach(student => {
					delete student['assigned_counselor_id'];
					delete student['uid'];
					delete student['partner_id'];
					delete student['package_id'];
				});
				this.dashboardService.exportToCsvService(fileName, this.studentListForCsvDownload);
			}
			else {
				this.studentListForCsvDownload = null;
			}
		});
	}

	paginate(event) {
		this.getStudentListPostData.offset = (event.first);
		this.getStudentsList();
	}

	// schedule session
	scheduleSessionFormSubmit() { }


	// Assign
	assignCounselor(studentId, counselorId, index) {
		this.assignButtonIndex = index;

		this.assignCounsellorPostData.student_id = studentId;
		this.assignCounsellorPostData.counselor_id = counselorId;

		if (counselorId === this.loggedInUserDetails.partner_id) {
			delete this.assignCounsellorPostData.counselor_id;
		}

		this.dashboardService.assignStudentToCounselor(this.assignCounsellorPostData).subscribe(response => {
			if (response.statusCode === 200 && response.status === true) {
				this.assignButtonText = 'Assigned';
				this.updateAssignBtnText();
			}
		});
	}

	updateAssignBtnText() {
		setTimeout(() => {
			this.assignButtonText = 'Assign';
		}, 5000);
	}


	setStudentIdAddNote(studentId) {
		this.studentId = studentId;

		this.dashboardService.getStudentNotes({ student_id: this.studentId }).subscribe(response => {
			if (response.statusCode === 200 && response.status === true) {
				if (response.data.hasOwnProperty('quickNotes')) {
					this.quickNotesForm.patchValue({
						quick_note: response.data.quickNotes.notes
					});
				}
				else {
					this.quickNotesForm.reset();
				}
			}
		});
	}

	// Notes Submit
	quickNotesFormSubmit() {
		this.isQuickNotesFormSubmitted = true;

		if (this.quickNotesForm.invalid) {
			return;
		}
		else {
			const postData = {
				student_id: this.studentId,
				notes: this.quick_note.value
			};
			this.dashboardService.addQuickNote(postData).subscribe(response => {
				if (response.statusCode == 200 && response.status == true) {
					this.quickNotesForm.reset();
					this.isQuickNotesFormSubmitted = false;
					$('#addNoteModal').modal('hide');
				}
			});
		}
	}

	resetPasswordInputField(studentId) {
		this.studentId = studentId;
		this.resetPasswordForm.reset();
	}

	//expand all toggle button
	expandAll() {
		this.expandAllAccordion = $('[id^=collapse_]');
		let i = 0;
		for (let list of this.expandAllAccordion) {
			if (!this.defaultExpend) {
				list.classList.add('show');
				this.isupChevron[i] = true;
			} else {
				list.classList.remove('show');
				this.isupChevron[i] = false;
			}
			i++;
		}

		this.defaultExpend = !this.defaultExpend;
		this.ishideShow = !this.ishideShow;
		if (this.expandCollapse == "Expand All") {
			this.expandCollapse = "Collapse All"
		} else if (this.expandCollapse == "Collapse All") {
			this.expandCollapse = "Expand All"
		}
	}

	noChange(event: MouseEvent, i) {
		event.stopPropagation(); // Prevent the click event from propagating to the parent elements
		// this.studentList[i].isShow = !this.studentList[i].isShow;
	}

	changeClassAndHideShow(event, i) {
		this.studentList[i].isShow = !this.studentList[i].isShow;
		this.isupChevron[i] = !this.isupChevron[i];
		event.preventDefault();
	}

	resetPasswordFormSubmit() {
		this.isResetPasswordFormSubmitted = true;

		if (this.resetPasswordForm.invalid) {
			return;
		}
		else {
			const postData = {
				student_id: this.studentId,
				password: this.password.value
			};
			console.log(postData)
			this.dashboardService.resetStudentPassword(postData).subscribe(response => {
				if (response.statusCode == 200 && response.status == true) {
					this.resetPasswordForm.reset();
					this.isResetPasswordFormSubmitted = false;
					$('#resetPasswordModal').modal('hide');
				}
			});
		}
	}


	servicesOneChange(event, i, ci, productName) {
		let isChecked = event.target.checked;
		if (productName == "Assessment") {
			if (isChecked) {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 1
			} else {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 0;
				this.studentList[i]['student_access']['serviceArray1'][ci]['sub_product'] = this.studentList[i]['student_access']['serviceArray1'][ci]['sub_product'].map((d) => {
					d.sub_product_status = 0;
					return d;
				})
				this.studentList[i]['student_access']['serviceArray1'] = this.studentList[i]['student_access']['serviceArray1'].map((d, index) => {
					if (index == ci) {
						return d
					} else {
						d.student_status = 0;
						return d;
					}
				})

			}

		} else if (productName == "Activities") {
			if (isChecked) {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 1
			} else {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 0

				// this.studentList[i]['student_access']['serviceArray1'] = this.studentList[i]['student_access']['serviceArray1'].map((d) => {
				// 	if (d.product_name === "Action Plan") {
				// 		d.student_status = 0
				// 	}
				// 	return d
				// })
			}

		} else if (productName == "Action Plan") {
			if (isChecked) {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 1
			} else {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 0
			}
		} else if (productName == "Report & Matches") {
			if (isChecked) {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 1
			} else {
				this.studentList[i]['student_access']['serviceArray1'][ci]['student_status'] = 0
			}
		}
	}



	onRadioChange(event, i, ci, si) {
		let isChecked = event.target.checked;
		this.fieldTouched[`${i}_${ci}`] = true;
		this.studentList[i]['student_access']['serviceArray1'][ci]['sub_product'] = this.studentList[i]['student_access']['serviceArray1'][ci]['sub_product'].map((d, index) => {

			if (isChecked && (index === si)) {
				d.sub_product_status = 1;
			} else {
				d.sub_product_status = 0;
			}
			return d;
		})
	}

	getSubCategoryEnableDisabled(i, ci, si) {
		if (this.studentList[i]['student_access']['serviceArray1'][ci]['product_name'] == "Assessment") {
			if (this.studentList[i]['student_access']['serviceArray1'][ci]['student_status']) {
				//on case of assesment
				let list = this.studentList[i]['student_access']['serviceArray1'][ci]['sub_product'].filter((data) => data.sub_product_status);
				if (list.length) {
					if (this.fieldTouched.hasOwnProperty(`${i}_${ci}`) && this.fieldTouched[`${i}_${ci}`]) {
						return false
					} else {
						return true
					}

				}
				return false

			} else {
				return true;
			}
		}
	}

	getServicesActivitiesPlanReporMatchesEnableDisabled(i, ci) {
		let disable = true;
		if (this.studentList[i]['student_access']['serviceArray1'][ci]['product_name'] == "Activities") {

			for (let child of this.studentList[i]['student_access']['serviceArray1']) {
				if (child.product_name === 'Assessment') {
					for (let subChild of child.sub_product) {
						if (subChild.sub_product_status === 1) {

							disable = false;
						}
					}
				}

			}
			return disable;
		} else if (this.studentList[i]['student_access']['serviceArray1'][ci]['product_name'] == "Action Plan") {

			// for (let child of this.studentList[i]['student_access']['serviceArray1']) {

			// 	if (child.product_name == 'Activities' && child.student_status == 1) {
			// 		disable = false
			// 	}
			// }
			// return disable
			for (let child of this.studentList[i]['student_access']['serviceArray1']) {
				if (child.product_name === 'Assessment') {
					for (let subChild of child.sub_product) {
						if (subChild.sub_product_status === 1) {

							disable = false;
						}
					}
				}

			}
			return disable;
		} else if (this.studentList[i]['student_access']['serviceArray1'][ci]['product_name'] == "Report & Matches") {

			for (let child of this.studentList[i]['student_access']['serviceArray1']) {
				if (child.product_name === 'Assessment') {
					for (let subChild of child.sub_product) {
						if (subChild.sub_product_status === 1) {
							disable = false;
						}
					}
				}

			}
			return disable;
		}
		//  else if (this.studentList[i]['student_access']['serviceArray1'][ci]['product_name'] == "Report") {

		// 	for (let child of this.studentList[i]['student_access']['serviceArray1']) {
		// 		if (child.product_name === 'Assessment') {
		// 			for (let subChild of child.sub_product) {
		// 				if (subChild.sub_product_status === 1) {
		// 					disable = false;
		// 				}
		// 			}
		// 		}

		// 	}
		// 	return disable;
		// }

	}

	onIncrement(parentIndex) {
		if (this.remain[parentIndex] >= this.getMaxRemain(parentIndex)) {
			this.messageService.add({
				key: 'topright',
				severity: 'warn',
				detail: "Limit exceeds",
			});
			return
		}
		this.remain[parentIndex] = this.remain[parentIndex] + 1;
	}

	onDecrement(parentIndex) {
		if (this.remain[parentIndex] == 0) {
			this.messageService.add({
				key: 'topright',
				severity: 'warn',
				detail: "Vip Count should not be negative",
			});
			return;
		}
		this.remain[parentIndex] = this.remain[parentIndex] - 1;
	}

	onInput(event, parentIndex) {
		let value = event.target.value;
		if (value < 0) {
			this.remain[parentIndex] = 0;
		}
		let max = this.getMaxRemain(parentIndex);
		if (value >= max) {
			this.remain[parentIndex] = max;
			this.messageService.add({
				key: 'topright',
				severity: 'warn',
				detail: "Limit exceeds",
			});
		}
	}

	validateCouponInput(inputElement: HTMLInputElement) {
		const inputValue = inputElement.value;
		const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

		inputElement.value = sanitizedValue;

		const numericValue = parseInt(sanitizedValue, 10);
		this.negativeValueError = numericValue < 0;
	}

	getMaxRemain(parentIndex) {

		let max = 0;
		for (let s2 of this.studentList[parentIndex]['student_access']['serviceArray2']) {
			if (s2.product_name === 'Virtual Internship') {
				max = s2.remaining
			}
		}
		return max;
	}

	getRemainingVI(parentIndex) {
		let str = '0/0 remaining';
		for (let s2 of this.studentList[parentIndex]['student_access']['serviceArray2']) {
			if (s2.product_name === 'Virtual Internship') {
				str = `${this.getMaxRemain(parentIndex) - this.remain[parentIndex]}/${s2.remaining} remaining`
			}
		}
		return str;
	}

	isVirtualInterShipAvailable(service2Array, parentIndex) {
		let exist = service2Array.filter(d => d.product_name === 'Virtual Internship');
		if (exist.length) {
			if (!exist[0].student_status) {
				this.remain[parentIndex] = 0;
				return false

			}
			else {
				return true
			}
		}
		this.remain[parentIndex] = 0;
		return false
	}
	isVirtualInterShipParentAvailable(service2Array, parentIndex) {
		let exist = service2Array.filter(d => d.product_name === 'Virtual Internship');
		if (exist.length) {
			if (!exist[0].partner_status) {
				return false
			}
			else {
				return true
			}
		}
		return false
	}


	isBuyVipBtnShowHide(service2Array) {
		let exist = service2Array.filter(d => d.product_name === 'Virtual Internship');
		if (exist.length) {
			if (!exist[0].remaining) {
				return true
			}
			else {
				return false
			}
		}
		return true
	}
	isSaveChangesBtnShowHide(service2Array) {
		let exist = service2Array.filter(d => d.product_name === 'Virtual Internship');
		if (exist.length) {
			if ((exist[0].remaining)) {
				return true
			}
			else {
				return false
			}
		}
		return false
	}


	//services checkbox changes
	onServiceVirtualProductChange(event, service, pi) {
		if (event.target.checked) {
			this.isUserTouchedVirtualServiceSecond[pi] = true;
			service.student_status = 1;
		} else {
			this.isUserTouchedVirtualServiceSecond[pi] = false;
			service.student_status = 0;
		}

	}

	getVirtualDisabled(list, parentIndex) {
		if (list.product_name == 'Virtual Internship') {
			if (this.isUserTouchedVirtualServiceSecond.hasOwnProperty(parentIndex) && this.isUserTouchedVirtualServiceSecond[parentIndex]) {
				return false;
			} else if (!this.isUserTouchedVirtualServiceSecond.hasOwnProperty(parentIndex) && list.student_status == 1) {
				return true;
			}
			return false;
		}
		return false;
	}


	ngOnDestroy() {
		this._subscriptions$ && this._subscriptions$.unsubscribe();
	}

}
