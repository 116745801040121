import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-abroad-preferences',
  templateUrl: './abroad-preferences.component.html',
  styleUrls: ['./abroad-preferences.component.scss']
})
export class AbroadPreferencesComponent implements OnInit {

  isLoading = false;

  preferencesForm: FormGroup;
  isPreferencesFormSubmitted: boolean;

  isExtraFieldAdded = false;
  isDropdownEnabled: boolean[]=[];

  results: string[];
  domains: [];
  filteredDomains: any[] = [];
  postData = {
    domains: []
  };

  isInvalidDomainValue;
  feedbackMessage;

  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) { 
  }

  ngOnInit() {
    this.domains = [];

    this.preferencesForm = this.formBuilder.group({
      target_career: ['', Validators.required],
      backup_career: ['']
    });

    if(localStorage.getItem('fromSavedRoadmap')!=null){
      this.getCurrentRoadmapPreferences();
    }
    else if(localStorage.getItem('roadmap_id')!=null){
      this.getCurrentRoadmapPreferences();
    }
    else if(localStorage.getItem('student_id')!=null){
      this.getCurrentRoadmapPreferences();
    }
    else {
      localStorage.removeItem('fromSavedRoadmap');
      localStorage.removeItem('target_career');
      localStorage.removeItem('backup_career');
      localStorage.removeItem('backup_career_2');
    }

    for(var i=0;i<3;i++){
      this.isDropdownEnabled[i]= false;
    }


    this.getCareerDomain();

  }

  // getters
  get target_career() { return this.preferencesForm.get('target_career'); }
  get backup_career() { return this.preferencesForm.get('backup_career'); }

  // Back-up career 2 field actions
  anotherCareerField(action) {
    if (action === 'add') {
      // this.preferencesForm.addControl('backup_career_2', this.formBuilder.control('', Validators.required));
      this.preferencesForm.addControl('backup_career_2', this.formBuilder.control(''));
      this.isExtraFieldAdded = true;

      this.backup_career.setValidators(Validators.required);
      this.backup_career.updateValueAndValidity();
    }
    else if (action === 'remove') {
      this.preferencesForm.removeControl('backup_career_2');
      this.isExtraFieldAdded = false;

      this.backup_career.clearValidators();
      this.backup_career.updateValueAndValidity();
    }
  }

  // career domains list for auto-complete
  getCareerDomain() {
    this.dashboardService.getAbroadCareerDomain(this.postData).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.domains = response.data.domains;
        this.filteredDomains = this.filterData(null,this.domains);
      }
    });
  }

  filterDomain(event) {
    const query = event.query;
    this.filteredDomains = this.filterData(query, this.domains);
  }

  filterData(query, domains: any[]): any[] {
    const filtered: any[] = [];

    domains.forEach(singleDomain => {
      const domain = singleDomain.career_domain_name;
      if (query==null || domain.toLowerCase().indexOf(query.toLowerCase()) != -1) {
        if (domain != this.target_career.value && domain != this.backup_career.value) {
          if (this.preferencesForm.contains('backup_career_2') && domain != this.preferencesForm.get('backup_career_2').value) {
            filtered.push(domain);
          }
          else if (!this.preferencesForm.contains('backup_career_2')) {
            filtered.push(domain);
          }
        }
      }
    });
    return filtered;
  }


  preferencesFormSubmit() {
    this.isPreferencesFormSubmitted = true;

    if (this.preferencesForm.invalid) {
      return;
    }

    // check if user entered domain is valid, means present in our domains list
    for (const inputDomainValue of Object.keys(this.preferencesForm.value)) {
      if ((this.preferencesForm.value)[inputDomainValue] != null && (this.preferencesForm.value)[inputDomainValue] !== '') {
        const valueMatch = { career_domain_name: (this.preferencesForm.value)[inputDomainValue] };

        // contains 'undefined' if user entered career name does not matches our domain list
        this.isInvalidDomainValue = this.domains.find((domain) => (JSON.stringify(domain) === JSON.stringify(valueMatch)));
        if (typeof this.isInvalidDomainValue === 'undefined') {
          this.feedbackMessage = '*Please enter a valid career name from the list';
          return false;
        }
      }
    }

    this.dashboardService.saveAbroadPreferences(this.preferencesForm.value).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        localStorage.setItem('target_career', response.data.target_career);
        localStorage.setItem('backup_career', response.data.backup_career);
        localStorage.setItem('backup_career_2', response.data.backup_career_2);

        if (response.data.target_career) {
          this.dashboardService.setRoadmapTabFlag('enableCollegeTab');
        }

        this.router.navigate(['../college-map'], { relativeTo: this.activatedRoute });
      }
    });
  }

  // Edit Operation [Pre-fill values]
  getCurrentRoadmapPreferences() {

    this.dashboardService.getAbroadCurrentRoadmapPreferences().subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        // if target_career is present, then enable the tab (college-map)
        if (response.data.roadmapDetails[0].target_career) {
          this.dashboardService.setRoadmapTabFlag('enableCollegeTab');
        }


        localStorage.setItem('target_career', response.data.roadmapDetails[0].target_career);
        localStorage.setItem('backup_career', response.data.roadmapDetails[0].backup_career);
        localStorage.setItem('backup_career_2', response.data.roadmapDetails[0].backup_career_2);

        this.preferencesForm.patchValue({
          target_career: response.data.roadmapDetails[0].target_career,
          backup_career: response.data.roadmapDetails[0].backup_career
        });
        if (response.data.roadmapDetails[0].backup_career_2 != null) {
          this.anotherCareerField('add');
          this.preferencesForm.patchValue({
            backup_career_2: response.data.roadmapDetails[0].backup_career_2
          });
        }
        for(var i=0;i<3;i++){
          this.isDropdownEnabled[i]= false;
        }
      }
    });
  }

  searchBoxChange(event: any,index){
    index = parseInt(index);
    this.alterDropdown(index);
    if(this.isDropdownEnabled[index]==false){
      this.isDropdownEnabled[index]=true;
    }
    if(index==0){
      this.filteredDomains = this.filterData(this.preferencesForm.get('target_career').value,this.domains)
    }
    else if(index==1){
      this.filteredDomains = this.filterData(this.preferencesForm.get('backup_career').value,this.domains)
    }
    else if(index==2){
      this.filteredDomains = this.filterData(this.preferencesForm.get('backup_career_2').value,this.domains)
    }
  }

  alterDropdown(i){
    i = parseInt(i);
    this.isDropdownEnabled[i] = !this.isDropdownEnabled[i];
    if(this.isDropdownEnabled[i]){
      for(var k=0;k<3;k++){
        if(k!=i){
          this.isDropdownEnabled[k] = false;
        }
      }
    }
  }
  selectedCareerOption(indexOfSelected,i){
    i = parseInt(i);
    indexOfSelected = parseInt(indexOfSelected);
    if(i==0){
      this.preferencesForm.patchValue({
        target_career : this.filteredDomains[indexOfSelected]
      })
    }
    else if(i==1){
      this.preferencesForm.patchValue({
        backup_career : this.filteredDomains[indexOfSelected]
      })
    }
    else{
      this.preferencesForm.patchValue({
        backup_career_2 : this.filteredDomains[indexOfSelected]
      })
    }
    this.filteredDomains = this.filterData(null,this.domains);
    this.alterDropdown(i);
  } 

}
