import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'revenue'
})
export class RevenuePipe implements PipeTransform {

  transform(value: any, precision: number = 2, type:string = 'round',currencyPrefix :string = '',currencySufix:string =''): unknown {
    value = parseFloat(value);
    if(type === 'round') {
      if(isNaN(value)) {
        return value
      }
      if(currencyPrefix && currencySufix) {
        return `${currencyPrefix} ${value.toFixed(precision)} ${currencySufix}`;
      } else if (currencyPrefix && !currencySufix) {
        return `${currencyPrefix} ${value.toFixed(precision)}`;
      } else if (!currencyPrefix && currencySufix) {
        return `${value.toFixed(precision)} ${currencySufix}`;
      } else if (!currencyPrefix && !currencySufix) {
        return `${value.toFixed(precision)}`;
      }
    } else if(type === 'ground') {
      return parseInt(value)
    }

  }

}
