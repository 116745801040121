import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingFrequency'
})
export class BillingFrequencyPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value == 3) {
      return 'quarterly';
    } else if (value == 12) {
      return 'annually';
    }
  }

}
