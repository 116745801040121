import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PreloginService } from './services/prelogin.service';
import { DashboardService } from './services/dashboard.service';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  excludedRoutes = ['upgrade', 'help'];

  constructor(private router: Router, private preLoginService: PreloginService, private dashboardService: DashboardService) { }

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.preLoginService.isUserLoggedIn() && this.isAuthenticated(activatedRoute.data.expectedRole)) {
      // TODO - Need to clarify this
      // CASE 1 - If from application, while navigating user reaches to 'Page Not Found' and press back then
      // sidebar wil not be visible, so to make it visible we set the flag to true (done below).
      // Ex - in sidebar create menu 'demo' with routerLink="/demo" and do nothing (not add it in app.routing).
      // Now, click it and go back --> side and top nav will not be visible.....

      // CASE 2 - BUT if user manually types some invalid url and lands to 'Page Not Found', then on pressing back
      // the sidebar will be visible, without setting the lag to true.
      this.preLoginService.setLoggedInFlagForNav(true);


      // ('/upgrade', '/help' is accessible in every condition if user is loggedIn and authenticated)
      // Lock Screen
      if (!this.excludedRoutes.includes(activatedRoute.routeConfig.path)) {
        if (this.isUserPlanActive()) {
          return true;
        }
        else {
          this.router.navigate(['plan-expired']);
          return true;
        }
      }
      return true;
    }
    else {
      // If user is not authenticated, then redirect to following based on role :
      // Super-admin (/admin i.e. super-admin login)
      if (this.preLoginService.getCurrentRole() === 3) {
        this.router.navigate(['/admin']);
      }
      else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }


  // For role based access
  public isAuthenticated(roles) {
    return roles.some(role => role === this.preLoginService.getCurrentRole());
  }


  // For lock screen
  public isUserPlanActive() {
    const decodedToken : any = this.dashboardService.decodeToken();

    const subscriptionStatus = decodedToken.subscription_status;
    const trialPeriod = decodedToken.trial_period;

    if (subscriptionStatus === 0 && trialPeriod === 1) {
      return true;  // access
    }
    else if (subscriptionStatus === 1 && trialPeriod === 0) {
      return true;  // access
    }
    else if (subscriptionStatus === 0 && trialPeriod === 0) {
      return false; // lock
    }
    else if (subscriptionStatus === 1) {
      return true;  // access
    }
  }
}
