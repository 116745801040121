import { Component, OnInit, Input } from '@angular/core';
import { SuperadminService } from '../../services/superadmin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationsService } from 'src/app/services/common/validations.service';
declare var $: any;

@Component({
	selector: 'app-admin-partner-info',
	templateUrl: './admin-partner-info.component.html',
	styleUrls: ['./admin-partner-info.component.scss']
})
export class AdminPartnerInfoComponent implements OnInit {

	@Input() public partnerId;

	isLoading;

	statsData;

	profileForm: FormGroup;
	isProfileFormSubmitted: boolean;

	saveBtnLabel = 'Saved';
	isBtnDisabled = true;
	apiResponse: any;
	custom_prefix;
	// reset password
	resetPasswordForm: FormGroup;
	resetPasswordFormSubmitted;
	resetPasswordMessage;

	constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private superAdminService: SuperadminService) {
		// subscribe to Complimentary Coupons Added Success flag and refresh the view
		this.superAdminService.getComplimentaryCouponsAddedFlag().subscribe(data => {
			if (data) {
				this.getPartnerInfoAndStats();
			}
		});
	}

	ngOnInit() {

		this.profileForm = this.formBuilder.group({
			partner_id: [this.partnerId],
			name: ['', [Validators.required, Validators.pattern('[(A-Za-z0-9 )]+')]],
			phone: ['', Validators.required],
			email: ['', this.validationService.validateEmail],
			current_city: ['', [Validators.required]],
			designation: ['', [Validators.required]],
		});

		this.getPartnerInfoAndStats();

		//
		this.profileForm.valueChanges.subscribe(val => {
			this.saveBtnLabel = 'Save Changes';
			this.isBtnDisabled = false;
		});


		this.resetPasswordForm = this.formBuilder.group({
			partner_id: [this.partnerId],
			password: ['', [Validators.required, Validators.minLength(6)]]
		});
	}

	// getters
	get name() { return this.profileForm.get('name'); }
	get phone() { return this.profileForm.get('phone'); }
	get email() { return this.profileForm.get('email'); }
	get current_city() { return this.profileForm.get('current_city'); }
	get designation() { return this.profileForm.get('designation'); }

	//
	get password() { return this.resetPasswordForm.get('password'); }


	getPartnerInfoAndStats() {
		const postData = {
			partner_id: this.partnerId
		};

		this.superAdminService.getPartnerInfoAndStats(postData).subscribe(response => {
			if (response.status === true && response.statusCode === 200) {
				this.apiResponse = response.data;
				this.custom_prefix = this.apiResponse.basicInfo.custom_prefix;
				const customprefix = (this.apiResponse.basicInfo.custom_prefix) ? this.apiResponse.basicInfo.custom_prefix : this.apiResponse.basicInfo.name
				this.superAdminService.setPartnerName(customprefix);

				this.profileForm.patchValue({
					name: this.apiResponse.basicInfo.name,
					phone: this.apiResponse.basicInfo.phone,
					email: this.apiResponse.basicInfo.email,
					current_city: this.apiResponse.basicInfo.current_city,
					designation: this.apiResponse.basicInfo.designation
				}, { emitEvent: false });

				this.statsData = [
					{ label: 'Total Revenue', count: this.apiResponse.stats.total_revenue + 'k' },
					{ label: 'Team Members', count: this.apiResponse.stats.team_count },
					{ label: 'Assessment Coupons Bought', count: this.apiResponse.stats.coupons_bought },
					{ label: 'Assessment Coupons Remaining', count: this.apiResponse.stats.coupons_remaining },
					{ label: 'Assessment Coupons Used', count: this.apiResponse.stats.coupons_used },
					{ label: 'VCS Bought', count: this.apiResponse.stats.vip_count },
					{ label: 'VCS Used', count: this.apiResponse.stats.vip_count - this.apiResponse.stats.vip_remaining },
					{ label: 'VCS Remaining', count: this.apiResponse.stats.vip_remaining }
				];
			}
		});
	}


	profileFormSubmit() {
		this.isProfileFormSubmitted = true;

		if (this.profileForm.invalid) {
			return;
		}

		const c_prefix = (this.custom_prefix) ? this.custom_prefix : this.profileForm.value.name;
		this.superAdminService.setPartnerName(c_prefix);

		this.saveBtnLabel = 'Saving...';

		this.superAdminService.updateProfileInfo(this.profileForm.value).subscribe(response => {
			this.isProfileFormSubmitted = false;
			this.saveBtnLabel = 'Saved';
			this.isBtnDisabled = true;
		},
			error => {
				this.saveBtnLabel = 'Saved';
				this.isBtnDisabled = true;
			});
	}


	// Telephone Input
	getNumber(fullNumber) {
		this.profileForm.patchValue({
			phone: fullNumber
		});
	}

	hasError(event: any): void {
		if (!event && this.phone.value !== '') {
			this.phone.setErrors({ invalidPhoneNumber: true });
		}
	}

	// reset password
	resetPasswordFormSubmit() {
		this.resetPasswordFormSubmitted = true;
		if (this.resetPasswordForm.invalid) {
			return;
		}

		this.superAdminService.resetPartnerPassword(this.resetPasswordForm.value).subscribe(response => {
			if (response.status === true && response.statusCode === 200) {
				this.resetPasswordMessage = 'Success';
				this.resetPasswordForm.reset();
				this.resetPasswordFormSubmitted = false;

				setTimeout(() => {
					this.resetPasswordMessage = null;
					$('#resetPasswordModal').modal('hide');
				}, 3000);

			}
		});

	}

}
