import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { environment } from '../../../environments/environment';
import { SuperadminService } from '../../services/superadmin.service';

@Component({
	selector: 'app-admin-information',
	templateUrl: './admin-information.component.html',
	styleUrls: ['./admin-information.component.scss']
})
export class AdminInformationComponent implements OnInit {

	@Input() public partnerId;
	isLoading = true;

	srvce;
	checkArray = [];

	default_heading = 'Discover Your Perfect Career';
	default_sub_heading = 'Take the world’s most-advanced career assessment and find your best-fit career.';
	default_featured_photo = 'https://mindlerimages.imgix.net/home/p-testi4.png';

	isInformationFormSubmitted: boolean;

	isLargeImage = false;
	isImageUploaded = true;
	uploadedImageName = '';

	loggedInUserDetails;
	displayImageUrl;

	getPartnerDetailsPostData = {
		partner_id: Number,
		section: '102'
	};

	saveBtnLabel = 'Saved';
	isBtnDisabled = true;
	minLengthArray;

	uploadedQrImage = "";

	informationForm = this.formBuilder.group({
		partner_id: [],
		heading: [this.default_heading, Validators.required],
		sub_heading: [this.default_sub_heading, Validators.required],
		about_us: ['', Validators.maxLength(2000)],
		featured_photo: [null],
		profile_evaluation: [false],
		counselling_session: [false],
		overseas_package: [false],
		india_package: [false],
		psychometric_assessment: [false],
		college_recommendation: [false],
		counselling_stream_assessment: [false],
		upi_id: ['', []],
		qr_code: ['', []],
		whatsapp_number: ['', []],
		services: this.formBuilder.array([])
	});

	constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private superAdminService: SuperadminService) { }

	ngOnInit() {

		this.getPartnerDetailsPostData.partner_id = this.partnerId;

		this.minLengthArray = (min: number): ValidatorFn | any => {
			return (control: AbstractControl[]) => {
				if (!(control instanceof FormArray)) return;
				return control.length < min ? { minLength: true } : null;
			}
		}


		this.superAdminService.getPartnerData(this.getPartnerDetailsPostData).subscribe(response => {
			this.isLoading = false;

			if (response.hasOwnProperty('data')) {

				console.log(response.data.services);
				// console.log(response.data.services);
				// this.srvce.forEach((e) => {
				//   if (e.svgString == e.defaultIcon) {
				//     e.img_type = "svg";
				//   }
				//   this.checkArray.push({
				//     id: e.id,
				//     title: e.title,
				//     description: e.description,
				//     status: e.status,
				//     svgString: e.svgString,
				//     defaultIcon: e.defaultIcon,
				//     img_type: e.img_type,
				//   });
				// });

				this.informationForm.patchValue({
					partner_id: this.partnerId,
					heading: response.data.heading,
					sub_heading: response.data.sub_heading,
					about_us: response.data.about_us,
					featured_photo: response.data.featured_photo,
					profile_evaluation: response.data.profile_evaluation,
					counselling_session: response.data.counselling_session,
					overseas_package: response.data.overseas_package,
					india_package: response.data.india_package,
					psychometric_assessment: response.data.psychometric_assessment,
					college_recommendation: response.data.college_recommendation,
					counselling_stream_assessment: response.data.counselling_stream_assessment,
					upi_id: response.data.upi_id ? response.data.upi_id : "",
					qr_code: response.data.qr_code ? response.data.qr_code : "",
					whatsapp_number: response.data.whatsapp_number ? response.data.whatsapp_number : "",
					services: response.data.services ? response.data.services : []
				},
					{ emitEvent: false })
			}
			if (response.data.services) {
				response.data.services.forEach(e => {
					this.services.push(this.formBuilder.group({
						id: e.id,
						partner_id: e.partner_id,
						service_name: e.service_name,
						svgString: e.svgString,
						img_type: e.img_type,
						title: e.title,
						description: e.description,
						status: e.status
					}));
				})
			}

			if (this.informationForm.get('qr_code').value) {
				this.uploadedQrImage = environment.featured_photo_baseUrl + this.partnerId + '/' + response.data.qr_code;
			}
			if (this.informationForm.get('featured_photo').value != null) {
				this.displayImageUrl = environment.featured_photo_baseUrl + this.partnerId + '/' + response.data.featured_photo;
				// Dynamic validation
				const about_us = this.informationForm.get('about_us');
				about_us.setValidators(Validators.required);
				about_us.setValidators(Validators.maxLength(2000));
				about_us.updateValueAndValidity({ emitEvent: false });
			}
			else {
				this.displayImageUrl = this.default_featured_photo;
			}
		});

		this.loggedInUserDetails = this.dashboardService.decodeToken();

		//
		this.informationForm.valueChanges.subscribe(val => {
			this.saveBtnLabel = 'Save Changes';
			this.isBtnDisabled = false;
		});
	}

	// getters
	get heading() { return this.informationForm.get('heading'); }
	get sub_heading() { return this.informationForm.get('sub_heading'); }
	get about_us() { return this.informationForm.get('about_us'); }
	get featured_photo() { return this.informationForm.get('featured_photo'); }

	uploadImage(event) {
		if (event.target.files[0].size > 500000) {
			this.isLargeImage = true;
		}
		else {
			// Dynamic validation
			const about_us = this.informationForm.get('about_us');
			// console.log(event.target.files.length);
			if (event.target.files.length >= 1) {
				about_us.setValidators(Validators.required);
				about_us.setValidators(Validators.maxLength(2000));
			}
			else {
				about_us.clearValidators();
			}
			about_us.updateValueAndValidity();
			//
			this.isLargeImage = false;

			const fileName = event.target.files[0].name;
			const newFileName = fileName.replace(/ /g, '-');

			const uploadData = new FormData();
			uploadData.append('image', event.target.files[0], newFileName);
			uploadData.append('partner_id', this.partnerId);
			uploadData.append('bucket', 'partner-featured-photos');

			this.superAdminService.uploadImage(uploadData).subscribe(data => {
				this.uploadedImageName = data.name;
				this.displayImageUrl = environment.featured_photo_baseUrl + this.partnerId + '/' + this.uploadedImageName;
				this.informationForm.patchValue({
					featured_photo: this.uploadedImageName
				});
				this.saveBtnLabel = 'Save Changes';
			});
		}
	}

	uploadQRImage(event) {
		if (event.target.files[0].size > 500000) {
			this.isLargeImage = true;
		} else {
			// Dynamic validation
			const about_us = this.informationForm.get("about_us");

			if (event.target.files.length >= 1) {
				about_us.setValidators(Validators.required);
				//about_us.setValidators(Validators.maxLength(2000));
			} else {
				about_us.clearValidators();
			}
			about_us.updateValueAndValidity();
			const fileName = event.target.files[0].name;
			const newFileName = fileName.replace(/ /g, "-");
			if (
				event.target.files[0].type != "image/jpeg" &&
				event.target.files[0].type != "image/png"
			) {
				this.isLargeImage = true;
				return;
			}
			this.isLargeImage = false;

			const uploadData = new FormData();
			uploadData.append('image', event.target.files[0], newFileName);
			uploadData.append('partner_id', this.partnerId);
			uploadData.append('bucket', 'partner-featured-photos');

			this.superAdminService
				.uploadImage(uploadData)
				.subscribe((data) => {
					this.uploadedImageName = data.name;
					this.uploadedQrImage =
						environment.featured_photo_baseUrl +
						this.partnerId +
						"/" +
						this.uploadedImageName;
					this.informationForm.patchValue({
						qr_code: this.uploadedImageName,
					});
					this.saveBtnLabel = "Save Changes";
				});
		}
	}

	informationFormSubmit() {
		this.isInformationFormSubmitted = true;
		if (this.informationForm.invalid) {
			this.informationForm.markAllAsTouched();
			return;
		}

		this.saveBtnLabel = 'Saving...';
		const information = this.informationForm.value;
		information.services = this.informationForm.value.services.map(obj => {
			obj.status = ~~obj.status
			return obj;
		})
		this.superAdminService.setInformationDetails(information).subscribe(data => {
			if (data.statusCode === 200 && data.status === true) {
				this.saveBtnLabel = 'Saved';
				this.isBtnDisabled = true;
			}
		});
	}

	get upi_id() {
		return this.informationForm.get("upi_id");
	}
	get qr_code() {
		return this.informationForm.get("qr_code");
	}
	get whatsapp_number() {
		return this.informationForm.get("whatsapp_number");
	}
	get services() {
		return this.informationForm.get("services") as FormArray | null;
	}
}
