import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  isLoading = true;
  message;

  apiResponse;
  studentId;

  constructor(private dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.getStudentInfo();
  }

  getStudentInfo() {
    this.isLoading = true;

    // Accessing parent route params
    this.activatedRoute.parent.paramMap.subscribe((params: ParamMap) => this.studentId = (params.get('student_id')));

    this.dashboardService.studentInfo({ student_id: this.studentId }).subscribe(response => {
      this.isLoading = false;

      if (response.status == false && response.statusCode == 200) {
        this.message = response.message;
      }
      else if (response.status == true && response.statusCode == 200) {
        this.apiResponse = response.data;
      }
    });
  }

}
