import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Toast } from 'primeng/toast';
import { SuperadminService } from 'src/app/services/superadmin.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-admin-product-access-management',
  templateUrl: './admin-product-access-management.component.html',
  styleUrls: ['./admin-product-access-management.component.scss']
})
export class AdminProductAccessManagementComponent implements OnInit {
  disabledtxt;
  servicesAndFeaturesList: any;
  servicesList;
  featuresList;
  serviceArrayListTwo;
  addProduct: any[] = [];
  removeProduct = [];
  @Input() public partnerId;
  partner_access_management_status;
  savingText = "Save Changes"
  assessmentName = "Assessment";
  isAssessment = true;
  updatedRemovedProduct = []
  updatedAddedProduct = []
  serviceDependency = [
    {
      id: 1,
      d: [2,3,4],
      i: [2,3,4]
    },
    // {
    //   id: 2,
    //   d: [3],
    //   i: [3]
    // },
    // {
    //   id: 3,
    //   d: [4],
    //   i: [4]
    // },
    // {
    //   id: 4,
    //   d: [],
    //   i: []
    // }
  ];

  preActive = [];

  constructor(private superAdminService: SuperadminService, private formBuilder: FormBuilder, private messageService: MessageService) { }


  ngOnInit() {
    this.getAllFeaturesAndServices();
  }
  disabletoggle(event) {
    if (event.target.checked) {
      this.partner_access_management_status = 1;
      this.disabledtxt = "Enabled";
      this.savingText = "Save Changes"
    }
    else if (!event.target.checked) {
      this.partner_access_management_status = 0;
      this.disabledtxt = "Disabled";
      this.savingText = "Save Changes"
    }
  }

  getAllFeaturesAndServices() {
    let reqObj = {
      "partner_id": this.partnerId
    };
    this.superAdminService.getallServicesAndFeatures(reqObj).subscribe(response => {
      this.updatedAddedProduct = [];
      this.updatedRemovedProduct = [];
      this.preActive = [];
      if (response.status == true && response.statusCode == 200) {
        this.servicesAndFeaturesList = response.data;
        this.partner_access_management_status = response.data.pam_access;
        if (this.partner_access_management_status == 1) {
          this.disabledtxt = "Enabled"
        }
        else if (this.partner_access_management_status == 0) {
          this.disabledtxt = "Disabled";
        }
        this.servicesList = this.servicesAndFeaturesList.servicesArray1.sort((a, b) => a.id - b.id);
        this.servicesList = this.servicesList.map((data) => {
          data["product_key"] = data.product_name.split(" ").join("")
          return { ...data, disable: 1 }
        })



        // this.servicesList.unshift({ id: 0, product_name: 'Assessment', status: 1, disable: 0 })
        this.serviceArrayListTwo = this.servicesAndFeaturesList.servicesArray2.sort((a, b) => a.id - b.id);
        this.serviceArrayListTwo = this.serviceArrayListTwo.map((data) => {
          data["product_key"] = data.product_name.split(" ").join("")
          return { ...data, disable: 1 }
        })


        this.featuresList = this.servicesAndFeaturesList.features.sort((a, b) => a.id - b.id);
        this.featuresList = this.featuresList.map((data) => {
          data["product_key"] = data.product_name.split(" ").join("")
          return { ...data, disable: 1 }
        })
        
        for (let s of this.servicesList) {
          if (s.status == 1) {
            this.preActive.push(s.id)
          }
        }

        for (let f of this.serviceArrayListTwo) {
          if (f.status == 1) {
            this.preActive.push(f.id)
          }
        }

        for (let f of this.featuresList) {
          if (f.status == 1) {
            this.preActive.push(f.id)
          }
        }

        this.onInitialFetch();
      }
    })


  }

  submitAdminAccess() {
    this.savingText = "Saving...";
    this.updatedAddedProduct = [];
    this.updatedRemovedProduct = [];
    this.servicesList.forEach((service) => {
      if ((service.status == 1)) {
        this.updatedAddedProduct.push(service.id)
      }
    })

    this.serviceArrayListTwo.forEach((service) => {
      if ((service.status == 1)) {
        this.updatedAddedProduct.push(service.id)
      }
    });

    this.featuresList.forEach((service) => {
      if ((service.status == 1)) {
        this.updatedAddedProduct.push(service.id)
      }
    });

    for (let s of this.servicesList) {
      if (s.status === 0) {
        if (this.preActive.includes(s.id)) {
          this.updatedRemovedProduct.push(s.id);
        }
      }
    }


    for (let s of this.serviceArrayListTwo) {
      if (s.status === 0) {
        if (this.preActive.includes(s.id)) {
          this.updatedRemovedProduct.push(s.id);
        }
      }
    }


    for (let f of this.featuresList) {
      if (f.status === 0) {
        if (this.preActive.includes(f.id)) {
          this.updatedRemovedProduct.push(f.id);
        }
      }
    }
    let reqObj: any = {
      "partner_id": this.partnerId,
      "partner_access_management_status": this.partner_access_management_status,
      "product_disabled": this.updatedRemovedProduct,
      "product_added": this.updatedAddedProduct
    }
    if ((this.updatedAddedProduct.includes(1))) {
      this.superAdminService.updateStudentServices(reqObj).subscribe(response => {
        if (response.status == true && response.statusCode == 200) {
          this.messageService.add({
            key: 'topright',
            severity: 'success',
            detail: response.message,
          });
          this.savingText = "Saved"
          this.getAllFeaturesAndServices();
          this.superAdminService.changesOccur.next();
        }
      })
    } else {
      this.messageService.add({
        key: 'topright',
        severity: 'warn',
        detail: "Please add Assessment",
      });
    }
  }

  // getMapped(list) {
  //   if (list) {
  //     return list.map((data) => {
  //       data["product_key"] = data.product_name.split(" ").join("")
  //       return data;
  //     })
  //   }
  // }

  onServiceArrayTwoProductChange(event, index) {
    if (event.target.checked) {
      this.serviceArrayListTwo[index].status = 1;
      this.savingText = "Save Changes"
    } else {
      this.serviceArrayListTwo[index].status = 0;
      this.savingText = "Save Changes"
    }
  }


  onFeatureProductChange(event, index) {
    if (event.target.checked) {
      this.featuresList[index].status = 1;
      this.savingText = "Save Changes"
    } else {
      this.featuresList[index].status = 0;
      this.savingText = "Save Changes"
    }
  }

  onServiceProductChange(event, index) {
    let service = this.servicesList[index];
    if (event.target.checked) {
      // if (service.product_key == 'Career') {
      //   let stream = this.servicesList.filter((service) => service.product_key == 'Stream')
      //   let streamIndex = this.servicesList.findIndex((s) => s.id == stream[0]['id']);
      //   if (streamIndex !== -1) {
      //     this.servicesList[streamIndex]['status'] = 0;
      //   }
      // }

      // if (service.product_key == 'Stream') {
      //   let career = this.servicesList.filter((service) => service.product_key == 'Career')
      //   let careerIndex = this.servicesList.findIndex((s) => s.id == career[0]['id']);
      //   if (careerIndex !== -1) {
      //     this.servicesList[careerIndex]['status'] = 0;
      //   }
      // }
      this.servicesList[index].status = 1;
      this.savingText = "Save Changes"
    } else {
      this.servicesList[index].status = 0;
      this.savingText = "Save Changes"
    }
    this.onAssessmentChnge(event.target.checked, service.id)
  }

  onAssessmentChnge(isChecked, serviceId) {
    let serviceControllDependency = this.serviceDependency.find((s) => s.id == serviceId);
    if (isChecked && serviceControllDependency) {
      let toEnable = serviceControllDependency.d
      this.enableControlls(toEnable);
    } else if (serviceControllDependency) {
      let toDisable = serviceControllDependency.i;
      this.disableControlls(toDisable);
    }
  }

  onInitialFetch() {
    this.servicesList.forEach(service => {
      if (service.status == 1) {
        let serviceControllDependency = this.serviceDependency.find((s) => s.id == service.id);
        let toEnable = serviceControllDependency.d;
        this.enableControlls(toEnable);
      }
    })
  }

  enableControlls(list: any[]) {
    this.servicesList.forEach(service => {
      if (list.includes(service.id)) {
        service.disable = 0
      }
    })
  }
  disableControlls(list: any[]) {
    this.servicesList.forEach(service => {
      if (list.includes(service.id)) {
        service.disable = 1
        service.status = 0
      }
    })
  }
}
