import { Component, OnInit, NgZone, Input,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ValidationsService } from '../../../services/common/validations.service';
import { DashboardService } from '../../../services/dashboard.service';
import { WindowRefService } from '../../../services/window-ref.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from 'primeng/api';
// import { validateCouponQuantity } from 'src/app/custom_validators/registration.validator';

@Component({
  selector: 'app-purchase-assessment-coupons',
  templateUrl: './purchase-assessment-coupons.component.html',
  styleUrls: ['./purchase-assessment-coupons.component.scss']
})
export class PurchaseAssessmentCouponsComponent implements OnInit {

  loggedInUserDetails;

  buyCouponsForm: FormGroup;
  maximumQuantity;
  planId;
  planPricePerCoupon;
  paymentPrice;
  @Input() isBuyMoreButtonClicked: boolean;
  @Output() flagCouponChanged = new EventEmitter();
  // From Parent
  @Input() public planInfo;

  constructor(private zone: NgZone, private windowRef: WindowRefService, private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService, private messageService:MessageService) { }

  ngOnInit() {
    // console.log('in child', this.planInfo);

    this.loggedInUserDetails = this.dashboardService.decodeToken();

    this.maximumQuantity = this.planInfo[this.planInfo.length - 1].range_1 - 1;

    this.buyCouponsForm = this.formBuilder.group({
      quantity: ['', [Validators.required, this.validationService.validateCouponQuantity(1, this.maximumQuantity)]],
      prefix: ['', Validators.pattern('[(A-Za-z0-9)]+')]
    });

    this.buyCouponsForm.get('quantity').valueChanges.subscribe(inputValue => {
      this.calculatePrice(inputValue);
    });
  }

  // getters
  get quantity() { return this.buyCouponsForm.get('quantity'); }
  get prefix() { return this.buyCouponsForm.get('prefix'); }

  calculatePrice(inputValue) {
    if (this.quantity.valid) {
      (this.planInfo).forEach(plan => {
        if (inputValue >= plan.range_1 && inputValue <= plan.range_2) {
          this.planId = plan.id;
          this.planPricePerCoupon = plan.price_per_coupon;
        }
      });

      const getPricePostData = {
        quantity: inputValue,
        id: this.planId
      }

      this.dashboardService.getPriceForCoupons(getPricePostData).subscribe(response => {
        // console.log(response);
        if (response.statusCode == 200 && response.status == true) {
          this.paymentPrice = response.data.price;
        }
      });
    }
    else {
      this.paymentPrice = '';
      this.planId = '';
      this.planPricePerCoupon = '';
    }

  }

  buyCouponsFormSubmit() {
    this.isBuyMoreButtonClicked=false;
    this.flagCouponChanged.emit(this.isBuyMoreButtonClicked);
    if (this.buyCouponsForm.valid) {
      const options = {
        key: this.dashboardService.getRazorpayKey(),
        subscription_card_change: 0,
        name: 'Mindler',
        description: 'Assessment Coupons',
        image: 'https://mindlerpartner.imgix.net/partner-assets/m-blue-logo.png',
        amount: this.paymentPrice * 100,    // amount has to be provided in paise INR 1 = 100 paise
        prefill: { name: this.loggedInUserDetails.name, email: this.loggedInUserDetails.email },
        theme: { color: '#007fb6' },
        handler: ((razorpayResponse) => {
          // console.log('razor - ', razorpayResponse);
          const assignAdditionalCouponsPostData = {
            razorpay_payment_id: razorpayResponse.razorpay_payment_id,
            purchase_id: this.planId,
            quantity: this.quantity.value,
            prefix: this.prefix.value
          };
          this.dashboardService.assignAdditionalCoupons(assignAdditionalCouponsPostData).subscribe(response => {
            this.buyCouponsForm.reset();
            // console.log(response);
            if (response.statusCode == 200 && response.status == true) {
              this.messageService.add({
                key: 'topright',
                severity: 'success',
                detail: "response.message",
              });
              this.dashboardService.setCouponBoughtFlag(response.couponCount, response.couponAmount);
            }
          });
        })

      };
      const rzp1 = new this.windowRef.nativeWindow.Razorpay(options);
      rzp1.open();
    }
  }

}
