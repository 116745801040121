import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { SuperadminService } from '../../services/superadmin.service';

@Component({
  selector: 'app-admin-testimonials',
  templateUrl: './admin-testimonials.component.html',
  styleUrls: ['./admin-testimonials.component.scss']
})
export class AdminTestimonialsComponent implements OnInit {

  @Input() public partnerId;

  isLoading = true;

  loggedInUserDetails;

  getPartnerDetailsPostData = {
    partner_id: Number,
    section: '103'
  };

  saveStatus;

  myForm: FormGroup;

  submitted = false;
  formId = 1;
  existingData;

  removeParamForm;
  removeParamIndex;

  saveBtnLabel = 'Save';

  constructor(private fb: FormBuilder, private dashboardService: DashboardService, private superAdminService: SuperadminService) { }

  ngOnInit() {
    this.getPartnerDetailsPostData.partner_id = this.partnerId;

    this.myForm = this.fb.group({
      partner_id: [this.partnerId],
      testimonials: new FormArray([])
    });

    this.getInitialForms();
  }

  // getter  - (accessed as this.testimonialsForm in this file)
  get testimonialsForm() { return this.myForm.get('testimonials') as FormArray; }

  getInitialForms() {
    this.isLoading = true;

    this.superAdminService.getPartnerData(this.getPartnerDetailsPostData).subscribe(response => {
      this.isLoading = false;

      if (response.data.length >= 1) {
        this.existingData = response.data;
        this.setTestimonials();
      }
      else {
        this.testimonialsForm.push(
          this.fb.group({
            name: ['', Validators.required],
            designation: ['', Validators.required],
            testimonial_text: ['', [Validators.required, Validators.maxLength(800)]]
          })
        );
      }
    });
  }


  setTestimonials() {
    this.existingData.forEach(x => {
      this.testimonialsForm.push(this.fb.group({
        id: [x.id],
        name: [x.name, Validators.required],
        designation: [x.designation, Validators.required],
        testimonial_text: [x.testimonial_text, [Validators.required, Validators.maxLength(800)]]
      }));
    });
  }

  // add form
  addNewTestimonialForm() {
    this.testimonialsForm.push(
      this.fb.group({
        name: ['', Validators.required],
        designation: ['', Validators.required],
        testimonial_text: ['', [Validators.required, Validators.maxLength(800)]]
      })
    );
    this.submitted = false;
  }

  // remove form step-1 (Set remove params)
  setRemoveFromParameters(form, index) {
    this.removeParamForm = form;
    this.removeParamIndex = index;
  }

  // remove form step-2 (Remove)
  removeTestimonialForm() {
    if (this.removeParamForm.hasOwnProperty('id')) {
      let postData = {
        partner_id: this.partnerId,
        testimonial_id: this.removeParamForm.id
      }
      this.superAdminService.removeTestimonial(postData).subscribe(response => {
        this.testimonialsForm.removeAt(this.removeParamIndex);
      });
    }
    else {
      this.testimonialsForm.removeAt(this.removeParamIndex);
    }
    this.submitted = false;
  }


  nestedFormSubmit() {
    if (this.testimonialsForm.length == 0) {
      return;
    }

    this.submitted = true;

    if (this.myForm.invalid) {
      return;
    }

    this.saveBtnLabel = 'Saving...';

    this.superAdminService.setTestimonialsDetails(this.myForm.value).subscribe(response => {
      this.saveBtnLabel = 'Saved';

      this.myForm.reset();
      this.testimonialsForm.clear();
      this.getInitialForms();
    });
  }
}

