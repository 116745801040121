import { Component, OnInit, NgZone } from "@angular/core";
import { DashboardService } from "../../services/dashboard.service";
import { PreloginService } from "../../services/prelogin.service";
import { Subscription } from "rxjs";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { environment } from "../../../environments/environment";
import { LazyLoadFilesService } from "../../services/lazy-load-files.service";
import { WhitelabellingOptionsComponent } from "../whitelabelling-options/whitelabelling-options.component";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  showSidebar;

  loggedInUserDetails;
  userRole; // 0 - Partner, 1 - Admin, 2 - Counsellor, 3 - Super-admin
  isPartner;
  isAdmin;
  isSuperAdmin;

  careerLibraryUrl = localStorage.getItem('careerUrl');

  name;
  profileImage = "";
  currentUserId;
  partnerLogoImage = "";
  logoUrl = "https://mindler-products-images.imgix.net/confluence/general-icons/edsoul.png?w=350&h=75&fit=crop&crop=faces";

  showBillingMenu;
  whiteLabellingDisabled: boolean = true;

  white_labelling;
  brandLogo;
  partner_id
  constructor(
    private zone: NgZone,
    private router: Router,
    private dashboardService: DashboardService,
    private preLoginService: PreloginService,
    private lazyLoadService: LazyLoadFilesService
  ) {
    console.log("Constructor called");
    // subscribe to Profile Image flag
    this.dashboardService.getProfileImageFlag().subscribe((data) => {
      this.profileImage = data.imageUrl;
      this.name = data.name;
    });

    this.preLoginService.getWhiteLabelling().subscribe((data) => {
      if (data != null) {
        if (data === 1) {
          this.whiteLabellingDisabled = true;
        } else {
          this.whiteLabellingDisabled = false;
        }
      }
    });

    this.preLoginService.getPartnerLogo().subscribe((data) => {
      this.partnerLogoImage = data;
    })

    // subscribe to First Time Plan Purchase flag (to show 'Billing & Invoices' menu)
    this.dashboardService.getFirstTimePlanPurchaseFlag().subscribe((data) => {
      // Billing Condition - partner && (subscription==1 || (subscription==0 && trial==0))

      // Refreshing user details with new token value
      this.loggedInUserDetails = this.dashboardService.decodeToken();
      this.showBillingMenu = this.isBillingMenuAllowed();
    });

    this.dashboardService.getCareerLibraryFlag().subscribe((data) => {
      if (data.career_library_enabled === 1) {
        this.careerLibraryUrl = data.url + "/";
      }
      else {
        this.careerLibraryUrl = 'https://www.mindler.com/';
      }
      localStorage.setItem('careerUrl', this.careerLibraryUrl);
    });

  }

  ngOnInit() {

    if (localStorage.getItem("whiteLabelling") === "1") {
      this.whiteLabellingDisabled = true;
    } else if (localStorage.getItem("whiteLabelling") === "0") {
      this.whiteLabellingDisabled = false;
    }

    this.loggedInUserDetails = this.dashboardService.decodeToken();
      if(this.loggedInUserDetails.white_labelling && this.loggedInUserDetails.logo){
        this.brandLogo = `https://mindlerpartner.imgix.net/partner-logo/${this.loggedInUserDetails.partner_id}/`+this.loggedInUserDetails.logo;
      }else{
        this.brandLogo="https://mindlerpartner.imgix.net/partner-assets/mindler-logo.svg"
      }

    this.router.events.subscribe((event: Event) => {
      // If, want to execute specific code only for a particular screen size, then use below with if statement -
      // console.log('window width - ', window.innerWidth);
      if (event instanceof NavigationStart) {
        // scroll to top on route change
        // (on mobile - if user is at the last of a page and goes to different page (long page),
        // then the new screen appears at the same scroll position i.e. last. So, to fix this below line is used)
        window.scrollTo(0, 0);
      }
    });

    this.loggedInUserDetails = this.dashboardService.decodeToken();

    this.name = this.loggedInUserDetails.name;

    this.showBillingMenu = this.isBillingMenuAllowed();

    this.userRole = this.preLoginService.getCurrentRole();

    // 0 - Partner, 1 - Admin, 2 - Counsellor, 3 - Super-admin
    if (this.userRole === 0) {
      this.isPartner = true;
      this.currentUserId = this.loggedInUserDetails.partner_id;
    } else if (this.userRole === 1) {
      this.isAdmin = true;
      this.currentUserId = this.loggedInUserDetails.counselor_id;
    } else if (this.userRole === 2) {
      this.currentUserId = this.loggedInUserDetails.counselor_id;
    } else if (this.userRole === 3) {
      this.isSuperAdmin = true;
      this.currentUserId = this.loggedInUserDetails.admin_id;
    }

    if (this.loggedInUserDetails.profile_image != null) {
      if (this.userRole === 0) {
        this.profileImage =
          environment.partner_photo_baseUrl +
          this.currentUserId +
          "/" +
          this.loggedInUserDetails.profile_image;
      } else if (this.userRole === 1 || this.userRole === 2) {
        this.profileImage =
          environment.counsellor_photo_baseUrl +
          this.currentUserId +
          "/" +
          this.loggedInUserDetails.profile_image;
      }
    }

    this.dashboardService.getCareerLibraryFlag().subscribe((data) => {
      if (data.career_library_enabled === 1) {
        this.careerLibraryUrl = data.url + "/";
      }
      else {
        this.careerLibraryUrl = "";
      }
      localStorage.setItem('careerUrl', this.careerLibraryUrl);
    });
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  isBillingMenuAllowed() {
    if (
      this.loggedInUserDetails.subscription_status === 1 ||
      (this.loggedInUserDetails.subscription_status === 0 &&
        this.loggedInUserDetails.trial_period === 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  logOut() {
    this.preLoginService.logOut();
  }
}
