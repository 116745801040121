import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { SuperadminService } from '../../services/superadmin.service';

@Component({
  selector: 'app-admin-organization-details',
  templateUrl: './admin-organization-details.component.html',
  styleUrls: ['./admin-organization-details.component.scss']
})
export class AdminOrganizationDetailsComponent implements OnInit {

  @Input() public partnerId;
  isLoading = true;

  organizationDetailsForm: FormGroup;
  isOrganizationDetailsFormSubmitted: boolean;

  saveBtnLabel = 'Saved';
  isBtnDisabled = true;
  iswhitelabelled = true;
  isDomainAvailable;
  websiteAddress;

  constructor(private formBuilder: FormBuilder, private validationService: ValidationsService, private superAdminService: SuperadminService) { }

  ngOnInit() {

    this.organizationDetailsForm = this.formBuilder.group({
      partner_id: [this.partnerId],
      orgnization_name: [''],
      helpline_number: [''],
      official_email_id: ['', this.validationService.validateEmail],
      office_address: [''],
      website_address:[''],
      google_map_link: ['']
    });

    this.getOrganizationDetails();

    //
    this.organizationDetailsForm.valueChanges.subscribe(val => {
      this.saveBtnLabel = 'Save Changes';
      this.isBtnDisabled = false;
    });
  }

  // getters
  get helpline_number() { return this.organizationDetailsForm.get('helpline_number'); }
  get official_email_id() { return this.organizationDetailsForm.get('official_email_id'); }


  getOrganizationDetails() {
    this.isLoading = true;
    
    this.superAdminService.getOrganizationDetails({ partner_id: this.partnerId }).subscribe(response => {
      this.isLoading = false;
      if (response.statusCode === 200 && response.status === true) {
        if ((response.data.details).length > 0) {
          this.organizationDetailsForm.patchValue({
            orgnization_name: response.data.details[0].orgnization_name,
            helpline_number: response.data.details[0].helpline_number,
            official_email_id: response.data.details[0].official_email_id,
            office_address: response.data.details[0].office_address,
            google_map_link: response.data.details[0].google_map_link
          },
            { emitEvent: false });
        }

        if(!response.data.white_labelling && response.data.domain_name){
          this.organizationDetailsForm.patchValue({ website_address:response.data.domain_name });
        }else{
          this.websiteAddress = response.data.domain_name;
        }

        this.iswhitelabelled = response.data.white_labelling;
      }
    });
  }


  organizationDetailsFormSubmit() {
    if (this.organizationDetailsForm.invalid) {
      return;
    }

    this.saveBtnLabel = 'Saving...';

    this.superAdminService.setOrganizationDetails(this.organizationDetailsForm.value).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.saveBtnLabel = 'Saved';
        this.isBtnDisabled = true;
        this.isDomainAvailable = response.isDomainExist;
      }
    });
  }

  // Telephone Input
  getNumber(fullNumber) {
    this.organizationDetailsForm.patchValue({
      phone: fullNumber
    });
  }

  hasError(event: any): void {
    if (!event && this.helpline_number.value !== '') {
      this.helpline_number.setErrors({ invalidPhoneNumber: true });
    }
  }

}
