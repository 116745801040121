import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ValidationsService } from 'src/app/services/common/validations.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { WindowRefService } from 'src/app/services/window-ref.service';

@Component({
  selector: 'app-purchase-vip',
  templateUrl: './purchase-vip.component.html',
  styleUrls: ['./purchase-vip.component.scss']
})
export class PurchaseVipComponent implements OnInit {
  vipPurchasePlan = [];
  vipPurchaseQuantity;
  vipPaymentPrice;
  planId;
  planPricePerCoupon;
  buyVipForm: FormGroup;
  maximumQuantity;
  loggedInUserDetails;
  @Input() isBuyMoreVipButtonClicked: boolean;
  @Output() flagChanged = new EventEmitter();
  constructor(private dashboardService: DashboardService, private windowRef: WindowRefService, private formBuilder: FormBuilder, private validationService: ValidationsService,private messageService: MessageService) { }

  ngOnInit() {
    this.getVipPurchaseCoupons();
    this.loggedInUserDetails = this.dashboardService.decodeToken();
    this.buyVipForm = this.formBuilder.group({
      quantity: ['', [Validators.required, this.validationService.validateCouponQuantity(1, this.maximumQuantity)]],
    });
  }

  calculatePriceOnChange(event) {
    let inputValue = event.target.value;
    this.calculatePrice(inputValue);
    this.buyVipForm.get('quantity').setValidators([Validators.required, this.validationService.validateCouponQuantity(1, this.maximumQuantity)])
    this.buyVipForm.get('quantity').updateValueAndValidity();
  }

  getVipPurchaseCoupons() {
    this.dashboardService.getvipPurchasePlan().subscribe((response) => {
      this.vipPurchasePlan = response.data;
      this.maximumQuantity = this.vipPurchasePlan[this.vipPurchasePlan.length - 1].range_2;
    })
  }

  //getter
  get quantity() { return this.buyVipForm.get('quantity'); }

  calculatePrice(inputValue) {
    if (this.quantity.valid) {
      (this.vipPurchasePlan).forEach(plan => {
        if (inputValue >= plan.range_1 && inputValue <= plan.range_2) {
          this.planId = plan.id;
          this.planPricePerCoupon = plan.price_per_coupon;
        }
      });

      const getPricePostData = {
        quantity: inputValue,
        id: this.planId
      }

      this.dashboardService.getPriceForVip(getPricePostData).subscribe(response => {
        // console.log(response);
        if (response.statusCode == 200 && response.status == true) {
          this.vipPaymentPrice = response.data.price;
        }
      });
    }
    else {
      this.vipPaymentPrice = '';
      this.planId = '';
      this.planPricePerCoupon = '';
    }

  }


  buyVipFormSubmit() {
    this.isBuyMoreVipButtonClicked=false
    this.flagChanged.emit(this.isBuyMoreVipButtonClicked);
    if (this.buyVipForm.valid) {
      const options = {
        key: this.dashboardService.getRazorpayKey(),
        subscription_card_change: 0,
        name: 'Mindler',
        // order_id: 1234,
        description: 'Vip Coupons',
        image: 'https://mindlerpartner.imgix.net/partner-assets/m-blue-logo.png',
        amount: this.vipPaymentPrice * 100,    // amount has to be provided in paise INR 1 = 100 paise
        prefill: { name: this.loggedInUserDetails.name, email: this.loggedInUserDetails.email },
        theme: { color: '#007fb6' },
        handler: ((razorpayResponse) => {
          // console.log('razor - ', razorpayResponse);
          const assignAdditionalVipPostData = {
            razorpay_payment_id: razorpayResponse.razorpay_payment_id,
            purchase_id: this.planId,
            quantity: +this.quantity.value,
          };
          this.dashboardService.assignAdditionalVip(assignAdditionalVipPostData).subscribe(response => {
            this.buyVipForm.reset();
            // console.log(response);
            if (response.statusCode == 200 && response.status == true) {
              this.messageService.add({
                key: 'topright',
                severity: 'success',
                detail: "response.message",
              });
              // this.dashboardService.setCouponBoughtFlag(response.couponCount, response.couponAmount);
            }
          });
        })

      };
      const rzp1 = new this.windowRef.nativeWindow.Razorpay(options);
      rzp1.open();
    }
  }



}
