import { Component, OnInit } from '@angular/core';
import { PreloginService } from '../../services/prelogin.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-counsellor-signup-preprocessing',
  templateUrl: './counsellor-signup-preprocessing.component.html',
  styleUrls: ['./counsellor-signup-preprocessing.component.scss']
})
export class CounsellorSignupPreprocessingComponent implements OnInit {

  loading = true;

  isInvalid = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private preLoginService: PreloginService) {
    // redirect already logged in user to dashboard
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/my-dashboard']);
    }
    else {
      this.activatedRoute.queryParams.subscribe(params => {
        const urlParam_p = params.p;
        const urlParam_c = params.c;

        localStorage.setItem('counsellorSign_p', urlParam_p);
        localStorage.setItem('counsellorSign_c', urlParam_c);

        const postData = {
          p: urlParam_p,
          c: urlParam_c
        };

        this.preLoginService.decodeInvitation(postData).subscribe(response => {
          this.loading = false;

          if (response.statusCode == 200 && response.status == false) {
            this.isInvalid = true;
          }
          else {
            localStorage.setItem('counsellorSign_partner_id', response.data.partner_id);
            localStorage.setItem('counsellorSign_partner_name', response.data.partner_name);
            localStorage.setItem('counsellorSign_email', response.data.counselor_email);

            // redirect to the counsellor-registration
            this.router.navigate(['/counsellor-registration']);
          }
        });

      });
    }
  }

  ngOnInit() { }

}
