import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PreloginService } from './services/prelogin.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {

    roadmapId;
    studentId;

    constructor(private router: Router, private preLoginService: PreloginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.roadmapId = localStorage.getItem('roadmap_id');
        this.studentId = localStorage.getItem('student_id');

        const lastIndex = (this.router.url).lastIndexOf('/');
        if ((this.router.url).substring(lastIndex + 1) === 'student-roadmap') {
            // console.log('student-roadmap');
        }
        else if ((this.router.url).indexOf('/student-roadmap/') !== -1) {
            // console.log('student-roadmap/');
            request = request.clone({
                headers: request.headers
                    .set('studentid', this.studentId)
                    .set('roadmapid', this.roadmapId)
            });
            localStorage.removeItem('fromSavedRoadmap');
        }
        else if ((this.router.url).indexOf('/roadmap') !== -1 || (this.router.url).indexOf('/saved-roadmaps')!==-1) {
            localStorage.removeItem('student_id');
            // console.log('roadmap');
            if (this.roadmapId) {
                request = request.clone({
                    headers: request.headers
                        .set('roadmapid', this.roadmapId)
                        .delete('studentid')
                });
            }
        }
        else {
            localStorage.removeItem('student_id');
            localStorage.removeItem('roadmap_id');
            localStorage.removeItem('fromSavedRoadmap');
            // console.log('not student-roadmap and not roadmap');
            request = request.clone({
                headers: request.headers.delete('studentid')
                    .delete('roadmapid')
            });
        }

        // MAJOR Fix : Earlier was setting 'session' in service which is 100% wrong. Refer dashboard service comment)
        const session: string = localStorage.getItem('pp_session');
        if (session) {
            request = request.clone({ headers: request.headers.set('session', session) });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // Uncomment if you want to see.
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // console.log(error);
                // Session Expired
                if (error.status === 403 && error.error.message === 'session expired') {
                    this.preLoginService.logOut(403);
                }
                // Backend server down
                // if (error.status == 0 && error.ok == false && error.statusText == 'Unknown Error') {
                //     localStorage.clear();
                //     this.preLoginService.setLoggedInFlagForNav(false);
                //     this.router.navigate(['/login'], { queryParams: { err: 0 } });
                // }
                return throwError(error);
            })
        );
    }
}