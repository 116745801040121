import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { DashboardService } from '../../services/dashboard.service';
import { SuperadminService } from '../../services/superadmin.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import { MessageService } from 'primeng/api';

declare var $: any;
@Component({
  selector: 'app-admin-assessment-coupons',
  templateUrl: './admin-assessment-coupons.component.html',
  styleUrls: ['./admin-assessment-coupons.component.scss']
})
export class AdminAssessmentCouponsComponent implements OnInit {

  @Input() public partnerId;

  @ViewChild('paginatorRef', { static: false }) paginatorRef: Paginator;
  addCoupons = "Add Coupons";
  isLoading = true;
  defaultPrefix = true;
  optionForm: FormGroup;
  partnerName;
  errorMsg;
  hasStatusOneProduct = false;
  getPurchasedCouponsPostData = {
    partner_id: Number,
    filterByType: Number,
    orderBy: 'date desc',
    search: '',
    download: '',
    offset: ''
  };
  purchasedCouponsList;
  purchasedCouponsListForCsvDownload;
  fileName = 'Coupons List';
  messageOnInput;
  // Pagination
  noOfPages;
  serviceOneResponse = [];
  // assign Coupon
  packageTypeSelectDropdown = []; // planSelect1, planSelect2, ...

  serialNumber = 1;
  deleteCouponFeedback;
  assessmentTableSearch = new Subject<string>();
  myDummyEvent = new Event('dummyEvent', { bubbles: true });

  // comp
  buyCouponsForm: FormGroup;
  defaultPrefixForm: FormGroup;
  planId;
  planPricePerCoupon;
  paymentPrice;
  couponCount;

  constructor(private zone: NgZone, private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService, private superAdminService: SuperadminService, private messageService: MessageService,) {
    // search
    this.assessmentTableSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.setFilterAndGetList();
      });
  }


  ngOnInit() {
    this.getPurchasedCouponsPostData.partner_id = this.partnerId;
    this.getAllFeaturesAndServices();
    this.superAdminService.getPartnerName().subscribe(data => {
      this.partnerName = data.slice(0, 5);

      this.defaultPrefixForm.patchValue({
        default_prefix: this.partnerName,
      }, { emitEvent: false });
    });

    this.optionForm = this.formBuilder.group({
      filterByType: ['']
    });

    this.getPurchasedCouponsList();

    this.buyCouponsForm = this.formBuilder.group({
      quantity: ['', [Validators.required, Validators.pattern('^0*[1-9][0-9]*$')]],
      prefix: ['', Validators.pattern('[(A-Za-z0-9)]+')]
    });

    this.defaultPrefixForm = this.formBuilder.group({
      default_prefix: ['', [Validators.pattern('[(A-Za-z0-9)]+')]]
    });


    this.optionForm.get('filterByType').valueChanges.subscribe(optedValue => {
      this.getPurchasedCouponsPostData.filterByType = optedValue;
      this.setFilterAndGetList();
    });

    this.superAdminService.changesOccur.subscribe((data)=>{
        this.getAllFeaturesAndServices();
    })
  }

  // getters
  get quantity() { return this.buyCouponsForm.get('quantity'); }
  get prefix() { return this.buyCouponsForm.get('prefix'); }
  get default_prefix() { return this.defaultPrefixForm.get('default_prefix'); }

  //
  setFilterAndGetList() {
    this.getPurchasedCouponsPostData.offset = '0';
    if (this.paginatorRef) {
      this.paginatorRef.changePageToFirst(this.myDummyEvent);
    }
    this.getPurchasedCouponsList();
  }

  // only number input
  onlyNumericInput(event) {
    return this.validationService.isNumericInput(event);
  }
  public isNumeric(value: any): boolean {
    return /^-?\d+$/.test(value);
  }
  public checkNumericInput(): void {
    this.addCoupons = "Add Coupons"
    if (!this.isNumeric(this.couponCount)) {
      this.errorMsg = "Please enter a valid number";
    } else {
      this.errorMsg = "";
    }
  }
  getAllFeaturesAndServices() {
    this.serviceOneResponse = [];
    let reqObj = {
      "partner_id": this.partnerId
    };
    this.superAdminService.getallServicesAndFeatures(reqObj).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        this.serviceOneResponse = response.data.servicesArray1;
      }
      // if (this.serviceOneResponse.length) {
      //   function hasStatusOne(data: any[]): boolean {
      //     return data.some(item => item.status === 1);
      //   }
      //   this.hasStatusOneProduct = hasStatusOne(this.serviceOneResponse);
      // }
      const hasAssessment = this.serviceOneResponse.some(item => item.product_name === "Assessment" && item.status === 1);
      this.hasStatusOneProduct = hasAssessment;
    })
  }
  getbtnDisabled() {
    if (!this.hasStatusOneProduct) {
      return true
    } else {
      return false
    }
  }
  // Coupons table
  getPurchasedCouponsList() {
    this.isLoading = true;

    this.superAdminService.getPartnerCouponsList(this.getPurchasedCouponsPostData).subscribe(response => {
      this.isLoading = false;
      if (response.status == true && response.statusCode == 200) {
        if (response.data != null) {
          if (this.getPurchasedCouponsPostData.download == '1') {
            this.getPurchasedCouponsPostData.download = '';
            this.purchasedCouponsListForCsvDownload = response.data.couponDetails;
            this.dashboardService.exportToCsvService(this.fileName, this.purchasedCouponsListForCsvDownload);
          }
          else {
            // creating select drop-downs (dynamic)
            this.packageTypeSelectDropdown = [];  // resetting the array, otherwise new fields are appended to it.
            (response.data.couponDetails).forEach((coupon, index) => {
              // selects will be generated with names as planSelect1, planSelect2, ...
              // STARTS with '1' i.e. planSelect1
              this.packageTypeSelectDropdown.push({ name: ('planSelect' + (index + 1)), value: (coupon.package_id == 2 && coupon.staged_at == 3) ? 3 : coupon.package_id });
              // INFO - if package_id == 2 and staged_at == 3, then 'college'
            });

            this.purchasedCouponsList = response.data.couponDetails;
            this.noOfPages = response.data.couponCount;
          }
        }
      }
      else if (response.status == false && response.statusCode == 200) {
        this.purchasedCouponsList = null;
        this.noOfPages = 0;
      }
    });
  }

  // Download CSV
  downloadCsv() {
    this.getPurchasedCouponsPostData.download = '1';
    this.getPurchasedCouponsList();
  }

  // Assign Purchased coupon
  assignPurchasedCoupon(itemIndex, dropdownSelectedPackageValue, couponCode) {
    const assignPurchasedCouponPostData = {
      partner_id: this.partnerId,
      coupon: couponCode,
      package: parseInt(dropdownSelectedPackageValue)
    };

    this.superAdminService.assignPurchasedCoupon(assignPurchasedCouponPostData).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.purchasedCouponsList[itemIndex].package_id = dropdownSelectedPackageValue;
      }
    });
  }

  // Paginate (with serialNumber update)
  paginate(event) {
    this.serialNumber = event.first + 1;
    this.getPurchasedCouponsPostData.offset = (event.first);
    this.getPurchasedCouponsList();
  }


  //
  buyCouponsFormSubmit() {
    if (this.buyCouponsForm.valid) {

      const assignAdditionalCouponsPostData = {
        partner_id: this.partnerId,
        quantity: this.quantity.value,
        prefix: this.prefix.value
      };

      this.superAdminService.addComplimentaryCoupons(assignAdditionalCouponsPostData).subscribe(response => {
        console.log(response);
        if (response.statusCode === 200 && response.status === true) {
          this.buyCouponsForm.reset();
          this.superAdminService.setComplimentaryCouponsAddedFlag(true);
          this.setFilterAndGetList();
        }
      });
    }
  }


  /*Set default prefix*/
  defaultPrefixFormSubmit() {
    if (this.defaultPrefixForm.valid) {

      const defaultPrefixFormPostData = {
        partner_id: this.partnerId,
        custom_prefix: this.default_prefix.value
      };

      this.superAdminService.updateDefaultPrefix(defaultPrefixFormPostData).subscribe(response => {
        if (response.statusCode === 200 && response.status === true) {
          this.defaultPrefix = true;
          this.partnerName = this.default_prefix.value;
        }
      });
    }
  }

  /*Show hide custom field*/
  defaultPrefixShow() {
    this.defaultPrefix = false;
  }

  displayDeleteModal(coupon_id) {
    localStorage.setItem('CouponToBeDeleted', coupon_id);
    $('#deleteCouponModal').modal('show');
  }

  deleteCoupon() {
    const deleteData = {
      coupon: localStorage.getItem('CouponToBeDeleted'),
      partner_id: this.partnerId
    }
    this.superAdminService.deleteCoupon(deleteData).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.paginatorRef.changePageToFirst;
        this.deleteCouponFeedback = "Deleted Coupon Successfully";
        this.getPurchasedCouponsList();
        this.openAcknowledgeModal();
      }
      else {
        this.deleteCouponFeedback = "There was some error in deleting a coupon";
      }
    })
  }

  submitAddCouponsCount() {
    this.addCoupons = "Adding Coupons"
    let reqObj: any = {
      "partner_id": this.partnerId,
      "count": this.couponCount
    }
    this.superAdminService.addCouponCount(reqObj).subscribe(response => {
      if (response.status == true && response.statusCode == 200) {
        this.addCoupons = "Added Coupons"
        this.messageService.add({
          key: 'topright',
          severity: 'success',
          detail: response.message,
        });
        this.couponCount = ""
      }
    })
    
  }

  openAcknowledgeModal() {
    console.log("Came till here");
    $('#deleteAcknowledgeModal').modal('show');
  }
  closeAcknowledgeModal() {
    $("#deleteAcknowledgeModal").modal('hide');
  }

}